var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-question-card", attrs: { elevation: "4" } },
    [
      _c(
        "div",
        { staticClass: "c-context" },
        [
          _vm.hasContext
            ? _c(
                "ClampedText",
                {
                  staticClass: "c-context-text c-text pa-4",
                  attrs: { "max-lines": 5 },
                },
                [_vm._v(" " + _vm._s(_vm.question.context) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.hasReference
        ? _c(
            "div",
            { staticClass: "px-4 py-0 text-center" },
            [
              _c("PopupWindow", {
                attrs: {
                  frame: _vm.question.reference.frame || false,
                  label:
                    _vm.question.reference.name || _vm.question.reference.url,
                  src: _vm.question.reference.url,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "c-question" },
        [
          _c(
            "v-card-title",
            { staticClass: "c-question-text c-text font-italic py-2" },
            [
              _vm._v(
                " Q" +
                  _vm._s(_vm.qno) +
                  ": " +
                  _vm._s(_vm.question.query || _vm.question.text) +
                  " "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "c-question-content d-flex flex-wrap ma-auto" },
            [
              _vm.question.image
                ? _c(
                    "v-card-text",
                    { staticClass: "c-figure c-half ma-auto" },
                    [
                      _c("v-img", {
                        key: _vm.qno,
                        staticClass: "ma-auto",
                        attrs: { contain: "", src: _vm.question.image.url },
                      }),
                      _c("div", { staticClass: "text-center ma-auto" }, [
                        _vm._v(" " + _vm._s(_vm.question.image?.caption) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "c-choices c-half" },
                [
                  _c("p", { staticClass: "c-prompt pb-0 mt-0 text-left" }, [
                    _vm._v(" " + _vm._s(_vm.prompt) + " "),
                  ]),
                  _c(
                    "v-list",
                    { staticClass: "pa-0", attrs: { shaped: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          staticClass: "c-choice-ol",
                          attrs: { color: "accent", multiple: _vm.isMulti },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        },
                        _vm._l(_vm.choices, function (choice, index) {
                          return _c("v-list-item", {
                            key: index,
                            staticClass: "c-choice-li",
                            class: {
                              "c-correct": choice.correct,
                              "c-incorrect": !choice.correct,
                              "c-review": _vm.review,
                            },
                            attrs: {
                              "active-class": "c-selected",
                              disabled: _vm.review,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ active }) {
                                    return [
                                      _c(
                                        "v-list-item-icon",
                                        {
                                          staticClass:
                                            "ma-0 pt-3 c-choice-text",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("charIndex")(index)
                                              ) +
                                              ". "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "c-choice-text c-text" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(choice.text) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _vm.isMulti
                                            ? _c("v-checkbox", {
                                                attrs: {
                                                  disabled: _vm.review,
                                                  "input-value": active,
                                                  color: "accent",
                                                },
                                              })
                                            : _c("v-radio", {
                                                attrs: {
                                                  "read-only": _vm.review,
                                                  "off-icon": active
                                                    ? "$radioOn"
                                                    : "$radioOff",
                                                  color: "accent",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.review
        ? _c(
            "v-card-actions",
            { staticClass: "d-flex justify-space-around pa-4 pt-0" },
            [
              !_vm.isMobile
                ? _c("v-btn", { staticClass: "c-btn-invisible" }, [
                    _vm._v(" " + _vm._s(_vm.$t("quiz.action.return")) + " "),
                  ])
                : _vm._e(),
              !_vm.isMobile ? _c("v-spacer") : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "primary", disabled: _vm.first },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("prev", _vm.selected)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.prev")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next", _vm.selected)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.next")) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("exit")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.return")) + " ")]
              ),
            ],
            1
          )
        : _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center pa-4 pt-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "primary", disabled: _vm.first },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("prev", _vm.selected)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.prev")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next", _vm.selected)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.next) + " ")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }