var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { persistent: "", "max-width": "320px" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        { staticClass: "c-dialog-card", class: { "c-outlined": _vm.outlined } },
        [
          _vm.title
            ? _c("v-card-title", { staticClass: "px-4" }, [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ])
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pb-4 px-4" },
            [
              _vm._t("default", function () {
                return [_vm._v(" " + _vm._s(_vm.text) + " ")]
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "c-dialog-actions",
              class: {
                "c-outlined": _vm.outlined,
                "justify-center": _vm.confirm,
              },
            },
            [
              !_vm.confirm
                ? _c(
                    "v-btn",
                    {
                      staticClass: "c-dialog-btn",
                      attrs: { color: "primary", rounded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onClose()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.closeText || _vm.$t("dialog.prompt.close")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.confirm ? _c("v-spacer") : _vm._e(),
              _c(
                "v-btn",
                {
                  ref: "submitButton",
                  staticClass: "c-dialog-btn",
                  attrs: { color: "primary", rounded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.submitText || _vm.$t("dialog.prompt.submit")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }