export default {
  activePortal(state, getters, rootState) {
    return rootState.portalStore.activePortal
  },

  areChannelsPrimed(state) {
    return state.channels.length > 0 || state.fetched
  },

  areChannelsLoading(state) {
    return state.loading == undefined ? false : state.loading
  },

  getChannel(state) {
    return ({ id, slug }) =>
      id
        ? state.channels.find((channel) => channel.id === id)
        : state.channels.find((channel) => channel.slug === slug)
  }
}
