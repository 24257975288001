var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-error-message" },
    [
      _c("v-alert", { attrs: { title: _vm.title, type: "error" } }, [
        _vm._v(" " + _vm._s(_vm.message) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }