<template>
  <v-list-group class="c-list-group" @click.stop>
    <template #activator>
      <v-list-item-action>
        <v-icon color="accent" x-large> mdi-cog </v-icon>
      </v-list-item-action>
      <v-list-item-title class="text-h6 text-left">
        {{ $t('account.settings') }}
      </v-list-item-title>
    </template>

    <v-list class="ml-8">
      <v-list-item>
        <v-list-item-action>
          <v-switch
            color="accent"
            :input-value="areTooltipsActive"
            inset
            value
            @change="setTooltips($event)"
          />
        </v-list-item-action>
        <v-list-item-title>{{ $t('settings.tooltips') }}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$feature('tours')">
        <v-list-item-action>
          <v-switch
            color="accent"
            :data-title="$t('tour.title')"
            :data-intro="$t('tour.appBar.tourButton.intro')"
            :input-value="areToursActive"
            inset
            value
            @change="setTouring($event)"
          />
        </v-list-item-action>
        <v-list-item-title>{{ $t('settings.touring') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-list-group>
</template>

<script>
import { mapActions } from 'vuex'
import FloatingVue from 'floating-vue'

export default {
  name: 'SettingsMenuItem',

  components: {},

  props: {},

  data: function () {
    return {}
  },

  computed: {
    areTooltipsActive() {
      return this.$store.state.appStore.tooltips
    },

    areToursActive() {
      return this.$store.state.appStore.tours
    }
  },

  created: function () {},

  methods: {
    ...mapActions('appStore', ['activateTours', 'deactivateTours']),

    setTooltips(newVal) {
      FloatingVue.options.themes.tooltip.disabled = !newVal
    },

    setTouring(newVal) {
      newVal ? this.activateTours() : this.deactivateTours()
    }
  }
}
</script>

<style lang="css" scoped></style>
