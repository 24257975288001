var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "grid",
      staticClass: "c-grid-container pa-0 g-skinny-scrollbars",
      attrs: { fluid: "" },
    },
    [
      _vm.gridWidth > 0
        ? _c("DynamicScroller", {
            staticClass: "c-scroller",
            attrs: {
              buffer: 2000,
              items: _vm.itemRows,
              "min-item-size": _vm.minHeight,
              "page-mode": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ item: row, index, active }) {
                    return [
                      _c(
                        "DynamicScrollerItem",
                        {
                          attrs: {
                            active: active,
                            "data-active": active,
                            "data-index": index,
                            item: row,
                            "size-dependencies": [],
                            "emit-resize": false,
                          },
                          on: {
                            resize: function ($event) {
                              return _vm.onItemResize($event, row)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "c-row ma-0",
                              attrs: { align: "stretch", justify: "start" },
                            },
                            _vm._l(row.items, function (item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.id,
                                  staticClass: "c-col",
                                  attrs: { cols: _vm.cols },
                                },
                                [_vm._t("card", null, { item: item })],
                                2
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "after",
                  fn: function () {
                    return [
                      _vm.showNotice
                        ? _c("div", { staticClass: "c-notice pl-3" }, [
                            _vm._v(" " + _vm._s(_vm.$t("ui.end")) + " "),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _vm.isEmpty
                        ? _c("div", { staticClass: "c-notice pl-3" }, [
                            _vm._v(" " + _vm._s(_vm.$t("ui.empty")) + " "),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }