var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-group",
    {
      staticClass: "c-list-group",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", { attrs: { color: "accent", "x-large": "" } }, [
                    _vm._v(" mdi-cog "),
                  ]),
                ],
                1
              ),
              _c("v-list-item-title", { staticClass: "text-h6 text-left" }, [
                _vm._v(" " + _vm._s(_vm.$t("account.settings")) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "ml-8" },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-action",
                [
                  _c("v-switch", {
                    attrs: {
                      color: "accent",
                      "input-value": _vm.areTooltipsActive,
                      inset: "",
                      value: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setTooltips($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.$t("settings.tooltips"))),
              ]),
            ],
            1
          ),
          _vm.$feature("tours")
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "accent",
                          "data-title": _vm.$t("tour.title"),
                          "data-intro": _vm.$t("tour.appBar.tourButton.intro"),
                          "input-value": _vm.areToursActive,
                          inset: "",
                          value: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setTouring($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("settings.touring"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }