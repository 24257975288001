var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.name === "divider"
    ? _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } })
    : _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.disabled,
            "input-value": _vm.active,
            text: "",
            "x-small": "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }