var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      ref: "page",
      staticClass: "c-player-page",
      class: { "c-fullscreen": _vm.isFullscreen },
    },
    [
      _c("PlayerBar", {
        staticClass: "c-player-bar",
        class: { "c-fullscreen": _vm.isFullscreen },
        attrs: { item: _vm.item },
        on: {
          "activate:canvas": function ($event) {
            _vm.isCanvasActivated = $event
          },
          "toggle:comments": function ($event) {
            _vm.showComments = !_vm.showComments
          },
          close: _vm.onClose,
          fullscreen: function ($event) {
            _vm.isFullscreen = $event
          },
          height: function ($event) {
            _vm.top = $event
          },
          share: function ($event) {
            return _vm.shareItem($event)
          },
        },
        model: {
          value: _vm.showDetails,
          callback: function ($$v) {
            _vm.showDetails = $$v
          },
          expression: "showDetails",
        },
      }),
      _c("PlayerDrawer", {
        staticClass: "c-player-drawer",
        class: { "c-fullscreen": _vm.isFullscreen },
        attrs: { context: _vm.context, item: _vm.item, open: _vm.showDetails },
        on: {
          resizing: function ($event) {
            _vm.resizing = $event
          },
        },
      }),
      _c("CommentDrawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.commentTarget,
            expression: "commentTarget",
          },
        ],
        attrs: {
          "data-title": _vm.$t("tour.comments.drawer.title"),
          "data-intro": _vm.$t("tour.comments.drawer.intro"),
          comments: _vm.comments,
          "target-id": _vm.commentTarget,
        },
        model: {
          value: _vm.showComments,
          callback: function ($$v) {
            _vm.showComments = $$v
          },
          expression: "showComments",
        },
      }),
      _vm.isLoading
        ? _c("CircularProgress", {
            staticClass: "c-progress",
            attrs: { message: _vm.$t("message.fetchingContent") },
          })
        : [
            _c("div", {
              staticClass: "c-bar-spacer",
              class: { "c-fullscreen": _vm.isFullscreen },
            }),
            _vm.isFound
              ? _c(
                  "ContentLayout",
                  {
                    attrs: {
                      "ad-free": _vm.isAdFree,
                      authors: _vm.authors,
                      fullscreen: _vm.isFullscreen,
                      params: _vm.params,
                      type: _vm.mediaType,
                      top: _vm.top,
                    },
                  },
                  [
                    _c("ContentPlayer", {
                      staticClass: "c-content g-skinny-scrollbars",
                      class: { "c-fullscreen": _vm.isFullscreen },
                      attrs: {
                        canvas: _vm.isCanvasActivated,
                        fullscreen: _vm.isFullscreen,
                        item: _vm.item,
                        overlay: _vm.resizing,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "player",
                            fn: function (props) {
                              return [
                                _c(_vm.player, {
                                  tag: "component",
                                  class: props.playerClass,
                                  attrs: {
                                    content: _vm.item,
                                    fullscreen: _vm.isFullscreen,
                                    params: props.params,
                                  },
                                  on: {
                                    context: function ($event) {
                                      _vm.context = $event
                                    },
                                    "error:handled": function ($event) {
                                      return _vm.onError(true, $event)
                                    },
                                    "error:loading": function ($event) {
                                      return _vm.onError(false, $event)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        601812510
                      ),
                    }),
                  ],
                  1
                )
              : _c("ErrorMessage", {
                  attrs: {
                    title: _vm.$t("error.ContentNotFoundError.title"),
                    message: _vm.$t("error.ContentNotFoundError.message"),
                  },
                }),
          ],
      _vm.showSharingModal
        ? _c("ShareModal", {
            attrs: { entity: _vm.sharedEntity },
            model: {
              value: _vm.showSharingModal,
              callback: function ($$v) {
                _vm.showSharingModal = $$v
              },
              expression: "showSharingModal",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }