import { APP_VERSION } from '@/config/appConfig'
import modules from './modules'
import { hydrateState } from '@/store/persistence/stateManager.js'

export default {
  // restore the saved state
  async restoreState({ state, dispatch }, { vm }) {
    /* Three default scenarios when restoring a state:
     * 1)no saved state exists (first time user, first time use of this device, or first time use of store)
     *    - initial state is used as is
     * 2)saved state exists, but the store's state has changed its properties
     *    - new properties (which don't exist in the saved state) use the inital state value
     *    - deprecated properties (which still exist in the saved state) require cleansing (via the module's 'restoreState' action)
     * 3)session state exists, and has all of the properties in the store's state
     *    - initial property is overwritten with the saved value
     */
    console.info('[store]: Restoring module states...')

    const restorePromises = Object.keys(modules).map(async (moduleName) => {
      const moduleStorage = state[moduleName].storage || 'local'
      const moduleState = await hydrateState(moduleName, moduleStorage)
      // console.debug(`[store]: Session state for ${moduleName}=`, moduleState)

      // only update the module store if previous session data exists
      const hasKeys = Object.keys(moduleState).length > 0
      dispatch(`${moduleName}/restoreState`, {
        sessionState: hasKeys ? { ...moduleState } : {},
        vm
      })
    })

    await Promise.all(restorePromises)

    dispatch(`appStore/updateVersion`, APP_VERSION)
    dispatch(`appStore/reportState`)

    console.info('[store]: State restoration actions complete!')
  },

  // update the state with org level properties
  async reflectOrg({ state, dispatch }, { org, vm }) {
    // set locale (do this first!)
    const locale = org.locales.includes(state.i18nStore.locale)
      ? state.i18nStore.locale
      : org.locales[0]
    dispatch('i18nStore/updateLocale', { locale, vm })

    // set portal
    const activePortalKey = state.tenantStore.portalKey
    const portals = org.portals || []
    await dispatch('portalStore/updatePortals', {
      portals,
      activePortalKey
    })

    // set content (note: content depends on portal!)
    const collections = org.collections
    const taxonomyTitles = org.taxonomyTitles
    dispatch('contentStore/updateCollections', { collections })
    dispatch('contentStore/updateTaxonomyTitles', { taxonomyTitles })

    // set menu (note: menu depends on collections!)
    const menuItems = org.menuItems
    const indexMenuItem = org.menuItems.find((menuItem) => menuItem.index)
    const indexRoute = indexMenuItem ? { name: indexMenuItem } : { name: 'home' }
    dispatch('menuStore/updateMenu', { menuItems })
    dispatch('menuStore/updateIndexRoute', { route: indexRoute })

    // set visual elements
    const appDescription = org.appDescription
    const assetRoot = org.assetRoot
      ? org.assetRoot.replace(/\/$/g, '') // strip off trailing '/'
      : `${window.location.origin}/${state.tenantStore.tenantKey}`

    const gtmId = org.gtmId || ''
    const orgInfo = {
      orgLink: org.orgInfo?.orgLink || '',
      orgLogo: org.orgInfo?.orgLogo || '/img/logos/default-logo.png',
      orgName: org.orgInfo?.orgName || '<Missing>',
      about: org.orgInfo?.about || '<Missing>',
      contact: org.orgInfo?.contact || '<Missing>',
      disclaimer: org.orgInfo?.disclaimer || '<Missing>',
      privacy: org.orgInfo?.privacy || '<Missing>',
      tou: org.orgInfo?.tou || '<Missing>'
    }
    dispatch('orgStore/updateOrg', { appDescription, assetRoot, gtmId, orgInfo })
    dispatch('themeStore/updateBranding', { brandTheme: org.theme, vm: this.$app })
  },

  // update the state with user profile properties
  reflectUser({ dispatch }, { user, vm }) {
    const locale = user.locale
    dispatch('userStore/updateUser', { user })
    if (locale) {
      dispatch('i18nStore/updateLocale', { locale, vm })
    }
  }
}
