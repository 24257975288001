var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showStatus
    ? _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.appBar.online"),
              expression: "$t('tooltip.appBar.online')",
            },
          ],
          staticClass: "c-noclick",
          attrs: { fab: "", small: "" },
        },
        [
          _c("v-icon", { attrs: { color: "accent" } }, [
            _vm._v(
              " " + _vm._s(_vm.online ? "mdi-wifi" : "mdi-wifi-off") + " "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }