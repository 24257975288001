var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.itemTag,
    { tag: "component", staticClass: "vue-recycle-scroller__item-view" },
    [
      _vm._t("default", null, {
        item: _vm.view.item,
        index: _vm.view.nr.index,
        active: _vm.view.nr.used,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }