// files generated by https://progressier.com/pwa-icons-and-ios-splash-screen-generator
export default [
  // iPhones
  {
    devices: 'iPhone_16_Pro_Max_portrait.png',
    width: 440,
    height: 956,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_16_Pro_Max_landscape.png',
    width: 440,
    height: 956,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_16_Pro_portrait.png',
    width: 402,
    height: 874,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_16_Pro_landscape.png',
    width: 402,
    height: 874,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_16_Plus__iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png',
    width: 430,
    height: 932,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_16_Plus__iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png',
    width: 430,
    height: 932,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_16__iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png',
    width: 393,
    height: 852,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_16__iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png',
    width: 393,
    height: 852,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png',
    width: 428,
    height: 926,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png',
    width: 428,
    height: 926,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png',
    width: 390,
    height: 844,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png',
    width: 390,
    height: 844,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png',
    width: 375,
    height: 812,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png',
    width: 375,
    height: 812,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png',
    width: 414,
    height: 896,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png',
    width: 414,
    height: 896,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_11__iPhone_XR_portrait.png',
    width: 414,
    height: 896,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_11__iPhone_XR_landscape.png',
    width: 414,
    height: 896,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png',
    width: 414,
    height: 736,
    pixelRatio: 3,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png',
    width: 414,
    height: 736,
    pixelRatio: 3,
    orientation: 'landscape'
  },
  {
    devices: 'iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png',
    width: 375,
    height: 667,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: 'iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png',
    width: 375,
    height: 667,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png',
    width: 320,
    height: 568,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png',
    width: 320,
    height: 568,
    pixelRatio: 2,
    orientation: 'landscape'
  },

  // iPads
  {
    devices: '13__iPad_Pro_M4_portrait.png',
    width: 1032,
    height: 1376,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '13__iPad_Pro_M4_landscape.png',
    width: 1032,
    height: 1376,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '12.9__iPad_Pro_portrait.png',
    width: 1024,
    height: 1366,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '12.9__iPad_Pro_landscape.png',
    width: 1024,
    height: 1366,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '11__iPad_Pro__10.5__iPad_Pro_portrait.png',
    width: 834,
    height: 1194,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '11__iPad_Pro__10.5__iPad_Pro_landscape.png',
    width: 834,
    height: 1194,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '10.9__iPad_Air_portrait.png',
    width: 820,
    height: 1180,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '10.9__iPad_Air_landscape.png',
    width: 820,
    height: 1180,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '10.5__iPad_Air_portrait.png',
    width: 834,
    height: 1112,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '10.5__iPad_Air_landscape.png',
    width: 834,
    height: 1112,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '10.2__iPad_portrait.png',
    width: 810,
    height: 1080,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '10.2__iPad_landscape.png',
    width: 810,
    height: 1080,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png',
    width: 768,
    height: 1024,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png',
    width: 768,
    height: 1024,
    pixelRatio: 2,
    orientation: 'landscape'
  },
  {
    devices: '8.3__iPad_Mini_portrait.png',
    width: 744,
    height: 1133,
    pixelRatio: 2,
    orientation: 'portrait'
  },
  {
    devices: '8.3__iPad_Mini_landscape.png',
    width: 744,
    height: 1133,
    pixelRatio: 2,
    orientation: 'landscape'
  }
]
