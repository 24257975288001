// import Vue from 'vue'
import emitter from '@/utilities/emitter.js'

// const bus = new Vue()
const bus = emitter

export class BusPlugin {
  static install(Vue, _options) {
    Vue.prototype.$bus = bus
  }
}

export const $bus = bus
