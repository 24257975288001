var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    {
      attrs: { accordian: "", flat: "", hover: "", multiple: "", tile: "" },
      model: {
        value: _vm.panels,
        callback: function ($$v) {
          _vm.panels = $$v
        },
        expression: "panels",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }