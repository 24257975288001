<template>
  <ModalDialog
    v-if="isTouringEnabled"
    v-model="showDialog"
    content-class="c-tour-modal"
    outlined
    :close-text="$t('tour.action.later')"
    :submit-text="$t('tour.action.start')"
    :title="$t('tour.title')"
    @close="onManual()"
    @submit="startTour(tour, introStep)"
  >
    {{ $t('tour.prompt') }}
  </ModalDialog>
</template>

<script>
import { mapActions } from 'vuex'
import ModalDialog from '@/components/base/ModalDialog'
import { tourService } from '@/services/tourService.js'

export default {
  name: 'AppTour',

  components: {
    ModalDialog
  },

  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: true
    },

    tour: {
      type: String,
      required: false,
      default: 'default'
    }
  },

  data: function () {
    return {
      isManual: false,
      showDialog: false,
      tourService: tourService
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    },

    appName() {
      const portal = this.$store.state.portalStore.activePortal
      return portal?.title?.[this.locale] || this.$t('tour.overview')
    },

    appDescription() {
      return this.$store.state.orgStore.appDescription?.[this.locale] || this.$t('tour.overview')
    },

    introStep() {
      return {
        element: null,
        title: this.appName || this.$t('tour.overview'),
        intro: this.appDescription
      }
    },

    isTouringEnabled() {
      return this.$store.state.appStore.tours
      // return this.$store.state.appStore.tours.includes[this.tour]
    },

    showTourDialog() {
      return this.dialog && this.tourService.isTourActive(this.tour)
    }
  },

  watch: {
    showTourDialog: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        if (newShow) {
          window.setTimeout(() => {
            this.showDialog = true
            this.isManual = false
          }, 2000)
        }
      }
    },

    isTouringEnabled: {
      immediate: true,
      handler: function (newActive, _oldActive) {
        // sync the global on/off switches of appStore and tourService
        this.tourService.setActive(newActive)
        // reset session and cookie level switches
        if (newActive) {
          this.tourService.reactivateTours()
        }
      }
    },

    tour: {
      immediate: true,
      handler: function (newTour, _oldTour) {
        // start "no startup dialog" tours
        if (!this.dialog) {
          // allow component to mount
          // this.$nextTick(() => this.startTour(newTour))
          setTimeout(() => this.startTour(newTour), 2000)
        }
      }
    }
  },

  methods: {
    ...mapActions('appStore', ['activateTours', 'deactivateTours']),

    startTour(tour, introStep) {
      this.tourService.startTour(tour, introStep)
    },

    onManual() {
      this.showDialog = false
      this.deactivateTours()
    }
  }
}
</script>

<style lang="scss">
.c-progress {
  background-color: var(--v-primary-base);
}
.c-tour-modal {
  background-color: var(--v-background-base);
  outline: 1px solid var(--v-text-base);
  border-radius: 5px;

  .v-card {
    background: inherit;
  }

  .v-card__text {
    color: var(--v-text-base) !important;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  .c-checkbox .v-input--selection-controls__input {
    opacity: 0.7;
  }
}
</style>
