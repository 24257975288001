<template>
  <div ref="view">
    <CardCarousel
      v-if="isCarousel"
      :items="items"
      :card-width="minCardWidth"
      :loading="loading"
      :width="viewWidth"
    >
      <template #card="props">
        <slot name="card" :item="props.item" />
      </template>
    </CardCarousel>

    <CardGrid
      v-else-if="isGrid"
      :infinite="true"
      :items="items"
      :loading="loading"
      :width="viewWidth"
    >
      <template #card="props">
        <DraggableNode
          :active="draggable"
          :drag-type="dragType"
          :identifier="props.item.id"
          @drop="$emit('move', $event)"
        >
          <slot name="card" :item="props.item" />
        </DraggableNode>
      </template>
    </CardGrid>

    <CardVirtualGrid
      v-else-if="isVirtualGrid"
      :items="items"
      :loading="loading"
      :min-card-height="minCardHeight"
      :width="viewWidth"
    >
      <template #card="props">
        <slot name="card" :item="props.item" />
      </template>
    </CardVirtualGrid>

    <CardTree
      v-else-if="isTree"
      class="mb-12"
      :attribute="treeAttribute"
      :expand="treeExpand"
      :items="items"
      :loading="loading"
      :width="viewWidth"
    >
      <template #card="props">
        <slot name="card" :item="props.item" />
      </template>
    </CardTree>
  </div>
</template>

<script>
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import CardTree from '@/components/base/CardTree'
import CardVirtualGrid from '@/components/base/CardVirtualGrid'
import DraggableNode from '@/components/base/DraggableNode'
import { debounce } from '@/utilities/gating.js'

export default {
  name: 'CardView',

  components: {
    CardCarousel,
    CardGrid,
    CardTree,
    CardVirtualGrid,
    DraggableNode
  },

  props: {
    /* card entities */

    items: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    maxItems: {
      type: Number,
      required: false,
      default: 0
    },

    /* card layout */

    layout: {
      type: String,
      required: false,
      default: 'grid'
    },

    width: {
      type: Number,
      required: false,
      default: 0
    },

    /* card dimensions */

    minCardHeight: {
      type: Number,
      required: false,
      default: 500
    },

    minCardWidth: {
      type: Number,
      required: false,
      default: 300
    },

    /* card grid */

    draggable: {
      type: Boolean,
      required: false,
      default: false
    },

    dragType: {
      type: String,
      required: false,
      default: 'generic'
    },

    /* card tree */

    treeAttribute: {
      type: String,
      required: false,
      default: 'topic'
    },

    treeExpand: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      viewWidth: 0,
      resizeObserver: null
    }
  },

  computed: {
    isCarousel() {
      return this.layout === 'carousel'
    },

    isGrid() {
      return this.optimizedGridLayout
    },

    isTree() {
      return this.layout === 'tree'
    },

    isVirtualGrid() {
      return this.optimizedGridLayout
    },

    optimizedGridLayout() {
      return this.maxItems > 48 ? 'vgrid' : 'grid'
    }
  },

  created: function () {
    // handles sidebar padding added via v-main transition
    this.resizeObserver = new ResizeObserver(
      debounce((entries) => {
        for (const entry of entries) {
          const width = entry.contentRect.width
          // avoid case where deactivated component width goes to zero
          if (width > 0) this.viewWidth = width
        }
      }, 10)
    )
  },

  mounted: function () {
    this.resizeObserver.observe(this.$refs.view)
  },

  beforeDestroy: function () {
    this.resizeObserver.disconnect()
  },

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
