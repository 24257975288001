var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isTouringEnabled
    ? _c(
        "ModalDialog",
        {
          attrs: {
            "content-class": "c-tour-modal",
            outlined: "",
            "close-text": _vm.$t("tour.action.later"),
            "submit-text": _vm.$t("tour.action.start"),
            title: _vm.$t("tour.title"),
          },
          on: {
            close: function ($event) {
              return _vm.onManual()
            },
            submit: function ($event) {
              return _vm.startTour(_vm.tour, _vm.introStep)
            },
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("tour.prompt")) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }