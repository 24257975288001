<template>
  <div class="c-article-viewer">
    <article
      class="c-article d-flex"
      :class="{ 'c-fullscreen': fullscreen }"
    >
      <iframe
        class="c-iframe mx-auto g-skinny-scrollbars"
        allowfullscreen
        :src="src"
        :title="title"
      />
    </article>
  </div>
</template>

<script>
export default {
  name: 'ArticleViewer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    src() {
      // return 'https://lumenii-functions.azurewebsites.net/api/tenant/healthplexus/html?url=https://www.healthplexus.net/article/degenerative-cervical-myelopathy-navigating-management-primary-care-setting'
      return this.content.contentURL
    },

    title() {
      return this.content.title
    }
  }
}
</script>

<style lang="css" scoped>
.c-article-viewer {
  height: 100%;
}
.c-article-viewer.c-fullscreen {
  height: var(--c-viewport-height);
  background-color: black;
}
.c-article {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
.c-iframe {
  display: block; /* iframe is normally inline - which adds lineheight whitespace causing scrollbars */
  border: 0;
  width: 100%;
  max-width: 900px;
  height: 100%;
}
</style>
