var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card py-0 rounded-xl", attrs: { outlined: "" } },
    [
      _c(
        "v-list-item",
        { staticClass: "px-2" },
        [
          _c(
            "v-list-item-avatar",
            { staticClass: "my-0" },
            [
              _vm.hasAvatar
                ? _c("v-img", { attrs: { src: _vm.authorAvatar } })
                : _c("v-icon", { attrs: { color: "primary", large: "" } }, [
                    _vm._v(" mdi-account-circle-outline "),
                  ]),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            { staticClass: "py-0" },
            [
              _c("v-list-item-title", [
                _vm._v(" " + _vm._s(_vm.authorName) + " "),
              ]),
              _c("v-list-item-subtitle", [_vm._v(" " + _vm._s(_vm.ago) + " ")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "text-body-1 pa-2" },
        [
          _vm.editMode
            ? _c("CommentForm", {
                attrs: {
                  body: _vm.commentBody,
                  outlined: false,
                  rich: _vm.rich,
                  "update-mode": true,
                },
                on: {
                  cancel: function ($event) {
                    return _vm.onCancel()
                  },
                  submit: function ($event) {
                    return _vm.onUpdate($event)
                  },
                },
              })
            : _c("CommentBody", {
                attrs: { body: _vm.commentBody, rich: _vm.rich },
              }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pt-0" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.commentReplyCount === 0,
                fab: "",
                icon: "",
                small: "",
              },
              on: { click: _vm.onReplies },
            },
            [
              _c("v-icon", { staticClass: "primary--text" }, [
                _vm._v(" mdi-chat-outline "),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.commentReplyCount))]),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.editable
            ? _c(
                "v-btn",
                {
                  attrs: { fab: "", icon: "", small: "" },
                  on: { click: _vm.onEdit },
                },
                [
                  _c("v-icon", { staticClass: "orange--text" }, [
                    _vm._v(" mdi-pencil "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.editable
            ? _c(
                "v-btn",
                {
                  attrs: { fab: "", icon: "", small: "" },
                  on: { click: _vm.onDelete },
                },
                [
                  _c("v-icon", { staticClass: "red--text" }, [
                    _vm._v(" mdi-delete "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { fab: "", icon: "", small: "" },
              on: { click: _vm.onReply },
            },
            [
              _c("v-icon", { staticClass: "blue--text" }, [
                _vm._v(" mdi-reply "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }