import { analyticsService } from '@/services/analyticsService.js'
import { GTM_ID } from '@/config/appConfig.js'

export default {
  computed: {
    analyticsMixin_context() {
      return {
        // invocation context
        path: this.$route.path,
        token: 'abc123',
        tid: GTM_ID,

        // user context
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        uid: this.$auth.getUserSubject(),

        // Urchin codes
        utm_campaign: this.$route.query.utm_campaign || '',
        utm_medium: this.$route.query.utm_medium || 'pwa',
        utm_source: this.$route.query.utm_source || this.$store.state.tenantStore.portalKey
      }
    }
  },

  methods: {
    analyticsMixin_sendBeacon(event, properties) {
      const snakeToCamel = (str) => str.replace(/(?!^)_(.)/g, (_, char) => char.toUpperCase())

      const eventName = snakeToCamel(event)

      // convert snake to camel

      analyticsService.sendBeacon(eventName, {
        ...this.analyticsMixin_context,
        ...properties
      })
    }
  }
}
