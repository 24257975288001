import introJs from 'intro.js'
import 'intro.js/minified/introjs.min.css'
import 'intro.js/themes/introjs-modern.css'
import { i18n } from '@/plugins/localePlugin'

const $t = (msg, ...args) => i18n.t(msg, ...args)

var dontShowAgain = {}

const cookieName = (tourName) => `${tourName}-dontShowAgain`

const introjsOptions = {
  // labels
  nextLabel: $t('tour.action.next') + ' > ',
  prevLabel: ' < ' + $t('tour.action.prev'),
  doneLabel: $t('tour.action.done'),
  dontShowAgainLabel: $t('tour.action.dontShowAgain'),
  hintButtonLabel: $t('tour.action.hint'),
  // navigation
  dontShowAgain: true,
  dontShowAgainCookie: cookieName('lumenii'),
  dontShowAgainCookieDays: 10 * 365,
  disableInteraction: false,
  exitOnOverlayClick: false,
  isActive: false,
  // visuals
  progressBarAdditionalClass: 'c-progress',
  showBullets: false,
  showButtons: true,
  showProgress: true,
  showStepNumbers: true,
  stepNumbersOfLabel: '/'
}

class TourService {
  constructor() {
    // core
    this.self = this
    this.introjs = introJs()
    this.introjs.setOptions(introjsOptions)

    // tour state
    this.currentStep = null
    this.introjs.__isTouringActive = false

    // callbacks
    this.introjs.onbeforechange(this.onBeforeChange)
    this.introjs.onchange(this.onChange)
    this.introjs.onafterchange(this.onAfterChange)
    this.introjs.onskip(this.onSkip)
    this.introjs.onexit(this.onExit)
    this.introjs.oncomplete(this.onComplete)
  }

  setOption(name, value) {
    this.introjs.setOption(name, value)
  }

  /* tour lifecycle */

  isTourActive(tour) {
    // use cookie name as key
    // - cookies for cross session state
    // - global variable for intra session state
    const dontShowAgainCookie = cookieName(tour)

    // check global variable for this tour
    const dontShow = dontShowAgainCookie in dontShowAgain && dontShowAgain[dontShowAgainCookie]

    // check introjs' global isActive flag *and* dontShowAgain cookie
    this.introjs.setOption('dontShowAgainCookie', cookieName(tour))
    const active = this.introjs.isActive()

    // if both global variables and cookie
    return active && !dontShow
  }

  isTouringActive() {
    return this.introjs.__isTouringActive
  }

  setActive(isActive) {
    this.introjs.setOption('isActive', isActive)
  }

  reactivateTours() {
    for (const key in dontShowAgain) {
      dontShowAgain[key] = false
      this.introjs.setOption('dontShowAgainCookie', key)
      this.introjs.setDontShowAgain(false)
    }
  }

  startTour(tour, introStep) {
    this.introjs.setOption('dontShowAgainCookie', `${tour}-dontShowAgain`)

    if (!this.isTourActive(tour)) {
      return
    }

    this.introjs.__isTouringActive = true

    this.introjs.setOptions({ steps: [], hints: [] })
    introStep && this.introjs.addStep(introStep)
    this.introjs.addSteps(this.getSteps(tour))
    /*
    this.introjs.addStep({
      element: null,
      title: $t('tour.hints.title'),
      intro: $t('tour.hints.intro')
    })
    */
    this.introjs.addHints()
    this.introjs.start()
  }

  refreshTour() {
    // ensure touring is active
    if (!this.introjs.isActive()) {
      return
    }

    // get current step

    // remove hints and steps
    this.introjs.removeHints()
    this.introjs.setOptions({ steps: [], hints: [] })
    this.introjs.addSteps(this.getSteps(this.tour))

    // rebuild hints and steps
    this.introjs.refresh(true) // true -> refreshes steps
    this.introjs.addHints()

    // restart introjs
    // this.introjs.start()
  }

  suspendTour() {
    this.currentStep = this.introjs._currentStep
    this.introjs.hideHints()
    this.introjs.exit()
    this.introjs.setOption('isActive', false)
    this.introjs.__isTouringActive = false
  }

  resumeTour() {
    this.introjs.setOption('isActive', true)
    this.introjs.showHints()
    this.introjs.gotoStep(this.currentStep)
    this.introjs.start()
    this.introjs.__isTouringActive = true
  }

  /* tour events (note: this = introjs instance) */

  onSkip(_step) {
    // called if tour ends before completion (i.e. *before* last step is completed)
  }

  onComplete() {
    this.setDontShowAgain(true)
    // called if tour completes (i.e. *after* last step is completed)
  }

  onExit() {
    // called after either 'skip' or 'complete' event occurs
    dontShowAgain[this._options.dontShowAgainCookie] = true
    this.__isTouringActive = false
  }

  /* step events */

  onChange(_el) {}

  onBeforeChange(el, step, direction) {
    console.error('beforeChange', step, direction)

    /* simple click */

    if (el.hasAttribute('data-click')) {
      el.click()
    }

    /* foward click */

    if (this.__forwardElement && direction === 'forward') {
      this.__forwardElement.click()
      this.__forwardElement = null
    }
    if (el.hasAttribute('data-click-forward')) {
      this.__forwardElement = direction === 'forward' ? el : null
    }

    /* future click */

    if (el.hasAttribute('data-click-future')) {
      this.__futureElement = el
    }
    if (el.hasAttribute('data-click-previous')) {
      this.__futureElement?.click()
      this.__futureElement = null
    }

    /* toggle click */

    if (el.getAttribute('data-toggle') === 'open') {
      console.error('toggle=open')
      this.__toggleElement = el
      el.click()
    } else if (el.getAttribute('data-toggle') === 'close') {
      console.error('toggle=close')
      this.__toggleElement?.click()
      this.__toggleElement = null
    } else if (el.getAttribute('data-toggle') === 'nop') {
      this.__toggleElement = el
      console.error('toggle=nop')
    }

    /* refresh */

    if (el.getAttribute('data-refresh')) {
      console.error('refreshing!', direction)
      this.refreshTour()
    }
  }

  onAfterChange(_el) {}

  /* helpers */

  getHints() {
    const elements = Array.from(document.querySelectorAll(`[data-hint]`))
    const steps = elements.map((el) => {
      return {
        hint: el.getAttribute('data-hint'),
        element: el,
        position: el.getAttribute('data-hint-position') || 'top-middle'
      }
    })
    return steps
  }

  getSteps(_tour) {
    const elements = Array.from(document.querySelectorAll(`[data-intro]`))
    console.error('elements=', elements)
    const steps = elements.map((el) => {
      return {
        title: el.getAttribute('data-title') || '',
        intro: el.getAttribute('data-intro'),
        element: el.getAttribute('data-position') === 'none' ? null : el
      }
    })
    return steps
  }
}

export const tourService = new TourService()

export const isTouringActive = () => tourService.isTouringActive()
