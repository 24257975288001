var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        "clipped-left": "",
        "clipped-right": "",
        color: _vm.bannerColour,
        dense: "",
        fixed: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "c-left text-left" },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: { class: "primary--text" },
                  expression: "{ class: 'primary--text' }",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.playerBar.details"),
                  expression: "$t('tooltip.playerBar.details')",
                },
              ],
              staticClass: "c-btn c-btn--stateless",
              attrs: {
                "data-title": _vm.$t("tour.playerPage.drawerOpen.title"),
                "data-intro": _vm.$t("tour.playerPage.drawerOpen.intro"),
                "data-toggle": _vm.isAsideOpen ? "nop" : "begin",
                fab: "",
                small: "",
                text: "",
              },
              on: { click: _vm.toggleAside },
            },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.aside ? "mdi-backburger" : "mdi-forwardburger") +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-toolbar-title",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.titleTooltip,
              expression: "titleTooltip",
            },
          ],
          staticClass: "c-title px-1 text-h6 text-center",
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "c-right text-right",
          class: { "c-full": !_vm.isMobile },
        },
        [
          !_vm.isMobile
            ? [
                _c("div"),
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    attrs: {
                      "data-title": _vm.$t("tour.playerPage.barActions.title"),
                      "data-intro": _vm.$t("tour.playerPage.barActions.intro"),
                      "data-position": "bottom-right-aligned",
                    },
                  },
                  _vm._l(_vm.buttons, function (button) {
                    return _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: button.tooltip,
                            expression: "button.tooltip",
                          },
                        ],
                        key: button.title,
                        staticClass: "c-btn c-btn--stateless ml-2",
                        attrs: {
                          "data-title": button.title,
                          "data-intro": button.tooltip,
                          fab: "",
                          small: "",
                          text: "",
                        },
                        on: { click: button.action },
                      },
                      [
                        _c(
                          "v-icon",
                          { class: button.class(), attrs: { "x-large": "" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(button.icon[button.choice() ? 0 : 1]) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            : [
                _c(
                  "v-speed-dial",
                  {
                    attrs: {
                      direction: "bottom",
                      transition: "slide-y-transition",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "c-btn",
                                attrs: {
                                  color: _vm.bannerColour,
                                  fab: "",
                                  small: "",
                                },
                                model: {
                                  value: _vm.showActions,
                                  callback: function ($$v) {
                                    _vm.showActions = $$v
                                  },
                                  expression: "showActions",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class: { "accent--text": _vm.showActions },
                                    attrs: { "x-large": "" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showActions
                                            ? "mdi-close"
                                            : "mdi-dots-vertical"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.showActions,
                      callback: function ($$v) {
                        _vm.showActions = $$v
                      },
                      expression: "showActions",
                    },
                  },
                  _vm._l(_vm.buttonsReversed, function (button) {
                    return _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: { class: "primary--text" },
                            expression: "{ class: 'primary--text' }",
                          },
                        ],
                        key: button.title,
                        staticClass: "c-btn c-btn--stateless c-btn-dial",
                        attrs: { color: _vm.buttonBackgroundColour, fab: "" },
                        on: { click: button.action },
                      },
                      [
                        _c(
                          "v-icon",
                          { class: button.class(), attrs: { "x-large": "" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(button.icon[button.choice() ? 0 : 1]) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }