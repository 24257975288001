<template>
  <v-container
    ref="grid"
    v-resize="onResize"
    class="c-grid-container pa-0 g-skinny-scrollbars"
    fluid
  >
    <v-row
      v-if="gridWidth > 0"
      class="c-grid g-hide-scrollbar ma-0"
      align="stretch"
      justify="start"
    >
      <v-col
        v-for="item in itemList"
        ref="card"
        :key="item.id"
        :cols="cols"
      >
        <slot name="card" :item="item" />
      </v-col>
    </v-row>

    <div v-show="hasMoreItems" ref='more' class="c-progress text-center my-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="50"
        :width="8"
      />
    </div>
  </v-container>
</template>

<script>
const GRID_SIZE = 48

export default {
  name: 'CardGrid',

  components: {},

  props: {
    items: {
      type: Array,
      required: true
    },

    infinite: {
      type: Boolean,
      required: false,
      default: false
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    minHeight: {
      type: Number,
      required: false,
      default: 500
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      activated: false,
      gridWidth: 0,
      scrollObserver: null,
      startIndex: 0,
      endIndex: 0
    }
  },

  computed: {
    cols() {
      const width = this.gridWidth

      if (width > 2400) return 1 // 12 columns
      if (width > 1800) return 2 // 6 columns
      if (width > 1200) return 3 // 4 columns
      if (width > 900) return 4 // 3 columns
      if (width > 600) return 6 // 2 columns
      if (width > 0) return 12 // 1 column

      return 12
    },

    colsByBreakpoint() {
      // note: abandoned since vuetify width & breakpoints are debounced (hence lag)
      const xxs = 450
      const xxl = 4152 // (12 * 300px) + (16px + 11 * 24px + 16px) + 256px

      const columns = {
        xxs: 12, // 1 card per row (<  450 px)
        xs: 12, // 1 cards per row (<  600 px)
        sm: 6, // 2 cards per row  (<  960 px)
        md: 4, // 3 cards per row  (< 1264 px)
        lg: 3, // 4 cards per row  (< 1904 px)
        xl: 2, // 6 cards per row  (< 4152 px)
        xxl: 1 // 12 cards per row (> 4152 px)
      }

      return this.$vuetify.breakpoint.width > xxl
        ? columns.xxl
        : this.$vuetify.breakpoint.width < xxs
        ? columns.xxs
        : columns[this.$vuetify.breakpoint.name]
    },

    itemList() {
      return this.items.slice(this.startIndex, this.endIndex)
    },

    hasMoreItems() {
      return this.infinite && this.endIndex < this.items.length
    }
  },

  watch: {
    items: {
      immediate: false,
      handler: function (newItems, _oldItems) {
        this.calculateIndexes(newItems.length)
      }
    },

    width: {
      immediate: true,
      handler: function (newWidth, _oldWidth) {
        this.gridWidth = newWidth || this.$refs.grid?.clientWidth || 0
      }
    }
  },

  created: function () {
    this.calculateIndexes(this.items.length)

    if (this.infinite) {
      this.scrollObserver = new IntersectionObserver(this.onMore, {
        root: null, // when observed inside the viewport
        threshold: 1.0
      })
    }
  },

  mounted: function () {
    this.scrollObserver?.observe(this.$refs.more)
  },

  activated: function () {
    this.activated = true
  },

  deactivated: function () {
    this.activated = false
  },

  beforeDestroy: function () {
    this.scrollObserver?.disconnect()
  },

  methods: {
    calculateIndexes(length) {
      this.startIndex = 0
      this.endIndex = this.infinite ? Math.min(GRID_SIZE, length) : length
    },

    onMore(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          this.hasMoreItems
            ? (this.endIndex = Math.min(this.endIndex + GRID_SIZE, this.items.length))
            : this.scrollObserver.unobserve(target)
        }
      })
    },

    onResize() {
      this.gridWidth = this.width || this.$refs.grid.clientWidth
    }
  }
}
</script>

<style lang="css" scoped>
</style>
