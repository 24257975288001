import posthog from 'posthog-js'
// import { POSTHOG_API_URL, POSTHOG_KEY } from '@/config/appConfig'

export class AnalyticsPlugin {
  static install(Vue, _options) {
    /*
    const posthogKey = POSTHOG_KEY
    if (posthogKey) {
      posthog.init(posthogKey, {
        api_host: POSTHOG_API_URL
      })
    }
    */

    Vue.prototype.$posthog = posthog
  }
}
