<template>
  <v-container
    ref="grid"
    v-resize="onResize"
    class="c-grid-container pa-0 g-skinny-scrollbars"
    fluid
  >
    <DynamicScroller
      v-if="gridWidth > 0"
      class="c-scroller"
      :buffer="2000"
      :items="itemRows"
      :min-item-size="minHeight"
      page-mode
    >
      <template #default="{ item: row, index, active }">
        <DynamicScrollerItem
          :active="active"
          :data-active="active"
          :data-index="index"
          :item="row"
          :size-dependencies="[]"
          :emit-resize="false"
          @resize="onItemResize($event, row)"
        >
          <v-row
            class="c-row ma-0"
            align="stretch"
            justify="start"
          >
            <v-col
              v-for="item in row.items"
              :key="item.id"
              class="c-col"
              :cols="cols"
            >
              <slot name="card" class="pa-3" :item="item" />
            </v-col>
          </v-row>
        </DynamicScrollerItem>
      </template>
      <template #after>
        <div v-if="showNotice" class="c-notice pl-3">
          {{ $t('ui.end') }}
        </div>
      </template>
      <template #empty>
        <div v-if="isEmpty" class="c-notice pl-3">
          {{ $t('ui.empty') }}
        </div>
      </template>
    </DynamicScroller>
  </v-container>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from '@/packages/scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  name: 'CardVirtualGrid',

  components: {
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    items: {
      type: Array,
      required: true
    },

    infinite: {
      type: Boolean,
      required: false,
      default: false
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    minHeight: {
      type: Number,
      required: false,
      default: 500
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      activated: false,
      gridWidth: 0
    }
  },

  computed: {
    cols() {
      const width = this.gridWidth

      if (width > 2400) return 1 // 12 columns
      if (width > 1800) return 2 // 6 columns
      if (width > 1200) return 3 // 4 columns
      if (width > 900) return 4 // 3 columns
      if (width > 600) return 6 // 2 columns
      if (width > 0) return 12 // 1 column

      return 12
    },

    colsByBreakpoint() {
      // note: abandoned since vuetify width & breakpoints are debounced (hence lag)
      const xxs = 450
      const xxl = 4152 // (12 * 300px) + (16px + 11 * 24px + 16px) + 256px

      const columns = {
        xxs: 12, // 1 card per row (<  450 px)
        xs: 12, // 1 cards per row (<  600 px)
        sm: 6, // 2 cards per row  (<  960 px)
        md: 4, // 3 cards per row  (< 1264 px)
        lg: 3, // 4 cards per row  (< 1904 px)
        xl: 2, // 6 cards per row  (< 4152 px)
        xxl: 1 // 12 cards per row (> 4152 px)
      }

      return this.$vuetify.breakpoint.width > xxl
        ? columns.xxl
        : this.$vuetify.breakpoint.width < xxs
        ? columns.xxs
        : columns[this.$vuetify.breakpoint.name]
    },

    itemRows() {
      const rowSize = 12 / this.cols
      return Array.from({ length: Math.ceil(this.items.length / rowSize) }, (v, i) =>
        this.items.slice(i * rowSize, i * rowSize + rowSize)
      ).map((row) => ({ id: row[0].id, items: row }))
    },

    isEmpty() {
      return !this.loading && this.items.length === 0
    },

    showNotice() {
      return !this.loading && this.items.length > 100
    }
  },

  watch: {
    width: {
      immediate: true,
      handler: function (newWidth, _oldWidth) {
        this.gridWidth = newWidth || this.$refs.grid?.clientWidth || 0
      }
    }
  },

  activated: function () {
    this.activated = true
  },

  deactivated: function () {
    this.activated = false
  },

  methods: {
    onResize() {
      // note: <v-container> is a functional component (hence no $el)
      this.gridWidth = this.width || this.$refs.grid.clientWidth
    },

    onItemResize({ id, size }, row) {
      console.debug('[CardVirtualGrid]: item resize', id, size, row.items[0].title)
    }
  }
}
</script>

<style lang="css" scoped>
.c-scroller {
  overflow-y: auto;
  height: 100%;
  flex: auto 1 1;
}
</style>
