<template>
  <v-card class="c-card d-flex flex-column" :class="{ 'c-selected': active }" text>
    <div class="c-flex-img">
      <v-img
        class="white--text c-clickable c-card-image"
        :aspect-ratio="16 / 9"
        :gradient="cardGradient"
        :src="thumbnail"
        @click="$emit('view:channel')"
      >
        <v-card-title class="c-card-title px-4 py-2">
          {{ channel.title }}
        </v-card-title>
      </v-img>
    </div>

    <v-card-title v-if="!channel.hideOverview" class="py-3 grey--text">
      {{ displayName ? $t('post.by') : '' }} {{ displayName }}
    </v-card-title>

    <v-card-text class="pb-2 text-left">
      <ClampedText>
        {{ channel.abstract }}
      </ClampedText>
    </v-card-text>

    <v-spacer />

    <v-card-actions class="px-4">
      <v-btn color="primary" @click="$emit('view:channel')">
        {{ $t('channel.explore') }}
      </v-btn>
      <v-spacer />
      <!-- share -->
      <v-btn
        v-if="$actions.share"
        v-tooltip="$t('tooltip.cardAction.share')"
        color="accent"
        icon
        large
        @click.stop="$emit('share')"
      >
        <v-icon large> mdi-share-variant </v-icon>
      </v-btn>
      <!-- info -->
      <v-btn
        v-tooltip="$t('tooltip.cardAction.info')"
        color="accent"
        icon
        large
        @click.stop="revealBack = true"
      >
        <v-icon large> mdi-information </v-icon>
      </v-btn>
      <!-- favourite -->
      <v-btn
        v-tooltip="$t('tooltip.cardAction.favourite')"
        color="red"
        icon
        large
        @click.stop="toggleFavourite()"
      >
        <v-icon large>
          {{ isFavourite ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-scroll-x-reverse-transition>
      <ChannelCardBack v-model="revealBack" :author="channel.author" :sponsor="channel.sponsor" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import ChannelCardBack from '@/components/channel/ChannelCardBack'
import ClampedText from '@/components/base/ClampedText'
import { mapActions } from 'vuex'
import { getImage } from '@/config/sanityConfig'

export default {
  name: 'ChannelCard',

  components: {
    ChannelCardBack,
    ClampedText
  },

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },

    channel: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false,
      revealBack: false
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    favourite() {
      return this.$store.state.userStore.channels.indexOf(this.channel.id) > -1
    },

    cardGradient() {
      return ''
      // return 'to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)'
    },

    displayName() {
      return this.channel.author?.displayName || this.channel.sponsor?.name || ''
    },

    thumbnail() {
      const image = this.channel.cardImage?.obj || this.channel.mainImage.obj
      return getImage(image).width(400).height(225).url()
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  activated: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToChannels', 'removeFromChannels']),

    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToFavourites() : this.removeFromFavourites()
    },

    addToFavourites() {
      this.addToChannels({ id: this.channel.id })
      this.$emit('add:channel')
    },

    removeFromFavourites() {
      this.removeFromChannels({ id: this.channel.id })
      this.$emit('remove:channel')
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  max-width: min(400px, 100%);
  min-width: 275px;
  border-radius: 16px;
}

.c-card-image {
  border-radius: inherit;
}

.c-card-title {
  background-color: rgb(0, 0, 0, 0.4);
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-shadow: 0px 2px 5px #222;
}

.c-clickable {
  cursor: pointer;
}

.c-selected {
  border-color: red;
  border-width: 0px;
  border-style: solid;
}

.v-card.c-channel > .v-card__text {
  font-size: 1.25rem;
  line-height: 2rem;
}
.theme--dark.v-card.c-channel > .v-card__text {
  color: white;
}
.theme--light.v-card.c-channel > .v-card__text {
  color: black;
}
</style>
