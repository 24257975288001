import Vue from 'vue'
import VueRouter from 'vue-router'

import { appRoutes, errorRoutes, menuRoutes, pageRoutes } from './routes'
import { beforeResolve, beforeEach, afterEach } from './guards'
import { scrollBehaviour } from './scrollBehaviour.js'

Vue.use(VueRouter)

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const routes = [...appRoutes, ...menuRoutes, ...pageRoutes, ...errorRoutes]
// const isActive = router.app.$store.state.menuStore.menuRoutes.includes(to.name)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: scrollBehaviour,
  routes: routes
})

router.onError((error) => {
  console.error('[router.onError]:', error)
})

/* register router guards */
router.beforeEach(beforeEach)
router.beforeResolve(beforeResolve) // after all in-component guards and async route components are resolved
router.afterEach(afterEach) // called just before the "to" DOM renders

export default router
