// import deepmerge from 'deepmerge'
import { APP_NAME } from '@/config/appConfig.js'
import * as Database from './database.js'
import * as Storage from './storage.js'

export const STORAGE_KEY = APP_NAME

export async function hydrateState(moduleName, storage) {
  const moduleKey = `${STORAGE_KEY}:${moduleName}`
  let moduleState

  try {
    if (storage === 'none') {
      moduleState = {}
    }

    if (storage === 'indexDB') {
      const record = await Database.getItem(moduleKey)
      // console.debug(`[stateManager]: ${moduleName}:record=${JSON.stringify(record).length}`)
      moduleState = record ? record : {}
    } else {
      const moduleString = Storage.getItem(moduleKey)
      // console.debug(`[stateManager]: ${moduleName}:string=${moduleString ? moduleString.length : 0}`)
      moduleState = moduleString ? JSON.parse(moduleString) : {}
    }
  } catch (error) {
    console.warn(`[store]: Unable to hydrate "${moduleName}" from ${storage || 'localStorage'}.`)
    moduleState = {}
  }
  return moduleState
}

export async function saveState(moduleName, moduleState) {
  const moduleStorage = moduleState.storage
  try {
    if (moduleStorage === 'indexDB') {
      const key = `${STORAGE_KEY}:${moduleName}`
      await Database.setItem(key, JSON.parse(JSON.stringify(moduleState)))
      if (Storage.getItem(key) !== null) {
        Storage.removeItem(key)
      }
    } else {
      Storage.setItem(`${STORAGE_KEY}:${moduleName}`, JSON.stringify(moduleState))
    }
  } catch (error) {
    console.warn(`[store]: Unable to save "${module}" to ${moduleStorage}.`)
  }
}
