<template>
  <v-menu
    v-model="isOpen"
    close-on-click
    close-on-content-click
    max-height="80%"
    offset-y
    top
    transition="slide-y-reverse-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-tooltip="$t('tooltip.modelBar.scenes')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        small
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ isOpen ? 'mdi-close' : 'mdi-animation-play' }}
        </v-icon>
      </v-btn>
    </template>
    <AnimationList
      v-model="selectedAnimationIds"
      :animations="animations"
      @select="$emit('select', $event)"
      @sync="syncAnimations($event)"
    />
  </v-menu>
</template>

<script>
import AnimationList from './AnimationList'

export default {
  name: 'AnimationSelector',

  components: {
    AnimationList
  },

  props: {
    animations: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      transition: 'slide-y-transition',
      selectedAnimationIds: []
    }
  },

  mounted: function () {
    // listen to the Player side drawer
    this.$bus.$on('sync:drawer', ({ selectedAnimationIds }) => {
      this.selectedAnimationIds = selectedAnimationIds.slice().reverse()
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:drawer')
  },

  methods: {
    syncAnimations(animationIds) {
      // notify the Player side drawer
      this.$bus.$emit('sync:selector', {
        selectedAnimationIds: animationIds.slice().reverse()
      })
    }
  }
}
</script>

<style lang="css" scoped></style>
