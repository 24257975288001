var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { ref: "card", staticClass: "c-card d-flex flex-column" },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-avatar",
            [
              _c("v-icon", { attrs: { color: "accent", "x-large": "" } }, [
                _vm._v(
                  " " + _vm._s(_vm.getMediaIcon(_vm.item.mediaType)) + " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-card-title",
                { staticClass: "c-title pa-0 text-left" },
                [
                  _c("ClampedText", { attrs: { "max-lines": 3 } }, [
                    _vm._v(" " + _vm._s(_vm.item.title) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "c-thumbnail-border" },
        [
          _c("v-img", {
            key: _vm.thumbnail,
            staticClass: "c-clickable ma-4",
            attrs: {
              "aspect-ratio": _vm.thumbnailAspectRatio,
              contain: "",
              src: _vm.thumbnail,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("view:item")
              },
            },
          }),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "py-0 text-subtitle-1 text-left" }, [
        _c("span", [_vm._v(_vm._s(_vm.topic) + " ")]),
      ]),
      _vm.$actions.size
        ? _c(
            "v-card-text",
            { staticClass: "d-flex py-0 text-subtitle-1" },
            [
              _c("div", [
                _vm._v(
                  " " + _vm._s(_vm.sizeInMB ? `${_vm.sizeInMB}MB` : " ") + " "
                ),
              ]),
              _c("v-spacer"),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.durationInMinutes
                        ? `${_vm.durationInMinutes} Min`
                        : " "
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("ContentCardActions", {
        attrs: { item: _vm.item },
        on: {
          "view:item": function ($event) {
            return _vm.$emit("view:item")
          },
          "view:info": function ($event) {
            _vm.revealBack = true
          },
          "remove:favourite": function ($event) {
            return _vm.$emit("remove:favourite")
          },
          share: function ($event) {
            return _vm.$emit("share")
          },
        },
      }),
      _c(
        "v-scroll-x-reverse-transition",
        [
          _c("ContentCardBack", {
            attrs: { item: _vm.item },
            on: {
              "view:parent": function ($event) {
                return _vm.$emit("view:parent")
              },
            },
            model: {
              value: _vm.revealBack,
              callback: function ($$v) {
                _vm.revealBack = $$v
              },
              expression: "revealBack",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }