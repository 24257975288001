<template>
  <v-list-item v-if="isAuthenticated">
    <v-list-item-avatar
      v-tooltip="{ content: $t('tooltip.account.avatar'), html: true, delay: { hide: 1500 }, popperClass: 'c-tooltip-link' }"
      class="c-avatar"
      :data-title="$t('tour.appBar.accountAvatar.title')"
      :data-intro="$t('tour.appBar.accountAvatar.intro')"
      size="40"
    >
      <a
        :href="$appConfig.avatarBaseURL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <v-avatar size="40">
          <img :src="avatarURL" />
        </v-avatar>
      </a>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title class="text-left">{{ userDisplayName }}</v-list-item-title>
      <v-list-item-subtitle class="text-left">{{ userEmail }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'UserMenuItem',

  components: {},

  props: {},

  data: function () {
    return {}
  },

  computed: {
    avatarURL() {
      return this.$auth.getUserAvatar()
    },

    isAuthenticated() {
      return this.$auth.isAuthenticated()
    },

    userDisplayName() {
      return this.$auth.getUserDisplayName()
    },

    userEmail() {
      return this.$auth.getUserEmail()
    },

    userId() {
      return this.$auth.getUserId()
    }
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.c-avatar {
  cursor: pointer;
  outline: var(--v-text-base) 1px solid;
}
</style>

<style lang="scss">
.v-popper__popper.c-tooltip-link a {
  &:link,
  &:visited {
    color: var(--v-accent-base);
  }
}
</style>
