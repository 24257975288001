var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { attrs: { dense: "", floating: "", rounded: "pill" } },
    [
      !_vm.isReallyMobile
        ? _c(
            "v-btn-toggle",
            { attrs: { color: "primary", rounded: "" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("tooltip.modelBar.zoomIn"),
                      expression: "$t('tooltip.modelBar.zoomIn')",
                    },
                  ],
                  staticClass: "c-btn c-btn--stateless",
                  attrs: {
                    disabled: !_vm.zoomInActive,
                    fab: "",
                    small: "",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.zoomIn.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(" mdi-plus "),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("tooltip.modelBar.zoomOut"),
                      expression: "$t('tooltip.modelBar.zoomOut')",
                    },
                  ],
                  staticClass: "c-btn c-btn--stateless",
                  attrs: {
                    disabled: !_vm.zoomOutActive,
                    fab: "",
                    small: "",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.zoomOut.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(" mdi-minus "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn-toggle",
        {
          staticClass: "mx-2",
          attrs: { color: "primary", mandatory: "", rounded: "" },
          model: {
            value: _vm.modeIndex,
            callback: function ($$v) {
              _vm.modeIndex = $$v
            },
            expression: "modeIndex",
          },
        },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.help
                    ? _vm.$t("model.help.move")
                    : _vm.$t("tooltip.modelBar.move"),
                  expression:
                    "help ? $t('model.help.move') : $t('tooltip.modelBar.move')",
                },
              ],
              staticClass: "c-btn",
              attrs: {
                "active-class": "c-btn--active",
                fab: "",
                "input-value": _vm.isMovable,
                small: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleMoveMode.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-cursor-move "),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.help
                    ? _vm.$t("model.help.rotate")
                    : _vm.$t("tooltip.modelBar.rotate"),
                  expression:
                    "help ? $t('model.help.rotate') : $t('tooltip.modelBar.rotate')",
                },
              ],
              staticClass: "c-btn",
              attrs: {
                "active-class": "c-btn--active",
                fab: "",
                "input-value": _vm.isRotatable,
                small: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleRotateMode.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-sync ")])],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.help
                    ? _vm.$t("model.help.zoom")
                    : _vm.$t("tooltip.modelBar.zoom"),
                  expression:
                    "help ? $t('model.help.zoom') : $t('tooltip.modelBar.zoom')",
                },
              ],
              staticClass: "c-btn",
              attrs: {
                "active-class": "c-btn--active",
                fab: "",
                "input-value": _vm.isScalable,
                small: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleScaleMode.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-arrow-expand "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.modelBar.reset"),
              expression: "$t('tooltip.modelBar.reset')",
            },
          ],
          staticClass: "c-btn c-btn--stateless",
          attrs: { fab: "", small: "", text: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.reset.apply(null, arguments)
            },
          },
        },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v(" mdi-crosshairs-gps "),
          ]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.modelBar.revolve"),
              expression: "$t('tooltip.modelBar.revolve')",
            },
          ],
          staticClass: "c-btn",
          attrs: {
            "active-class": "c-btn--active",
            fab: "",
            "input-value": _vm.isRevolving,
            small: "",
            text: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleRevolution.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-fan ")])],
        1
      ),
      _vm.hasAnnotations
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.modelBar.annotations"),
                  expression: "$t('tooltip.modelBar.annotations')",
                },
              ],
              staticClass: "c-btn c-btn--stateful",
              attrs: {
                "active-class": "c-btn--active",
                depressed: "",
                fab: "",
                "input-value": _vm.showAnnotations,
                small: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleAnnotations.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-tag ")])],
            1
          )
        : _vm._e(),
      _vm.hasMoreThanOneScene
        ? _c("SceneSelector", {
            attrs: { scenes: _vm.scenes },
            on: {
              select: function ($event) {
                return _vm.selectScene($event)
              },
            },
            model: {
              value: _vm.selectedSceneId,
              callback: function ($$v) {
                _vm.selectedSceneId = $$v
              },
              expression: "selectedSceneId",
            },
          })
        : _vm._e(),
      _vm.hasAnimations
        ? _c("AnimationSelector", {
            attrs: { animations: _vm.animations },
            on: {
              select: function ($event) {
                return _vm.selectAnimation($event)
              },
            },
          })
        : _vm._e(),
      _vm.hasLayers
        ? _c("LayerSelector", {
            attrs: { layers: _vm.layers },
            on: {
              select: function ($event) {
                return _vm.toggleLayer($event)
              },
            },
          })
        : _vm._e(),
      _vm.description
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.modelBar.description"),
                  expression: "$t('tooltip.modelBar.description')",
                },
              ],
              staticClass: "c-btn",
              attrs: {
                "active-class": "c-btn--active",
                fab: "",
                "input-value": _vm.showInfo,
                small: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleInfo.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-information-variant-circle-outline "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }