import posthog from 'posthog-js'
import { POSTHOG_API_URL, POSTHOG_KEY } from '@/config/appConfig.js'

/* Custom Events
 * - app_start
 * - ad_click
 * - ad_view
 * - channel_view
 * - channel_close
 * - content_view
 * - content_close
 * - post_view
 * - post_close
 * - user_login
 * - user_logout
 * - pageview
 * - pageleave
 */
const onStartup = () => posthog.capture('app_start')

class AnalyticsService {
  constructor() {
    this.type = ''
    this.trace = false
    this.block = false

    const posthogKey = POSTHOG_KEY
    if (posthogKey) {
      // see https://posthog.com/docs/libraries/js#config
      posthog.init(posthogKey, {
        api_host: POSTHOG_API_URL,
        // https://posthog.com/docs/product-analytics/autocapture#autocaptured-properties
        autocapture: true,
        capture_pageview: false,
        capture_pageleave: false,
        disable_session_recording: true,
        enable_recording_console_log: false,
        enable_heatmaps: true,
        loaded: onStartup,
        opt_out_capturing_by_default: false,
        opt_out_persistence_by_default: false,
        secure_cookie: true,
        session_idle_timeout_seconds: 1800, // 30 minutes

        // pre-filtering and sampling of events
        before_send: (event) => {
          if (event) {
            if (event.event === '$pageview') {
              // remove login hash from  urls
              event.properties.$current_url = event.properties.$current_url.split('#')[0]
              if (this.trace) {
                console.debug(`[posthog]: ${event.event} - ${event.properties.$current_url}`)
              }
            } else {
              if (this.trace) {
                console.debug(`[posthog]: ${event.event} - ${JSON.stringify(event.properties)}`)
              }
            }

            // return event to log for real
            return this.block ? null : event
          } else {
            return null
          }
        }
      })
    }
  }

  activate({ w, d, keys }) {
    // GTM
    if (keys.gtm) {
      console.debug('[analyticsService]: Activating Google Tag Manager.')
      const s = 'script'
      const l = 'dataLayer'
      const i = keys.gtm

      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    }

    if (keys.posthog) {
      console.debug('[analyticsService]: Activating Posthog.')
    }
  }

  identify({ name, email, subject }) {
    if (this.trace) {
      console.debug('identify=', name, email, subject)
    }
    posthog.identify(subject, { name, email, subject })
  }

  capture(event, payload) {
    /*
    // Google Analytics (via GTM)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'pageView',
      ...payload
    })
    */

    // Posthog
    posthog.capture(event, payload)
  }

  reset() {
    posthog.reset(true)
  }
}

export default AnalyticsService
export const analyticsService = new AnalyticsService()
