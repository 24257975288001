import ICS from 'vue-ics'
import { VuePlugin } from 'vuera'
import renderMixin from '@/mixins/renderMixin.js'

export class MiscPlugin {
  static install(Vue, _options) {
    // generate .ics calendar files
    Vue.use(ICS, {
      uidDomain: 'metaspark',
      prodId: 'lumenii'
    })

    // call React components from Vue
    Vue.use(VuePlugin)

    // debug rendering
    Vue.mixin(renderMixin)
  }
}
