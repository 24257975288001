<template>
  <v-list-item @click.stop="updateMode">
    <!-- make the menu item icon itself clickable -->
    <v-list-item-action>
      <ModeSwitcher
        ref="modeSwitcher1"
        :activator="false"
        :mode="isDark"
        reverse
      />
    </v-list-item-action>
    <!-- use a toggle switch as the menu item value -->
    <v-list-item-title class="d-flex align-center justify-space-between text-h6 text-left">
      {{ $t('account.mode') }}
      <ModeSwitcher
        ref="modeSwitcher2"
        :activator="false"
        :mode="isDark"
        switch
      />
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import ModeSwitcher from '@/components/account/ModeSwitcher'

export default {
  name: 'ModeMenuItem',

  components: {
    ModeSwitcher
  },

  props: {},

  data: function () {
    return {}
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    }
  },

  methods: {
    updateMode() {
      this.$refs.modeSwitcher1.toggleMode()
      this.$refs.modeSwitcher2.toggleMode()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
