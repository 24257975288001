var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SectionPanel",
    {
      attrs: {
        colour: _vm.colour,
        count: _vm.entriesCount,
        loading: _vm.loading,
        title: _vm.title,
      },
    },
    [
      _c("CalendarViewer", {
        attrs: { events: _vm.entries, period: "day" },
        on: {
          "update:period": function ($event) {
            return _vm.$emit("update:peried", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }