<template>
  <div>
    <v-card class="c-result-card pa-4 mb-4" elevation="4">
      <!-- summary -->
      <v-card-title class="d-flex justify-center pt-0 pb-3">
        <v-icon v-if="score >= passingGrade" color="success" x-large>
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error" x-large>
          mdi-alpha-x-circle
        </v-icon>
        <div class="c-score">
          {{ $t('quiz.status.score') }} {{ formattedScore() }} / {{ quizLength }}
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-actions class="c-actions py-0">
        <v-btn class="mr-4" color="primary" @click="$emit('review')">
          {{ $t('quiz.action.review') }}
        </v-btn>
        <v-btn v-if="quiz.reference" class="mr-4" color="primary" @click="$emit('learn')">
          {{ $t('quiz.action.learn') }}
        </v-btn>
        <v-btn color="primary" @click="$emit('retake')">
          {{ $t('quiz.action.retake') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <QuizSourceCard
      v-if="source"
      :source="source"
    />

    <QuizReferencesCard
      v-if="references.length > 0"
      :references="references"
    />
  </div>
</template>

<script>
import { quizService } from '@/services/quizService.js'
import QuizReferencesCard from '@/components/quiz/QuizReferencesCard'
import QuizSourceCard from '@/components/quiz/QuizSourceCard'

export default {
  name: 'QuizResultCard',

  components: {
    QuizReferencesCard,
    QuizSourceCard
  },

  props: {
    quiz: {
      type: Object,
      required: true
    },

    source: {
      type: Object,
      required: false,
      default: null
    },

    startDate: {
      type: Number,
      required: false,
      default: Date.now()
    },

    references: {
      type: Array,
      required: false,
      default: () => []
    },

    userResponses: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      score: {}
    }
  },

  computed: {
    passingGrade() {
      return this.quiz.pass || this.quizLength * 0.5
    },

    percentageScore() {
      return (this.score / this.quizLength) * 100
    },

    quizLength() {
      return this.quiz.questions.length
    }
  },

  created: function () {
    const quizResponses = quizService.generateResponses(this.quiz, this.userResponses)
    this.score = quizService.calculateScore(quizResponses)
    this.postResults(quizResponses)
  },

  methods: {
    formattedScore() {
      return this.score.toFixed(2).replace(/[.,]00$/, '')
    },

    postResults(quizResponses) {
      const startDate = this.startDate
      const finishDate = Date.now()

      const user = {
        name: this.$auth.getUserDisplayName(),
        email: this.$auth.getUserEmail()
      }

      quizService.postResults({
        startDate,
        finishDate,
        quiz: this.quiz,
        user,
        quizResponses,
        score: this.score
      })
    }
  }
}
</script>

<style lang="css" scoped>
.c-result-card {
  text-align: center;
  width: 100%;
}

.c-actions {
  display: flex;
  justify-content: center;
}
</style>
