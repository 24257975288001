<template>
  <v-menu
    v-model="isOpen"
    close-on-click
    close-on-content-click
    max-height="80%"
    offset-y
    top
    transition="slide-y-reverse-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-tooltip="$t('tooltip.modelBar.scenes')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        small
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ isOpen ? 'mdi-close' : 'mdi-sitemap' }}
        </v-icon>
      </v-btn>
    </template>
    <SceneList
      v-model="selectedSceneId"
      :scenes="scenes"
      @select="$emit('select', $event)"
      @sync="syncScene($event)"
    />
  </v-menu>
</template>

<script>
import SceneList from './SceneList'

export default {
  name: 'SceneSelector',

  components: {
    SceneList
  },

  model: {
    prop: 'selected',
    event: 'select'
  },

  props: {
    selected: {
      type: String,
      required: true
    },

    scenes: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      transition: 'slide-y-transition',
      selectedSceneId: this.selected
    }
  },

  mounted: function () {
    // listen to the Player side drawer
    this.$bus.$on('sync:drawer', ({ selectedSceneId }) => {
      this.selectedSceneId = selectedSceneId
      this.$emit('select', selectedSceneId)
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:drawer')
  },

  methods: {
    syncScene(selectedSceneId) {
      // notify the Player side drawer
      this.$bus.$emit('sync:selector', {
        selectedSceneId,
        selectedAnimationIds: []
      })
      this.isOpen = false // 'close-on-content-click' not working
    }
  }
}
</script>

<style lang="css" scoped></style>
