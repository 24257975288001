<template>
  <div class="c-unity-app">
    <div v-if="showLoadingScreen" class="c-loading-screen">
      <UnityAppLoadingScreen :progress="progress" />
    </div>
    <!-- the following div exists *behind* the loading screen -->
    <div :id="containerId" v-resize="resizeCanvas" class="c-container">
      <canvas id="unity-canvas" ref="unityCanvas" class="c-canvas" />
    </div>
  </div>
</template>

<script>
// refer to Unity docs: https://docs.unity3d.com/560/Documentation/Manual/webgl-templates.html
import Vue from 'vue'
import { EventNames } from '@/players/ModelPlayer/constants/ControlConstants'
import { MethodNamesPre2020, MethodNames2020 } from '../constants/UnityConstants'
import UnityAppLoadingScreen from './UnityAppLoadingScreen'
// import unityInstantiate from '../scripts/unityInstantiate'
import mobileMixin from '@/mixins/mobileMixin.js'

const LOADER_TIMEOUT = 45000 // 45 seconds

export default {
  name: 'UnityApp',

  components: {
    UnityAppLoadingScreen
  },

  mixins: [mobileMixin],

  props: {
    src: {
      type: String,
      required: true
    },

    loader: {
      type: String,
      required: false,
      default: () => null
    },

    module: {
      type: Object,
      required: false,
      default: () => null
    },

    width: {
      type: String,
      required: false,
      default: () => '960'
    },

    height: {
      type: String,
      required: false,
      default: () => '600'
    }
  },

  data: function () {
    return {
      // unity objects
      appInstance: null,
      unityLoader: null,

      // template elements
      containerId: 'unity-container',
      // + Number(Math.random().toString().substr(3) + Date.now()).toString(36),
      canvas: null,
      canvasSize: { width: 0, height: 0 },
      container: null,

      // loading controls
      isLoading: true,
      isWebGL2Supported: false,
      loaded: false,
      loaderTimeout: null,
      progress: 0.0,
      showLoadingScreen: true
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    browserName() {
      return this.mobileMixin_browserName
    },

    hasMobileModel() {
      const url = new URL(this.src)
      return url.searchParams.has('m') // 'm' for 'mobile'
    },

    buildURL() {
      const str = this.src
      return str.substring(0, str.lastIndexOf('/'))
    },

    buildName() {
      const url = new URL(this.src)
      const str = url.pathname
      const name =
        this.unityVersion === 'pre2020'
          ? str.substring(str.lastIndexOf('/')).slice(0, -5) // remove '.json'
          : str.substring(str.lastIndexOf('/')).slice(0, -10) // remove 'loader.js'
      return this.isReallyMobile && this.hasMobileModel ? name.slice(1) + '-Mobile' : name.slice(1)
    },

    loaderURL() {
      return this.unityVersion === 'pre2020'
        ? this.loader || this.buildURL + '/UnityLoader.js'
        : this.loader || this.buildURL + `/${this.buildName}.loader.js`
    },

    unityConfig() {
      return this.unityVersion === 'pre2020'
        ? {
            dataUrl: this.buildURL + `/${this.buildName}.data.unityweb`,
            // devicePixelRatio = this.isMobile ? 1 : 2,
            frameworkUrl: this.buildURL + `/${this.buildName}.wasm.framework.unityweb`,
            codeUrl: this.buildURL + `/${this.buildName}.wasm.code.unityweb`,
            companyName: 'MetaSpark Technology Group',
            productName: this.$appConfig.appName,
            productVersion: 'V0'
          }
        : {
            dataUrl: this.buildURL + `/${this.buildName}.data.gz`,
            // devicePixelRatio = this.isMobile ? 1 : 2,
            frameworkUrl: this.buildURL + `/${this.buildName}.framework.js.gz`,
            codeUrl: this.buildURL + `/${this.buildName}.wasm.gz`,
            companyName: 'MetaSpark Technology Group',
            productName: 'Lumenii',
            productVersion: 'V0'
          }
    },

    unityVersion() {
      return this.src.toLowerCase().endsWith('.json') ? 'pre2020' : 'post2020'
    }
  },

  beforeMount: function () {
    if (!this.eventBus) {
      this.eventBus = new Vue({
        data: {
          pre2020: {
            ready: false,
            loaded: false
          },
          post2020: {
            ready: false,
            loaded: false
          }
        }
      })
    }

    // check if loader singleton already exists
    if (this.eventBus[this.unityVersion].isLoaderAdded) {
      this.eventBus[this.unityVersion].isLoaderReady = true
    } else {
      // create the script tag
      const script = document.createElement('script')

      // listen for script to load
      script.onload = () => {
        this.eventBus[this.unityVersion].isLoaderReady = true
        this.eventBus.$emit('onload')
      }

      // listen for script loading to fail
      script.onerror = () => {
        console.error('[UnityApp]: Error while loading', this.loaderURL)
        this.abort({
          title: this.$t(`error.UnityLoaderError.title`),
          text: this.$t(`error.UnityLoaderError.message`),
          footer: this.loaderURL
        })
      }

      // update the document html
      script.setAttribute('src', this.loaderURL)
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      document.head.appendChild(script)
      this.eventBus[this.unityVersion].isLoaderAdded = true
    }
  },

  mounted: function () {
    // record any loader script runtime errors
    window.addEventListener('error', this.onLoaderError)

    // initialize document references
    this.container = document.querySelector(`#${this.containerId}`)

    // check device / os / browser support (and only instantiate the app if device is supported)
    this.isWebGL2Supported = !!document.createElement('canvas').getContext('webgl2')

    const isSupported = this.checkPlatform()
    if (!isSupported) return

    // leverage event bus to detect when the loader script is loaded
    this.eventBus[this.unityVersion].isLoaderReady
      ? this.instantiate()
      : this.eventBus.$on('onload', this.instantiate)
  },

  beforeDestroy: async function () {
    clearTimeout(this.loaderTimeout)
    this.sendUnityMessage({ event: EventNames.EXIT })

    // const alert = window.alert
    // window.alert = () => {}
    try {
      await this.appInstance?.Quit()
    } catch {
      console.error('[UnityApp]: Quit not implemented.')
    }
    // window.alert = alert

    window.removeEventListener('error', this.onLoaderError)
  },

  methods: {
    /* instantiate the Unity app */

    instantiate() {
      // set a timeout in case the model doesn't load
      this.loaderTimeout = setTimeout(async () => {
        console.error(`[UnityApp]: Timed out waiting for Unity app to load (${this.loaderURL}).`)
        await this.abort({
          title: this.$t(`error.TimeoutError.title`),
          text: this.$t(`error.TimeoutError.message`),
          footer: this.loaderURL
        })
      }, LOADER_TIMEOUT)

      // instantiate the app instance (dependent on Unity version)
      this.isLoading = true
      this.unityVersion === 'pre2020'
        ? this.instantiatePre2020() // synchronous
        : this.instantiatePost2020() // asynchronous
    },

    // pre version 2020.1 instantiation (synchronous)
    instantiatePre2020() {
      if (typeof window.UnityLoader === 'undefined') {
        console.error('[UnityApp]: window.UnityLoader global object is undefined.')
        this.cannotInstantiate('window.UnityLoader is undefined')
        return
      }

      // setup environment compatibility checker
      window.UnityLoader.compatibilityCheck = this.checkCompatibility

      // this.appInstance = unityInstantiate(this.containerId, this.src, {
      this.appInstance = window.UnityLoader.instantiate(this.containerId, this.src, {
        onProgress: (appInstance, progress) => {
          if (appInstance.Module) this.trackProgress(progress)
        },
        ...(this.module && { Module: this.module })
      })

      if (this.appInstance) {
        this.makeFullscreen()
        this.canvas = document.getElementById('#canvas') // selector=##canvas
      } else {
        console.error('[UnityApp]: appInstance is null.')
        this.cannotInstantiate('appInstance is null.')
      }
    },

    // post version 2020.1 instantiation (asynchronous)
    async instantiatePost2020() {
      // this.canvas = document.querySelector('#unity-canvas')
      this.canvas = this.$refs.unityCanvas

      try {
        this.appInstance = await window.createUnityInstance(
          this.canvas,
          this.unityConfig,
          this.trackProgress
        )
      } catch (error) {
        console.error('[UnityApp]: Unable to load Unity app.', error)
        await this.cannotInstantiate(error)
      }
    },

    async cannotInstantiate(error) {
      await this.abort({
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        footer: error
      })
    },

    async abort({ title, text, footer }) {
      clearTimeout(this.loaderTimeout)
      const result = await this.$alert({
        icon: 'error',
        title: title,
        text: text,
        footer: footer
      })
      if (result.isConfirmed) {
        this.showLoadingScreen = false
        this.$emit('error')
      }
    },

    // report errors during loader script execution
    onLoaderError(event) {
      const { message } = event
      console.error('[UnityApp]:', event)
      if (message.startsWith('ResizeObserver loop')) {
        // console.warn('Ignored: ResizeObserver loop completed with undelivered notifications.')
        return false
      }

      this.abort({
        title: this.$t(`error.UnityLoaderError.title`),
        text: this.$t(`error.UnityLoaderError.message`),
        footer: message
      })
    },

    // update the progress bar on the app loading screen
    trackProgress(progress) {
      if (this.progress < progress) {
        this.progress = progress
      }

      this.loaded = progress >= 1

      if (this.loaded) {
        clearTimeout(this.loaderTimeout)
        this.isLoading = false
        // wait a bit to see the loading bar at 100%
        setTimeout(() => {
          this.showLoadingScreen = false
          this.$emit('loaded')
        }, 500)
      }
    },

    /* platform checks (device / os / browser support) */

    // pre2020 loader compatibility checks
    checkCompatibility(instance, onSuccess, onError) {
      const unityLoader = window.UnityLoader
      const browserName = unityLoader.SystemInfo.browser
      const isMobile = unityLoader.SystemInfo.mobile
      const supportsWebGL2 = unityLoader.SystemInfo.hasWebGL === 2

      const compatible = this.validatePlatform({
        browserName,
        isMobile,
        supportsWebGL2
      })

      compatible ? onSuccess() : onError()

      return compatible
    },

    // post2020 compatibity checks
    checkPlatform() {
      const browserName = this.browserName
      const isMobile = this.isReallyMobile
      const supportsWebGL2 = this.isWebGL2Supported

      return this.validatePlatform({
        browserName,
        isMobile,
        supportsWebGL2
      })
    },

    // universal compatibility checker
    validatePlatform({ browserName, isMobile, supportsWebGL2 }) {
      const supportedBrowsers = ['Chrome', 'Edge', 'Firefox', 'Safari']
      const isMobileDeviceSupported = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

      let compatible = false

      supportedBrowsers.includes(browserName)
        ? supportsWebGL2
          ? isMobile
            ? (compatible = isMobileDeviceSupported)
            : (compatible = true)
          : this.abort({
              title: this.$t(`error.UnityWebGLError.title`),
              text: this.$t(`error.UnityWebGLError.message`),
              footer: browserName
            })
        : this.abort({
            title: this.$t(`error.UnityBrowserWarning.title`),
            text: this.$t(`error.UnityBrowserWarning.message`),
            footer: browserName
          })

      return compatible
    },

    /* manage canvas size */

    makeFullscreen() {
      this.appInstance?.SetFullscreen(1)
    },

    resizeCanvas() {
      // canvas may not exist yet and post2020 works already
      if (!this.canvas || this.unityVersion === 'post2020') return

      // get the width and height of the canvas' container
      const width = this.container.clientWidth
      const height = this.container.clientHeight

      // update the canvas size and re-render the WebGL viewport
      if (this.canvas.width !== width || this.canvas.height !== height) {
        this.canvas.width = width
        this.canvas.height = height
        const gl = this.canvas.getContext('webgl2')
        gl.viewport(0, 0, this.canvas.width, this.canvas.height)
      }

      this.canvasSize = { width: this.canvas.width, height: this.canvas.height }
    },

    /* manage Unity app */

    sendUnityMessage({ event, param = '' }) {
      const methodNames = this.unityVersion === 'pre2020' ? MethodNamesPre2020 : MethodNames2020
      const gameObject = methodNames[event][0]
      const methodName = methodNames[event][1]

      this.appInstance?.SendMessage(gameObject, methodName, param)
    }
  }
}
</script>

<style lang="css" scoped>
.c-container,
.c-loading-screen {
  height: calc(var(--c-content-viewport-height));
  width: 100%;
}

.c-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-canvas {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
