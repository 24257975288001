<template>
  <v-list-group class="c-list-group" @click.stop>
    <template #activator>
      <v-list-item-action>
        <v-icon color="accent" x-large> mdi-translate </v-icon>
      </v-list-item-action>
      <v-list-item-title class="text-h6 text-left">
        {{ currentLocaleName }}
      </v-list-item-title>
    </template>
    <LocaleSelector class="ml-8" />
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleSelector from '@/components/account/LocaleSelector'

export default {
  name: 'LocaleMenuItem',

  components: {
    LocaleSelector
  },

  props: {},

  data: function () {
    return {}
  },

  computed: {
    ...mapGetters('i18nStore', ['getLocaleName']),

    currentLocaleName() {
      return this.getLocaleName(this.$store.state.i18nStore.locale)
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
