<template>
  <v-app id="app" class="c-app">
    <CircularProgress
      v-if="loading"
      class='c-init'
      :logo="orgLogo"
      :message="loadingMessage"
    />
    <template v-else>
      <keep-alive include="AppLayout">
        <component :is="layout" />
      </keep-alive>
    </template>

    <!-- pwa update notice -->
    <AppUpdate />

    <!-- app tour -->
    <AppTour
      v-if="hasTour && $feature('tours')"
      ref="tour"
      :dialog="layout === 'AppLayout'"
      :tour="tourName"
    />

    <!-- meeting portlet -->
    <v-dialog
      v-if="showMeetingPortlet"
      v-model="showMeetingPortlet"
      content-class="c-meeting-dialog"
      eager
      :fullscreen="$vuetify.breakpoint.xsAndDown"
      overlay-opacity="0.2"
      transition="scale-transition"
      width="80%"
      max-width="600px"
      min-width="300px"
      @click:outside="showMeetingPortlet = false"
      @keydown.esc="showMeetingPortlet = false"
    >
      <MeetingWindow
        ref="meetingWindow"
        v-model="showMeetingPortlet"
        :jwt="jwt"
        :room="room"
        :subject="subject"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

import AppTour from '@/components/app/AppTour'
import AppUpdate from '@/components/app/AppUpdate'
import CircularProgress from '@/components/base/CircularProgress'
import MeetingWindow from '@/components/meetings/MeetingWindow'

import exitMixin from '@/mixins/exitMixin.js'
import layoutMixin from '@/mixins/layoutMixin.js'
import mobileMixin from '@/mixins/mobileMixin.js'
import pwaMixin from '@/mixins/pwaMixin.js'

import { orgService } from '@/services/orgService'
import localhostOrg from '@/config/localhostOrg.json'
import { hexToRGBA } from '@/utilities/colourUtils.js'
import { realNextTick } from '@/utilities/realNextTick'
import { analyticsService } from './services/analyticsService'

const defaultLayout = 'ScreenLayout' // avoids premature rendering of app layout elements

class TenantError extends Error {
  constructor(message, options = {}) {
    super(message, options)
    this.name = 'TenantError'
  }
}

export default {
  name: 'App',

  components: {
    AppTour,
    AppUpdate,
    CircularProgress,
    MeetingWindow
  },

  mixins: [exitMixin, layoutMixin, mobileMixin, pwaMixin],

  data: function () {
    return {
      // app data
      isLocal: false,
      loading: true, // avoids a forced reflow
      loadingMessage: this.$t('message.initializing'),
      orgService: orgService,
      portalManifest: null,

      // meeting data
      showMeetingPortlet: false,
      jwt: null,
      room: '',
      subject: ''
    }
  },

  computed: {
    /* app settings */

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    layout() {
      return this.$route.meta.layout || defaultLayout
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* tour control */

    tourName() {
      return 'tour-' + this.layout
    },

    hasTour() {
      return ['AppLayout', 'PageLayout', 'PlayerLayout'].includes(this.layout)
    },

    /* tenant/org settings */

    tenantKey() {
      return this.$store.state.tenantStore.tenantKey
    },

    tenant() {
      return this.$store.state.tenantStore.tenant
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    orgKey() {
      return this.$store.state.tenantStore.orgKey
    },

    orgLogo() {
      // since org is being loaded, assume a naming convention
      return `/img/logos/${this.tenantKey}-logo.png`
    },

    /* analytics settings */

    gtmId() {
      return this.$store.state.orgStore.gtmId
    },

    posthogKey() {
      return this.$store.state.orgStore.posthogKey
    },

    /* manifest settings */

    appName() {
      return this.portal?.title?.[this.locale] || this.$appConfig.appName
    },

    assetRoot() {
      return this.$store.state.orgStore.assetRoot
    },

    accentColour() {
      return this.$vuetify.theme.currentTheme.accent
      /*
      return this.isDark
        ? this.$vuetify.theme.themes.dark.accent
        : this.$vuetify.theme.themes.light.accent
      */
    },

    primaryColour() {
      return this.isDark
        ? this.$vuetify.theme.themes.dark.primary
        : this.$vuetify.theme.themes.light.primary
    },

    orgName() {
      return this.$store.state.orgStore.orgName
    },

    portal() {
      return this.$store.state.portalStore.activePortal
    },

    isChromeOrEdge() {
      return this.mobileMixin_isChromeOrEdge
    },

    isSafari() {
      return this.mobileMixin_isSafari
    },

    /* content settings */

    collections() {
      return this.$store.state.contentStore.collections
    }
  },

  watch: {
    accentColour: {
      immediate: true,
      handler: function (newColour, _oldColour) {
        const rgba = hexToRGBA(newColour, 0.25)
        document.documentElement.style.setProperty('--c-accent-light', rgba)
      }
    },

    primaryColour: {
      immediate: true,
      handler: function (newColour, _oldColour) {
        const rgba = hexToRGBA(newColour, 0.25)
        document.documentElement.style.setProperty('--c-primary-light', rgba)
      }
    },

    layout: {
      immediate: false,
      handler: function (_newLayout, _oldLayout) {
        realNextTick(() => {
          // this.$refs.tour.suspendTour()
          // this.$refs.tour.refreshTour()
          // this.$refs.tour.resumeTour()
        })
      }
    },

    portalManifest: {
      immediate: false,
      handler: function (newManifest, _oldManifest) {
        this.$nextTick(() => this.pwaMixin_updateManifestAndHeadTags(newManifest))
      }
    }
  },

  beforeCreate: async function () {
    // setup all the store modules from local storage
    // (use dispatch since methods are not initialized yet)
    await this.$store.dispatch('restoreState', { vm: this }, { root: true })
  },

  created: async function () {
    await this.initializeTenant()
  },

  mounted: function () {
    this.$appConfig.historyStart = window.history.length
    this.addBusListeners()
    this.addEventListeners()
    this.$nextTick(() => {
      this.setDimensions()
    })
  },

  beforeDestroy: function () {
    this.removeEventListeners()
  },

  methods: {
    ...mapActions(['restoreState', 'reflectOrg']),
    ...mapActions('appStore', ['updateOnlineStatus']),
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('channelStore', ['fetchChannels']),
    ...mapActions('contentStore', ['fetchLibrary', 'fetchCollectionItems']),

    /* manage events */

    addBusListeners() {
      // exit the app
      this.$bus.$on('exit', () => {
        this.exitMixin_exit()
      })

      // show the portal window
      this.$bus.$on('meeting:show', (meeting) => {
        this.jwt = meeting.jwt
        this.room = meeting.room
        this.subject = meeting.subject
        this.showMeetingPortlet = true
        console.debug('[App]: meeting:show event received. Meeting=', meeting)
      })

      // reset when the portal window is blocked
      this.$bus.$on('meeting:reset', () => {
        this.jwt = null
        this.room = null
        this.subject = null
        this.showMeetingPortlet = false
      })

      // this event occurs from the Vue instance within the portal window
      this.$bus.$on('meeting:hide', () => {
        self.close()
      })
    },

    addEventListeners() {
      window.addEventListener('resize', this.setDimensions)
      window.addEventListener('online', this.changeStatus)
      window.addEventListener('offline', this.changeStatus)
      window.addEventListener('load', this.onLoad)
      document.addEventListener('DOMContentLoaded', this.onDOMContentLoaded)
    },

    removeEventListeners() {
      window.removeEventListener('resize', this.setDimensions)
      window.removeEventListener('online', this.changeStatus)
      window.removeEventListener('offline', this.changeStatus)
      window.removeEventListener('load', this.onLoad)
      document.removeEventListener('DOMContentLoaded', this.onDOMContentLoaded)
    },

    /* setup tenant */

    async initializeTenant() {
      try {
        // retrieve the tenant record
        const tenant = await this.$store.dispatch('tenantStore/fetchTenant')

        // initialize the authentication/authorization services
        // this.loadingMessage = this.$t('message.authenticating')
        await this.$auth.initialize(tenant)

        // fetch and configure the current org (for this tenant)
        // this.loadingMessage = this.$t('message.configuring')
        await this.setupOrg(this.orgKey)

        // iniitialize analytics
        analyticsService.activate({
          w: window,
          d: document,
          keys: { gtm: this.gtmId, posthog: this.posthogKey }
        })

        // update the PWA manifest
        this.portalManifest = {
          appName: this.appName,
          appDescription: this.portal.description?.[this.locale] || '',
          assetRoot: this.assetRoot,
          orgName: this.orgName,
          themeColour: this.primaryColour,
          tenantKey: this.tenantKey,
          isSafari: this.isSafari
        }

        // prime the content/post/channel caches
        // this.loadingMessage = this.$t('message.priming')
        await this.primeCaches()
      } catch (error) {
        this.loading = false
        this.$auth.cancel(false)
        this.$error(new TenantError(error.message), { tenant: this.tenantKey })
      }
    },

    onDOMContentLoaded() {
      // shrink the bottom navigation bar on iOS / Android
    },

    onLoad() {},

    async primeCaches() {
      console.debug('[App]: Priming caches...')
      this.fetchChannels()
      this.fetchPosts()
      this.fetchLibrary()
      console.debug('[App]: Priming caches complete (but async!)...')
    },

    async setupOrg(orgKey) {
      this.loading = true

      try {
        const org = this.isLocal ? localhostOrg : await this.orgService.fetchOrg(orgKey)
        await this.reflectOrg({ org, vm: this.$root })
        return org
      } catch (error) {
        // rely on localStorage values (as loaded in beforeCreate())
        console.error(`[App]: Unable to retrieve org. ${error}.`)
      } finally {
        this.loading = false
      }
    },

    /* capture context */

    changeStatus() {
      const onlineStatus = window.navigator.onLine
      this.updateOnlineStatus(onlineStatus)
    },

    documentHeight() {
      return Math.max(
        document.documentElement.clientHeight,
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight
      )
    },

    setDimensions() {
      const root = document.documentElement

      /* retrieve viewport dimensions */
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      /* set viewport dimensions */
      root.style.setProperty('--c-viewport-width', `${vw}px`)
      root.style.setProperty('--c-viewport-height', `${vh}px`)
      root.style.setProperty('--vh', `${vh / 100}px`)

      /* set scrollbar dimensions */
      const scrollbarSize = this.layoutMixin_calculateScrollbarSize(root)
      root.style.setProperty('--c-scrollbar-height', `${scrollbarSize.height}px`)
      root.style.setProperty('--c-scrollbar-width', `${scrollbarSize.width}px`)
    },

    /* guided tour */

    refreshTour() {
      console.error('refreshTour!')
      this.$refs.tour.refreshTour()
    }
  }
}
</script>

<style lang="css" scoped>
.c-app {
}
.c-init {
  height: 100vh;
  height: 100dvh;
}
.c-meeting-dialog {
}
</style>

<!-- Global Styles -->
<style lang="css">
@import './assets/styles/globals.css';

.v-application--wrap {
  min-height: 100vh !important;
  min-height: 100dvh !important;
}
</style>
