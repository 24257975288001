<template>
  <div ref="leaderboard" class="c-leaderboard-ads">
    <AdSpot
      v-model="showAds"
      class="c-carousel"
      carousel
      :max="3"
      optimize
      zone="leaderboard"
      @update:show="$emit('close')"
      @count="onCount($event)"
    />
    <AdSpot
      v-if="showHero"
      class="c-hero"
      :max="1"
      rollup
      zone="hero"
    />
  </div>
</template>

<script>
import AdSpot from '@/components/ad/AdSpot'
import mobileMixin from '@/mixins/mobileMixin.js'
import { isTouringActive } from '@/services/tourService.js'

export default {
  name: 'AdLeaderboard',

  components: {
    AdSpot
  },

  mixins: [mobileMixin],

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    hero: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      observer: null,
      showAds: this.show
    }
  },

  computed: {
    showHero() {
      return this.hero && !isTouringActive()
    }
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.showAds = newShow
      }
    }
  },

  created: function () {
    this.observer = new ResizeObserver(this.onResize)
  },

  mounted: function () {
    this.observer.observe(this.$refs.leaderboard)
  },

  beforeDestroy: function () {
    if (this.observer) this.observer.disconnect()
  },

  methods: {
    onClose() {
      this.showAds = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onCount(adCount) {
      this.$emit('count', adCount)

      if (adCount === 0) {
        // this.onClose()
        /*
         * if this ad component is closed (because ads have not loaded yet)
         * and the parent component removes it (via a v-if), the parent
         * has no awareness of ads finally loading (hence doesn't reverse the v-if)
         */
      }
    },

    onResize(entries) {
      const height = entries[0].contentRect.height
      if (height > 0 && height !== this.headerHeight) {
        this.$emit('height', height)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-leaderboard-ads {
  width: 100%;
  max-width: 100%; /* flex item: so it doesn't overflow */
  min-width: 0; /* flex item: so it can shrink */
}
</style>
