const initialState = {
  // persisted state
  posts: [],
  headers: [],
  // transient state
  fetched: false, // true if a succesful fetch occured in current session
  loading: undefined, // undefined -> true -> false during fetch
  promise: undefined,
  // storage
  storage: 'indexDB'
}

export default () => initialState
