import Vue from 'vue'
import Vuex from 'vuex'

import { DEFAULT_CALENDAR_OVERLAP_MODE } from '@/config/appConfig.js'

Vue.use(Vuex)

const initialState = {
  overlapMode: DEFAULT_CALENDAR_OVERLAP_MODE
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setOverlapMode(state, overlapMode) {
      state.overlapMode = overlapMode
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    updateOverlapMode({ commit }, { overlapMode }) {
      commit('setOverlapMode', overlapMode)
    }
  }
}
