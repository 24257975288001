var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CardPanel", {
    attrs: {
      items: _vm.loading ? _vm.skeletonItems : _vm.items,
      dense: _vm.dense,
      draggable: _vm.draggable,
      "drag-type": "content",
      loading: _vm.loading,
      filters: _vm.contentFilters,
      layouts: _vm.layouts,
      preference: _vm.preference,
      sorts: _vm.contentSortOptions,
      title: _vm.title,
      "tree-attribute": _vm.treeAttribute,
      width: _vm.width,
    },
    scopedSlots: _vm._u([
      {
        key: "card",
        fn: function (props) {
          return [
            _vm.loading
              ? _c("ContentCardSkeleton")
              : _c("ContentCard", {
                  attrs: { item: props.item },
                  on: {
                    "view:item": function ($event) {
                      return _vm.$emit("view:item", props.item)
                    },
                    "view:parent": function ($event) {
                      return _vm.$emit("view:parent", props.item.parent)
                    },
                    share: function ($event) {
                      return _vm.$emit("share", props.item)
                    },
                  },
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }