<template>
  <div class="c-error-message">
    <v-alert
      :title="title"
      type="error"
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',

  components: {},

  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  }
}
</script>

<style lang="css" scoped>
</style>
