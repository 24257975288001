import axios from 'axios'
import { fetchAds } from './ad'
import { fetchChannel, fetchChannels } from './channel'
import { fetchContent, fetchContentItem, getContentHeaders } from './content'
import { fetchOrg, fetchOrgs, fetchOrgPersons } from './organization'
import { fetchPerson, fetchPersons, getPersonHeaders } from './person'
import { fetchPost, fetchPosts, getPostHeaders, incrementLikes } from './post'
import { fetchKey, saveSubscription, deleteSubscription, updateSubscription } from './push'
import { fetchQuizBySlug, fetchQuizzes, postResults } from './quiz'
import { fetchTenant, fetchTenants } from './tenant'
import { fetchUser, fetchUserTenants, addUser, updateUser, verifyUser } from './user'
import { fetchComments, fetchReplies, addComment, modifyComment, removeComment } from './comment'
import { uploadFile } from './file'

export const fetchCalendarEntries = (start, end) => {
  return axios
    .get(`https://myserver.azurewebsites.com/events?start=${start}&end=${end}`)
    .then((response) => response.data)
}

export const fetchCompanies = () => {
  return axios.get('https://myserver.azurewebsites.com/companies').then((response) => response.data)
}

export const fetchContacts = () => {
  return axios.get('https://myserver.azurewebsites.com/contacts').then((response) => response.data)
}

export const fetchNotices = () => {
  return axios.get('https://myserver.azurewebsites.com/notices').then((response) => response.data)
}

export const fetchSystemMessage = () => {
  return axios
    .get('https://myserver.azurewebsites.com/systemMessage')
    .then((response) => response.data)
}

export default {
  // ads
  fetchAds,
  // calendar
  fetchCalendarEntries,
  // channels
  fetchChannel,
  fetchChannels,
  // clients
  fetchCompanies,
  fetchContacts,
  // comment
  fetchComments,
  fetchReplies,
  addComment,
  modifyComment,
  removeComment,
  // content
  fetchContent,
  fetchContentItem,
  getContentHeaders,
  // files
  uploadFile,
  // inline messages
  fetchNotices,
  fetchSystemMessage,
  // orgs
  fetchOrg,
  fetchOrgs,
  fetchOrgPersons,
  // persons
  fetchPerson,
  fetchPersons,
  getPersonHeaders,
  // posts
  fetchPost,
  fetchPosts,
  getPostHeaders,
  incrementLikes,
  // push subscriptions
  fetchKey,
  saveSubscription,
  deleteSubscription,
  updateSubscription,
  //quizzes
  fetchQuizBySlug,
  fetchQuizzes,
  postResults,
  // tenants
  fetchTenant,
  fetchTenants,
  // users
  fetchUser,
  fetchUserTenants,
  addUser,
  updateUser,
  verifyUser
}
