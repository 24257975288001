var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    [
      _c(
        "v-list-item-group",
        {
          attrs: { "active-class": "c-list-item--active", mandatory: "" },
          model: {
            value: _vm.activeLocale,
            callback: function ($$v) {
              _vm.activeLocale = $$v
            },
            expression: "activeLocale",
          },
        },
        _vm._l(_vm.locales, function (localeObj, key, i) {
          return _c(
            "v-list-item",
            {
              key: localeObj.code,
              attrs: { value: localeObj.code },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchLocale(localeObj.code)
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                { attrs: { size: "28" } },
                [
                  true
                    ? _c(
                        "v-avatar",
                        {
                          staticClass: "ml-0 mr-4 c-lang-avatar",
                          attrs: { color: _vm.getColour(i) },
                        },
                        [
                          _c("span", { staticClass: "white--text text-h5" }, [
                            _vm._v(" " + _vm._s(localeObj.avatar) + " "),
                          ]),
                        ]
                      )
                    : _c("img", {
                        attrs: {
                          height: "24",
                          src: require("@/assets/images/flags/" +
                            localeObj.icon),
                        },
                      }),
                ],
                1
              ),
              _c("v-list-item-title", { staticClass: "text-h6" }, [
                _vm._v(" " + _vm._s(localeObj.name) + " "),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }