<template>
  <v-divider
    v-if="name === 'divider'"
    class="mx-2"
    vertical
  />
  <v-btn
    v-else
    :disabled="disabled"
    :input-value="active"
    text
    x-small
    @click="$emit('click')"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'RichTextMenuButton',

  components: {},

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
