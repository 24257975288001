var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "c-result-card pa-4 mb-4", attrs: { elevation: "4" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-center pt-0 pb-3" },
            [
              _vm.score >= _vm.passingGrade
                ? _c("v-icon", { attrs: { color: "success", "x-large": "" } }, [
                    _vm._v(" mdi-check-circle "),
                  ])
                : _c("v-icon", { attrs: { color: "error", "x-large": "" } }, [
                    _vm._v(" mdi-alpha-x-circle "),
                  ]),
              _c("div", { staticClass: "c-score" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("quiz.status.score")) +
                    " " +
                    _vm._s(_vm.formattedScore()) +
                    " / " +
                    _vm._s(_vm.quizLength) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "c-actions py-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("review")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.review")) + " ")]
              ),
              _vm.quiz.reference
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("learn")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("quiz.action.learn")) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("retake")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quiz.action.retake")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.source
        ? _c("QuizSourceCard", { attrs: { source: _vm.source } })
        : _vm._e(),
      _vm.references.length > 0
        ? _c("QuizReferencesCard", { attrs: { references: _vm.references } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }