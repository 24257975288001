var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.updateMode.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-list-item-action",
        [
          _c("ModeSwitcher", {
            ref: "modeSwitcher1",
            attrs: { activator: false, mode: _vm.isDark, reverse: "" },
          }),
        ],
        1
      ),
      _c(
        "v-list-item-title",
        {
          staticClass:
            "d-flex align-center justify-space-between text-h6 text-left",
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("account.mode")) + " "),
          _c("ModeSwitcher", {
            ref: "modeSwitcher2",
            attrs: { activator: false, mode: _vm.isDark, switch: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }