import store from '@/store'

export const fetchQuizzes = async () => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/quizzes`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[quizService]:', error)
    return []
  }
}

export const fetchQuizBySlug = async (quizSlug) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/quiz/${quizSlug}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error(`[quizService]: Unable to retrieve quiz=${tenantKey}/${quizSlug}. ${error}`)
    return {}
  }
}

export const postResults = async (quizSlug, quizResults) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...quizResults })
    }

    const response = await fetch(`/api/tenant/${tenantKey}/quiz/${quizSlug}/results`, request)

    if (response.ok) {
      // return await response.json()
      return {}
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[quizService]:', error)
    throw error
  }
}
