<template>
  <v-expansion-panels
    v-model="panels"
    accordian
    flat
    hover
    multiple
    tile
  >
    <slot />
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SectionPanels',

  components: {},

  props: {
    depth: {
      type: Number,
      required: true
    },

    expand: {
      type: Boolean,
      required: false,
      default: false
    },

    initial: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      panels: [...this.initial]
    }
  },

  watch: {
    depth: {
      immediate: false,
      handler: function (_newVal, _oldVal) {
        this.panels = this.expand ? this.expandAll() : [...this.initial]
      }
    },

    expand: {
      immediate: false,
      handler: function (newExpand, _oldVal) {
        this.panels = newExpand ? this.expandAll() : this.collapseAll()
      }
    }
  },

  created: function () {
    this.panels = this.expand
      ? this.expandAll()
      : this.initial.length > 0
      ? [...this.initial]
      : this.collapseAll()
  },

  methods: {
    expandAll() {
      return [...Array(this.depth).keys()].map((k, i) => i)
    },

    collapseAll() {
      return []
    }
  }
}
</script>

<style lang="css" scoped>
</style>
