<template>
  <v-card-actions class="justify-space-between">
    <!-- view -->
    <v-btn
      v-tooltip="$t('tooltip.cardAction.view')"
      icon
      large
      @click="$emit('view:item')"
    >
      <v-icon color="accent" large> mdi-eye </v-icon>
    </v-btn>
    <!-- share -->
    <v-btn
      v-if="$actions.share"
      v-tooltip="$t('tooltip.cardAction.share')"
      color="accent"
      icon
      large
      @click="$emit('share')"
    >
      <v-icon large> mdi-share-variant </v-icon>
    </v-btn>
    <!-- archive -->
    <v-btn
      v-if="$actions.archive"
      v-tooltip="$t('tooltip.cardAction.archive')"
      color="accent"
      icon
      large
      @click="archiveItem(item)"
    >
      <v-icon large> mdi-cloud-download </v-icon>
    </v-btn>
    <!-- info -->
    <v-btn
      v-tooltip="$t('tooltip.cardAction.info')"
      color="accent"
      icon
      large
      @click="$emit('view:info')"
    >
      <v-icon large> mdi-information </v-icon>
    </v-btn>
    <!-- favourite -->
    <v-btn
      v-tooltip="$t('tooltip.cardAction.favourite')"
      color="red"
      icon
      large
      @click="toggleFavourite(item)"
    >
      <v-icon large>
        {{ isFavourite ? 'mdi-heart' : 'mdi-heart-outline' }}
      </v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContentCardActions',

  components: {},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false
    }
  },

  computed: {
    favourite() {
      return this.$store.state.userStore.favourites.indexOf(this.item.id) > -1
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToFavourites', 'removeFromFavourites']),

    /*
     * manage favourites
     */
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToUserFavourites() : this.removeFromUserFavourites()
    },

    addToUserFavourites() {
      this.addToFavourites({ id: this.item.id })
      this.$emit('add:favourite')
    },

    removeFromUserFavourites() {
      this.removeFromFavourites({ id: this.item.id })
      this.$emit('remove:favourite')
    },

    /*
     * manage storage
     */
    archiveItem() {}
  }
}
</script>

<style lang="css" scoped></style>
