import { channelService } from '@/services/channelService'

export default {
  /* common actions */

  restoreState({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */

  updateActiveChannel({ commit }, { channel }) {
    commit('setActiveChannel', channel)
  },

  async resyncChannels({ commit, getters }) {
    const filter = getters.activePortal?.channelFilter
    const promise = channelService.fetchChannels({ filter })
    commit('setLoading', { loading: true, promise })
    const channels = await promise
    commit('setChannels', channels)
    commit('setLoading', { loading: false })
    return channels
  },

  async fetchChannels({ state, commit, getters }) {
    // fetch channels
    if (state.fetched) {
      // already fetched - use cached values
      const count = state.channels.length
      console.debug(`[channelStore]: Fetching channels from cache (${count}).`)
      return state.channels
    } else {
      // fetch required
      if (state.loading) {
        // previous fetch in progress
        console.debug('[channelStore]: Fetching channels via pre-existing promise.')
        // skip waiting because there's cached content (where previous fetch proceeds)
        if (state.channels.length > 0) {
          return state.channels
        }
        // wait because there's no cached content
        try {
          const channels = await state.promise
          return channels
        } catch (error) {
          console.warn('[channelStore]: ...Pre-existing fetch failed.', error)
          return state.channels || []
        }
      } else {
        // needs a refresh from the CMS
        try {
          console.info(`[channelStore]: Fetching channels from CMS.`)
          const filter = getters.activePortal?.postFilter
          const promise = channelService.fetchChannels({ filter })
          commit('setLoading', { loading: true, promise })
          // use cached channels (but update state)
          if (state.channels.length > 0) {
            promise.then((channels) => {
              commit('setChannels', channels)
              commit('setFetched', true)
              commit('setLoading', { loading: false })
            })
            // return the current cache value
            return state.channels
          } else {
            const channels = await promise
            commit('setChannels', channels)
            commit('setFetched', true)
            commit('setLoading', { loading: false })
            // return the fetched value
            return channels
          }
        } catch (error) {
          console.warn(`[channelStore]: ...Fetch failed. Using cache.`, error)
          return state.channels || []
        }
      }
    }
  },

  async fetchChannel({ commit, getters }, { id = null, slug = null }) {
    const channel = getters.getChannel({ id, slug })

    if (channel) {
      return channel
    } else {
      try {
        const fetchedChannel = await channelService.fetchChannel({ id, slug })
        commit('setChannel', fetchedChannel)
        return fetchedChannel
      } catch (error) {
        console.error('[channelStore]:', error)
        throw error
      }
    }
  }
}
