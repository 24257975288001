var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    {
      ref: "panel",
      staticClass: "c-section-panel",
      on: {
        change: function ($event) {
          return _vm.$emit("change")
        },
      },
    },
    [
      _c("v-expansion-panel-header", {
        ref: "panelHeader",
        staticClass: "c-section-panel-header text-h6",
        attrs: { color: "secondary" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    { staticClass: "c-header" },
                    [
                      _vm._t(
                        "header",
                        function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.title) +
                                " " +
                                _vm._s(_vm.loading ? "" : `(${_vm.count})`) +
                                " "
                            ),
                          ]
                        },
                        null,
                        props
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("v-expansion-panel-content", {
        ref: "panelContent",
        staticClass: "c-section-panel-content",
        attrs: { color: "sheet" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    { staticClass: "c-toolbar" },
                    [_vm._t("toolbar")],
                    2
                  ),
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.loading,
                      color: "red",
                      height: 8,
                      indeterminate: "",
                      striped: "",
                    },
                  }),
                  !_vm.loading && _vm.count === 0
                    ? _c(
                        "div",
                        { staticClass: "c-text" },
                        [
                          _vm._t("empty", function () {
                            return [
                              _vm._v(" " + _vm._s(_vm.$t("ui.empty")) + " "),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._t("default", null, null, props),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }