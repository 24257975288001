<template>
    <v-snackbar
      v-model="snackbar"
      color="error"
      text
      :timeout="timeout"
      top
      @input="emit('close', $event)"
    >
      {{ message }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'ErrorToast',

  components: {},

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    message: {
      type: String,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      timeout: 5000, // 5 seconds
      snackbar: this.show
    }
  }
}
</script>

<style lang="css" scoped>
</style>
