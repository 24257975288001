var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "toolbar", staticClass: "c-toolbar d-flex flex-wrap" },
    [
      _vm._l(_vm.enabledMenuButtons, function (button, index) {
        return _c("RichTextMenuButton", {
          key: button.name === "divider" ? index : button.name,
          attrs: {
            icon: button.icon,
            name: button.name,
            active: button.isActive(),
            disabled: button.isDisabled(),
          },
          on: {
            click: function ($event) {
              return button.action()
            },
          },
        })
      }),
      !_vm.showExtendedMenu
        ? _c("v-spacer")
        : _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
      _c("RichTextMenuButton", {
        key: _vm.resizeButton.name,
        attrs: {
          icon: _vm.resizeButton.icon,
          name: _vm.resizeButton.name,
          active: _vm.resizeButton.isActive(),
          disabled: _vm.resizeButton.isDisabled(),
        },
        on: {
          click: function ($event) {
            return _vm.resizeButton.action()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }