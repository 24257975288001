import Vue from 'vue'
import Vuex from 'vuex'

import defaultOrg from '@/config/defaultOrg.json'

Vue.use(Vuex)

const initialState = {
  appDescription: {},
  assetRoot: defaultOrg.assetRoot,
  gtmId: '',
  orgLink: defaultOrg.orgInfo.orgLink,
  orgLogo: defaultOrg.orgInfo.orgLogo,
  orgName: defaultOrg.orgInfo.orgName,
  about: null,
  disclaimer: null,
  privacy: null,
  tou: null
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */
    setAppConfig(state, { appDescription, assetRoot, gtmId }) {
      state.appDescription = appDescription
      state.assetRoot = assetRoot
      state.gtmId = gtmId
    },

    setOrgInfo(state, { orgLink, orgLogo, orgName, about, contact, disclaimer, privacy, tou }) {
      state.orgLink = orgLink
      state.orgLogo = orgLogo
      state.orgName = orgName
      state.about = about
      state.contact = contact
      state.disclaimer = disclaimer
      state.privacy = privacy
      state.tou = tou
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    updateOrg({ commit }, { appDescription, assetRoot, gtmId, orgInfo }) {
      commit('setAppConfig', { appDescription, gtmId, assetRoot })
      commit('setOrgInfo', orgInfo)
    }
  }
}
