var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CardPanel", {
    attrs: {
      items: _vm.loading ? _vm.skeletonChannels : _vm.channels,
      dense: _vm.dense,
      draggable: _vm.draggable,
      "drag-type": "channel",
      loading: _vm.loading,
      filters: _vm.channelFilters,
      layouts: _vm.layouts,
      preference: _vm.preference,
      sorts: _vm.channelSortOptions,
      title: _vm.title,
      "tree-attribute": _vm.treeAttribute,
      width: _vm.width,
    },
    scopedSlots: _vm._u([
      {
        key: "card",
        fn: function (props) {
          return [
            _vm.loading
              ? _c("ChannelCardSkeleton")
              : _c("ChannelCard", {
                  attrs: { channel: props.item },
                  on: {
                    "view:channel": function ($event) {
                      return _vm.$emit("view:channel", props.item)
                    },
                    share: function ($event) {
                      return _vm.$emit("share", props.item)
                    },
                  },
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }