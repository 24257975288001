var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasItems
    ? _c(
        "v-list",
        { attrs: { dark: _vm.isDark, dense: "", nav: "", rounded: "" } },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              attrs: {
                "active-class": "c-active",
                "input-value": index === _vm.selectedIndex,
              },
              on: {
                click: function ($event) {
                  return _vm.selectItem(index)
                },
              },
            },
            [
              item.avatar
                ? _c(
                    "v-list-item-avatar",
                    [_c("v-img", { attrs: { src: item.avatar } })],
                    1
                  )
                : _vm._e(),
              _c("v-list-item-title", [_vm._v(_vm._s(_vm.prefix + item.name))]),
            ],
            1
          )
        }),
        1
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }