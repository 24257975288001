var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "c-menu",
      attrs: {
        app: "",
        clipped: _vm.clipped,
        "expand-on-hover": _vm.expandOnHover,
        "mini-variant": _vm.isMini,
        "mini-variant-width": _vm.miniWidth,
        "mobile-breakpoint": 0,
        value: _vm.show,
        width: _vm.fullWidth,
      },
      scopedSlots: _vm._u([
        {
          key: "img",
          fn: function () {
            return [
              _c("v-img", {
                attrs: {
                  src: _vm.backgroundImage,
                  gradient: _vm.backgroundGradient,
                  height: "100%",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "prepend",
          fn: function () {
            return [
              _c(
                "v-list",
                { staticClass: "pb-0", attrs: { nav: "", subheader: "" } },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "c-list-item c-list-item--prepend py-1" },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "c-logo c-list-item-icon py-0 my-0" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.orgLogo,
                              contain: "",
                              "max-height": "40",
                              "max-width": "40",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "c-list-item-content" },
                        [
                          _c("v-list-item-title", { staticClass: "c-name" }, [
                            _vm._v(" " + _vm._s(_vm.appName) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        { staticClass: "ml-3 my-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.$t(
                                    "tooltip.menuBar.menuStyleButton"
                                  ),
                                  expression:
                                    "$t('tooltip.menuBar.menuStyleButton')",
                                },
                              ],
                              attrs: {
                                "data-title": _vm.$t(
                                  "tour.appBar.menuStyleButton.title"
                                ),
                                "data-intro": _vm.$t(
                                  "tour.appBar.menuStyleButton.intro"
                                ),
                                "data-toggle": _vm.isMini ? "open" : "nop",
                                fab: "",
                                small: "",
                                "input-value": "true",
                              },
                              on: { click: _vm.toggleMiniVariant },
                            },
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isMini
                                        ? "mdi-format-list-bulleted-square"
                                        : "mdi-dots-vertical"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-1" }),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "v-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMini,
                      expression: "isMini",
                    },
                  ],
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "c-list-item" },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.$t(
                                    "tooltip.menuBar.menuLockButton"
                                  ),
                                  expression:
                                    "$t('tooltip.menuBar.menuLockButton')",
                                },
                              ],
                              attrs: {
                                "data-title": _vm.$t(
                                  "tour.appBar.menuLockButton.title"
                                ),
                                "data-intro": _vm.$t(
                                  "tour.appBar.menuLockButton.intro"
                                ),
                                "data-toggle": !_vm.isMini ? "close" : "nop",
                                icon: "",
                              },
                              on: { click: _vm.toggleHover },
                            },
                            [
                              _c("v-icon", { attrs: { "x-large": "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.expandOnHover
                                        ? "mdi-chevron-triple-left"
                                        : "mdi-chevron-triple-right"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-list-item-content"),
                    ],
                    1
                  ),
                  _c("v-list"),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", rounded: "" } },
        _vm._l(_vm.menuItems, function (menuItem) {
          return _c(
            "v-list-item",
            {
              key: menuItem.key,
              staticClass: "c-list-item",
              attrs: {
                "data-title": _vm.menuTitles[menuItem.key],
                "data-intro": _vm.menuIntros[menuItem.key],
                "data-click": "",
                "active-class": "c-list-item--active",
                exact: "",
                fixed: "",
                to: _vm.to(menuItem),
                router: "",
              },
            },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "c-list-item-icon" },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(" " + _vm._s(menuItem.icon) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "c-list-item-content" },
                [
                  _c("v-list-item-title", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.menuTitles[menuItem.key]) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }