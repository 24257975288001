var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-app-install" },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInstallButton,
              expression: "showInstallButton",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.appBar.install"),
              expression: "$t('tooltip.appBar.install')",
            },
          ],
          staticClass: "ml-2",
          attrs: {
            "data-title": _vm.$t("tour.appBar.installButton.title"),
            "data-intro": _vm.$t("tour.appBar.installButton.intro"),
            fab: "",
            small: "",
          },
          on: { click: _vm.onInstall },
        },
        [
          _c("v-icon", { attrs: { color: "accent" } }, [
            _vm._v(" mdi-tray-arrow-down "),
          ]),
        ],
        1
      ),
      _vm.showInstallPopup
        ? _c("AppInstallPopup", {
            attrs: { device: _vm.iosDeviceType },
            on: {
              cancel: function ($event) {
                _vm.showInstallPopup = $event
              },
            },
            model: {
              value: _vm.showInstallPopup,
              callback: function ($$v) {
                _vm.showInstallPopup = $$v
              },
              expression: "showInstallPopup",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }