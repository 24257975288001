// ¯\_(ツ)_/¯

import Vue from 'vue'
import App from '@/App'

// import core css assets
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.min.css' // https://materialdesignicons.com
import '@fortawesome/fontawesome-free/css/fontawesome.min.css' // https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free

// import filters
import registerFilters from '@/filters'

// import plugins
import registerPlugins from '@/plugins/registerPlugins.js'

// import Vue platform extensions
import router from '@/router' // returns new VueRouter
import store from '@/store' // returns new Vuex
import { i18n } from '@/plugins/localePlugin.js'
// import vuetify from '@/plugins/vuetifyPlugin.js'

// import config
import { getFeature } from '@/config/featureConfig.js'
import * as pwa from '@/pwa.js'

// startup message
console.info(`Lumenii is starting...`)

// register Vue plugins and filters
registerPlugins({ router, store })
registerFilters()

// register PWA
if (getFeature('pwaInstall')) {
  window.addEventListener('DOMContentLoaded', () => {
    pwa.addSplashScreens()
  })
  pwa.addInstallListeners()
}

if (getFeature('pwaDeregister')) {
  pwa.deregisterAll()
}

if (getFeature('pwaRegister')) {
  // load service worker after app delivers content to user
  window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
      pwa.register()
    }
  })
}

// setup Vue configuration
Vue.config.productionTip = false
Vue.config.ignoredElements = ['model-viewer']

// create the Vue instance
const app = new Vue({
  i18n,
  store,
  router,
  vuetify: Vue.prototype.$vuetify,
  render: (h) => h(App),
  renderError: (h, err) => h('pre', { style: { color: 'red' } }, err.stack)
})

store.$app = app

app.$mount('#app')
