var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "WidgetWrapper",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.adCount > 0,
          expression: "adCount > 0",
        },
      ],
      staticClass: "c-ad-widget",
      attrs: { title: _vm.$t("ad.advertisements") },
      on: {
        close: function ($event) {
          return _vm.onClose()
        },
      },
    },
    [
      _c("AdSpot", {
        attrs: {
          closeable: false,
          max: 3,
          reverse: _vm.reverse,
          stack: "",
          zone: _vm.zone,
        },
        on: {
          count: function ($event) {
            return _vm.onCount($event)
          },
        },
        model: {
          value: _vm.showAds,
          callback: function ($$v) {
            _vm.showAds = $$v
          },
          expression: "showAds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }