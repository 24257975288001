import Vue from 'vue'

import { AlertPlugin } from '@/plugins/alertPlugin'
import { AnalyticsPlugin } from '@/plugins/analyticsPlugin'
import { AppConfigPlugin } from '@/plugins/appConfigPlugin'
import { AuthPlugin } from '@/plugins/authPlugin'
import { BusPlugin } from '@/plugins/busPlugin'
import { ComponentsPlugin } from '@/plugins/componentsPlugin'
import { ErrorHandlerPlugin } from '@/plugins/errorHandlerPlugin'
import { FeatureTogglePlugin } from '@/plugins/featureTogglePlugin'
import { LayoutPlugin } from '@/plugins/layoutPlugin'
import { LocalePlugin, i18n } from '@/plugins/localePlugin'
import { MiscPlugin } from '@/plugins/miscPlugin'
import { NotificationPlugin } from '@/plugins/notificationPlugin'
import { PlayerPlugin } from '@/plugins/playerPlugin'
import { PortableTextPlugin } from '@/plugins/portableTextPlugin'
import { TooltipPlugin } from '@/plugins/tooltipPlugin'
import { TracePlugin } from '@/plugins/tracePlugin'
import { VuetifyPlugin } from '@/plugins/vuetifyPlugin'

// initialize plugins
export default function ({ router, store }) {
  // initialize the application configuration
  Vue.use(AppConfigPlugin) // this.$appConfig
  // initialize i18n first (so it can be used in other plugins)
  Vue.use(LocalePlugin, { store }) // this.$t, export const i18n
  // initialize error handling next (so it can be used in other plugins)
  Vue.use(ErrorHandlerPlugin) // this.$logger and this.$alert / this.$swal

  // initialize all ther plugins
  Vue.use(AlertPlugin) // this.$alert, this.$toast
  Vue.use(AnalyticsPlugin) // this.$postHog
  Vue.use(AuthPlugin, { store, router }) // this.$auth
  Vue.use(BusPlugin) // this.$bus, Vue.prototype.$bus
  Vue.use(ComponentsPlugin) // registers global components
  Vue.use(FeatureTogglePlugin) // this.$features
  Vue.use(LayoutPlugin) // registers layout components
  Vue.use(MiscPlugin) // registers miscellaneous components
  Vue.use(NotificationPlugin) // this.$notify, Vue.notify
  Vue.use(PlayerPlugin) // registers the various player components
  Vue.use(PortableTextPlugin) // registers various portable text rendering components
  Vue.use(TracePlugin) // adds a mixin for each lifecycle hook
  Vue.use(TooltipPlugin) // registers tooltip components and directives
  Vue.use(VuetifyPlugin, { store, i18n }) // this.$vuetify
}
