<template>
  <ResizableDrawer
    v-model="showComments"
    color="background"
    :data-title="$t('tour.comments.drawer.title')"
    :data-intro="$t('tour.comments.drawer.intro')"
    hide-overlay
    right
    :initial-width="350"
  >
    <template #header>
      <div ref="drawerSlot" class="c-header d-flex align-center">
        <v-subheader class="c-title px-3">
          {{ $t('comment.header') }}
        </v-subheader>

        <v-spacer />

        <CloseButton
          :size="12"
          top="4px"
          right="8px"
          @close="$emit('update:show', false)"
        />
      </div>
      <v-alert
        v-model="noComments"
        class="mx-2 mt-2 rounded-xl"
        color="primary"
        icon="mdi-information"
      >
        {{ $t('comment.notices.first') }}
      </v-alert>
    </template>

    <template #default>
      <CommentThread
        class="ma-2"
        :comments="comments"
        rich
        show-form
        :target-id="targetId"
        @count="$emit(count)"
      />
    </template>
  </ResizableDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseButton from '@/components/base/CloseButton'
import CommentThread from '@/components/comments/CommentThread'
import ResizableDrawer from '@/components/base/ResizableDrawer'

export default {
  name: 'CommentDrawer',

  components: {
    CloseButton,
    CommentThread,
    ResizableDrawer
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    comments: {
      type: Array,
      required: true
    },

    targetId: {
      type: String,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      showComments: false,
      showInput: true
    }
  },

  computed: {
    ...mapGetters('commentStore', ['getComments']),

    noComments() {
      return this.comments.length < 1
    }
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.showComments = newShow
      }
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-title {
  font-size: 1rem;
  max-height: 36px;
}
</style>
