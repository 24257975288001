<template>
  <v-dialog
    v-model="showDialog"
    v-bind="$attrs"
    persistent
    max-width="320px"
    v-on="$listeners"
  >
    <v-card
      class="c-dialog-card"
      :class="{ 'c-outlined': outlined }"
    >
      <v-card-title v-if="title" class="px-4">
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text class="pb-4 px-4">
        <slot>
          {{ text }}
        </slot>
      </v-card-text>

      <v-card-actions
        class="c-dialog-actions"
        :class="{ 'c-outlined': outlined, 'justify-center': confirm }"
      >
        <v-btn
          v-if="!confirm"
          class="c-dialog-btn"
          color="primary"
          rounded
          @click="onClose()"
        >
          {{ closeText || $t('dialog.prompt.close') }}
        </v-btn>

        <v-spacer v-if="!confirm" />

        <v-btn
          ref="submitButton"
          class="c-dialog-btn"
          color="primary"
          rounded
          @click="onSubmit()"
        >
          {{ submitText || $t('dialog.prompt.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDialog',

  components: {},

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: true
    },

    confirm: {
      type: Boolean,
      required: false,
      default: false
    },

    closeText: {
      type: String,
      required: false,
      default: ''
    },

    submitText: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    text: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.showDialog = newShow
        if (newShow) {
          // $nextTick doesn't work
          setTimeout(() => {
            this.$refs.submitButton.$el.focus()
          }, 0)
        }
      }
    }
  },

  methods: {
    onClose() {
      this.showDialog = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onSubmit() {
      this.showDialog = false
      this.$emit('update:show', false)
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-dialog-card.c-outlined {
  outline: 1px solid var(--v-text-base);
}
.c-dialog-actions.c-outlined {
  border-top: 1px solid var(--v-text-base);
}
.c-dialog-btn {
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  &:focus {
    border: 1px solid var(--v-text-base);
    opacity: 1;
  }
}
</style>
