var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SectionPanel", {
    ref: "panel",
    attrs: {
      colour: _vm.colour,
      count: _vm.itemCount,
      loading: _vm.loading,
      title: _vm.title,
    },
    scopedSlots: _vm._u(
      [
        {
          key: _vm.toolbarSlotName,
          fn: function () {
            return [
              _c("LayoutToolbar", {
                attrs: {
                  items: _vm.items,
                  filters: _vm.filters,
                  layouts: _vm.layouts,
                  sort: _vm.initialSortOptionName,
                  sorts: _vm.sorts,
                  title: _vm.dense ? _vm.title : "",
                  wide: _vm.dense,
                },
                on: {
                  collapse: function ($event) {
                    _vm.expand = false
                  },
                  expand: function ($event) {
                    _vm.expand = true
                  },
                  results: function ($event) {
                    _vm.resultSet = $event
                  },
                  "update:layout": function ($event) {
                    return _vm.updateLayoutPreference($event)
                  },
                  "update:sort": function ($event) {
                    return _vm.updateSortPreference($event)
                  },
                },
                model: {
                  value: _vm.layout,
                  callback: function ($$v) {
                    _vm.layout = $$v
                  },
                  expression: "layout",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "default",
          fn: function ({ open }) {
            return [
              _c("CardView", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: open,
                    expression: "open",
                  },
                ],
                attrs: {
                  items: _vm.resultSet,
                  loading: _vm.loading,
                  layout: _vm.layout,
                  draggable: _vm.isDraggable,
                  "drag-type": _vm.dragType,
                  "tree-attribute": _vm.treeAttribute,
                  "tree-expand": _vm.expand,
                  width: _vm.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "card",
                      fn: function (props) {
                        return [_vm._t("card", null, { item: props.item })]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }