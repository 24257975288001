var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "c-form-fields py-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _vm.isAnonymous
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        ref: "name",
                        attrs: {
                          autocomplete: "name",
                          clearable: "",
                          disabled: _vm.alert,
                          filled: "",
                          "prepend-icon": "mdi-account",
                          rules: _vm.nameRules,
                          counter: 25,
                          label: _vm.$t("comment.details.name"),
                          required: "",
                        },
                        model: {
                          value: _vm.commentName,
                          callback: function ($$v) {
                            _vm.commentName = $$v
                          },
                          expression: "commentName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAnonymous
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        ref: "email",
                        attrs: {
                          clearable: "",
                          disabled: _vm.alert,
                          filled: "",
                          "prepend-icon": "mdi-at",
                          rules: _vm.emailRules,
                          label: _vm.$t("comment.details.email"),
                          required: "",
                        },
                        model: {
                          value: _vm.commentEmail,
                          callback: function ($$v) {
                            _vm.commentEmail = $$v
                          },
                          expression: "commentEmail",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "d-flex flex-wrap px-0", attrs: { cols: "12" } },
                [
                  _vm.rich
                    ? _c("RichTextEditor", {
                        attrs: { counter: _vm.maxLength },
                        model: {
                          value: _vm.commentBody,
                          callback: function ($$v) {
                            _vm.commentBody = $$v
                          },
                          expression: "commentBody",
                        },
                      })
                    : _c("v-textarea", {
                        staticClass: "c-plain-editor",
                        attrs: {
                          autocomplete: "",
                          autofocus: "",
                          "auto-grow": "",
                          clearable: "",
                          disabled: _vm.alert,
                          filled: "",
                          placeholder: _vm.$t("comment.placeholder"),
                          rules: _vm.activeRules,
                          label: _vm.$t("comment.details.body"),
                          counter: _vm.maxLength,
                          required: "",
                          rows: "3",
                          outlined: "",
                        },
                        on: { "click:clear": _vm.resetForm },
                        model: {
                          value: _vm.commentBody,
                          callback: function ($$v) {
                            _vm.commentBody = $$v
                          },
                          expression: "commentBody",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mt-4",
          attrs: { dense: "", dismissible: "", type: "error" },
          model: {
            value: _vm.alert,
            callback: function ($$v) {
              _vm.alert = $$v
            },
            expression: "alert",
          },
        },
        [_vm._v(" " + _vm._s(_vm.error) + " ")]
      ),
      _c(
        "div",
        { staticClass: "c-form-actions d-flex mt-2" },
        [
          _c("v-spacer"),
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.valid,
                      expression: "!valid",
                    },
                  ],
                  staticClass: "my-0",
                  attrs: { icon: "" },
                  on: { click: _vm.resetForm },
                },
                [_c("v-icon", [_vm._v("mdi-refresh")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { rounded: "", small: "", text: "" },
              on: {
                click: function ($event) {
                  return _vm.onCancel()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("ui.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text ml-2",
              attrs: {
                color: "green lighten-1",
                disabled: !_vm.valid || !_vm.isDirty,
                rounded: "",
                small: "",
                type: "submit",
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit($event)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.updateMode
                      ? _vm.$t("comment.action.update")
                      : _vm.$t("comment.action.submit")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }