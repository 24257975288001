import Vue from 'vue'
import Vuex from 'vuex'
import { userService } from '@/services/userService'

Vue.use(Vuex)

const initialState = {
  userId: '',
  favourites: [],
  channels: [],
  contacts: [],
  companies: [],
  posts: [],
  locale: 'en',
  // preferences follow the user across devices
  // (hence, persisted in a database)
  preferences: {
    // favourites
    favouritesLayout: 'grid',
    favouritesSort: 'sortByAsIs',
    favouritePostsLayout: 'grid',
    favouritePostsSort: 'sortByAsIs',
    favouriteChannelsLayout: 'grid',
    favouriteChannelsSort: 'sortByAsIs',
    // views
    contentLayout: 'grid',
    contentSort: 'sortByPublishDateDesc',
    postsLayout: 'grid',
    postsSort: 'sortByPublishDateDesc',
    channelsLayout: 'grid',
    channelsSort: 'sortByPublishDateDesc',
    contactsLayout: 'grid',
    companiesLayout: 'grid'
  },
  // settings are different per device
  // (hence, only persisted in localStorage)
  settings: {
    // autoMode: false,
    // homeMenuItem: 'home',
    // isDark: true,
    showMenu: true, // show side / bottom menu
    sidebarMini: true, // show just icons (sidebar only)
    sidebarHover: true // expand on hover (sidebar only)
  }
}

const moveElement = function (immutableArray, from, after) {
  const arr = [...immutableArray]
  const fromIndex = arr.indexOf(from)
  const afterIndex = arr.indexOf(after)

  // if both "from" and "after" are found, do the swap
  if (fromIndex >= 0 && afterIndex >= 0) {
    const element = arr[fromIndex] // get a copy of the element being moved

    arr.splice(fromIndex, 1) // remove the single element from the array
    const insertionIndex = fromIndex < afterIndex ? afterIndex : afterIndex + 1
    arr.splice(insertionIndex, 0, element) // insert the element back into the array (to the right)
  }
  return arr
}

const persistUser = async function (tenantKey, user) {
  try {
    if (tenantKey && user.userId) {
      await userService.updateUser(user)
    } else {
      throw new Error('No userId specified!')
    }
  } catch (error) {
    console.error(`[userStore]: Error updating user. ${error}`)
    // FIXME: add an alert box here?
  }
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    getPreference(state) {
      return (preference) => state.preferences[preference]
    },

    getSetting(state) {
      return (setting) => state.settings[setting]
    },

    getSettings(state) {
      return () => state.settings
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // special merge for preferences & settings
      const special = ['preferences', 'settings']

      // reassigning state breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        if (key in state && !special.includes(key)) {
          state[key] = newState[key]
        }
      })

      // special merge required since state may have:
      //   1. incomplete/outdated list of properties and/or
      //   2. boolean values
      special.forEach((s) => {
        for (const key in state[s]) {
          // only process newState values that exist
          if (newState[s]?.[key] != null) {
            // if (isBoolean) state[s][key] = newState[s][key] === 'true'
            state[s][key] = newState[s][key]
          }
          // console.warn(`state.${s}.${key}=${state[s][key]}`)
        }
      })
    },

    /* specific mutations */

    setUser(state, user) {
      state.userId = user.userId
      state.channels = user.channels || []
      state.contacts = user.contacts || []
      state.companies = user.companies || []
      state.favourites = user.favourites || []
      state.posts = user.posts || []
      state.locale = user.locale || state.locale
      state.preferences = user.preferences || state.preferences
    },

    setUserId(state, userId) {
      state.userId = userId
    },

    setPosts(state, posts) {
      state.posts = posts || []
    },

    setChannels(state, channels) {
      state.channels = channels || []
    },

    setContacts(state, contacts) {
      state.contacts = contacts || []
    },

    setCompanies(state, companies) {
      state.companies = companies || []
    },

    setFavourites(state, favourites) {
      state.favourites = favourites || []
    },

    setLocale(state, locale) {
      state.locale = locale || 'en'
    },

    setPreferences(state, preferences) {
      for (const key in preferences) {
        if (key in state.preferences) {
          state.preferences[key] = preferences[key]
        } else {
          state.preferences = {
            ...state.preferences,
            [key]: preferences[key]
          }
        }
      }
    },

    setSettings(state, settings) {
      for (const key in settings) {
        if (key in state.settings) {
          state.settings[key] = settings[key]
        } else {
          state.settings = {
            ...state.settings,
            [key]: settings[key]
          }
        }
      }
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /*
     * manage user state
     */
    async updateUser({ commit }, { user }) {
      console.debug('[userStore]: User=', user)
      commit('setUser', user)
    },

    /*
     * manage favourite posts
     */

    addToPosts({ state, dispatch }, { id }) {
      const posts = state.posts
      if (posts.indexOf(id) === -1) {
        posts.splice(0, 0, id)
        dispatch('updatePosts', { posts })
      }
    },

    removeFromPosts({ state, dispatch }, { id }) {
      const posts = state.posts.filter((favouriteId) => favouriteId !== id)
      if (posts.length !== state.posts.length) {
        dispatch('updatePosts', { posts })
      }
    },

    clearPosts({ dispatch }) {
      dispatch('updatePosts', { posts: [] })
    },

    movePost({ state, dispatch }, { from, after }) {
      const posts = moveElement(state.posts, from, after)
      dispatch('updatePosts', { posts })
    },

    async updatePosts({ state, commit, rootState }, { posts }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        posts
      }
      await persistUser(tenantKey, user)
      commit('setPosts', posts)
    },

    /*
     * manage favourite channels
     */

    addToChannels({ state, dispatch }, { id }) {
      const channels = state.channels
      if (channels.indexOf(id) === -1) {
        channels.splice(0, 0, id)
        dispatch('updateChannels', { channels })
      }
    },

    removeFromChannels({ state, dispatch }, { id }) {
      const channels = state.channels.filter((favouriteId) => favouriteId !== id)
      if (channels.length !== state.channels.length) {
        dispatch('updateChannels', { channels })
      }
    },

    clearChannels({ dispatch }) {
      dispatch('updateChannels', { channels: [] })
    },

    async updateChannels({ state, commit, rootState }, { channels }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        channels
      }
      await persistUser(tenantKey, user)
      commit('setChannels', channels)
    },

    /*
     * manage favourite content
     */

    addToFavourites({ state, dispatch }, { id }) {
      const favourites = state.favourites
      if (favourites.indexOf(id) === -1) {
        favourites.splice(0, 0, id)
        dispatch('updateFavourites', { favourites })
      }
    },

    removeFromFavourites({ state, dispatch }, { id }) {
      const favourites = state.favourites.filter((favouriteId) => favouriteId !== id)
      if (favourites.length !== state.favourites.length) {
        dispatch('updateFavourites', { favourites })
      }
    },

    clearFavourites({ dispatch }) {
      dispatch('updateFavourites', { favourites: [] })
    },

    async updateFavourites({ state, commit, rootState }, { favourites }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        favourites
      }
      await persistUser(tenantKey, user)
      commit('setFavourites', favourites)
    },

    moveFavourite({ state, dispatch }, { from, after }) {
      const favourites = moveElement(state.favourites, from, after)
      dispatch('updateFavourites', { favourites })
    },

    /*
     * manage favourite contacts
     */

    addToContacts({ state, dispatch }, { id }) {
      const contacts = state.contacts
      if (contacts.indexOf(id) === -1) {
        contacts.splice(0, 0, id)
        dispatch('updateContacts', { contacts })
      }
    },

    removeFromContacts({ state, dispatch }, { id }) {
      const contacts = state.contacts.filter((companyId) => companyId !== id)
      if (contacts.length !== state.contacts.length) {
        dispatch('updateContacts', { contacts })
      }
    },

    clearContacts({ dispatch }) {
      dispatch('updateContacts', { contacts: [] })
    },

    async updateContacts({ state, commit, rootState }, { contacts }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        contacts
      }
      await persistUser(tenantKey, user)
      commit('setContacts', contacts)
    },

    /*
     * manage favourite companies
     */

    addToCompanies({ state, dispatch }, { id }) {
      const companies = state.companies
      if (companies.indexOf(id) === -1) {
        companies.splice(0, 0, id)
        dispatch('updateCompanies', { companies })
      }
    },

    removeFromCompanies({ state, dispatch }, { id }) {
      const companies = state.companies.filter((companyId) => companyId !== id)
      if (companies.length !== state.companies.length) {
        dispatch('updateCompanies', { companies })
      }
    },

    clearCompanies({ dispatch }) {
      dispatch('updateCompanies', { companies: [] })
    },

    async updateCompanies({ state, commit, rootState }, { companies }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        companies
      }
      await persistUser(tenantKey, user)
      commit('setCompanies', companies)
    },

    /*
     * manage preferences & settings
     */

    async updateLocale({ state, dispatch, commit, rootState }, { locale, vm }) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        locale
      }
      await persistUser(tenantKey, user)
      commit('setLocale', locale)
      dispatch('i18nStore/updateLocale', { locale, vm })
    },

    async updatePreferences({ state, commit, rootState }, preferences) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        preferences: {
          ...state.preferences,
          ...preferences
        },
        settings: state.settings
      }
      await persistUser(tenantKey, user)
      commit('setPreferences', preferences)
    },

    async updateSettings({ state, commit, rootState }, settings) {
      const tenantKey = rootState.tenantStore.tenantKey
      const user = {
        userId: state.userId,
        preferences: state.preferences,
        settings: {
          ...state.settings,
          ...settings
        }
      }
      await persistUser(tenantKey, user)
      commit('setSettings', settings)
    }
  }
}
