var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "div",
        { ref: "editor", staticClass: "c-editor" },
        [
          _vm.editable
            ? _c("RichTextToolbar", {
                staticClass: "mb-2",
                attrs: { editor: _vm.editor },
                on: {
                  expand: function ($event) {
                    return _vm.onResizeEditor($event)
                  },
                },
              })
            : _vm._e(),
          _c("editor-content", {
            staticClass: "c-editor__content",
            attrs: { editor: _vm.editor },
          }),
          _vm.maxLength && _vm.editable
            ? _c(
                "div",
                {
                  staticClass: "c-count mt-2 mr-2",
                  class: {
                    "c-max":
                      _vm.editor.storage.characterCount.characters() ===
                      _vm.maxLength,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.editor.storage.characterCount.characters()) +
                      " / " +
                      _vm._s(_vm.maxLength) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }