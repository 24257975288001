var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SectionPanels",
    {
      staticClass: "g-hide-scrollbar c-section-panel mb-0",
      attrs: { depth: _vm.tree.length, expand: _vm.expand },
    },
    _vm._l(_vm.tree, function (node, i) {
      return _c(
        "SectionPanel",
        {
          key: i,
          attrs: {
            title: node.branchName,
            count: node.leafNodes.length,
            loading: _vm.loading,
          },
        },
        [
          _c("CardCarousel", {
            attrs: {
              title: node.branchName,
              items: node.leafNodes,
              width: _vm.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [_vm._t("card", null, { item: props.item })]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }