var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "layout",
      staticClass: "c-layout-container c-column",
      class: {
        "c-fullheight g-skinny-scrollbars": !_vm.scroll && !_vm.fullscreen,
        "c-fullscreen": _vm.fullscreen,
      },
    },
    [
      _vm.showHeader
        ? _c(
            "header",
            {
              ref: "header",
              staticClass: "c-layout-header c-column",
              class: { "c-fullscreen": _vm.fullscreen },
            },
            [
              _vm._t("header", null, {
                on: {
                  close: _vm.onCloseHeader,
                  height: _vm.setHeaderHeight,
                },
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "main",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll.self",
              value: _vm.onScroll,
              expression: "onScroll",
              modifiers: { self: true },
            },
          ],
          ref: "main",
          staticClass: "c-layout-main d-flex justify-center align-start",
        },
        [
          _vm.showLeftPanel
            ? _c(
                "div",
                {
                  staticClass: "c-left-side c-layout-side c-column pa-2",
                  class: {
                    "c-scrollable g-skinny-scrollbars": _vm.isScrollable,
                    "c-fullscreen": _vm.fullscreen,
                  },
                },
                [_vm._t("left")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "c-layout-middle c-column",
              class: {
                "c-solo": !_vm.showLeftPanel && !_vm.showRightPanel,
                "c-scrollable g-skinny-scrollbars": _vm.isScrollable,
                "c-fullscreen": _vm.fullscreen,
              },
            },
            [_vm._t("middle")],
            2
          ),
          _vm.showRightPanel
            ? _c(
                "div",
                {
                  staticClass: "c-right-side c-layout-side c-column pa-2",
                  class: {
                    "c-scrollable g-skinny-scrollbars": _vm.isScrollable,
                    "c-fullscreen": _vm.fullscreen,
                  },
                },
                [_vm._t("right")],
                2
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }