var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "c-close",
      style: {
        position: _vm.absolute ? "absolute" : "relative",
        top: _vm.top,
        right: _vm.right,
      },
      attrs: { color: "rgba(0, 0, 0, 0.25)", "x-small": "", fab: "" },
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-close ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }