import Vue from 'vue'
import Vuex from 'vuex'
import { APP_VERSION } from '@/config/appConfig.js'
// import client from '@client'
import client from '@/services/mock'

Vue.use(Vuex)

const notificationPermission = 'Notification' in window ? Notification.permission : 'denied'

const initialState = {
  version: APP_VERSION,
  online: window.navigator.onLine,
  notices: [],
  notification: {
    permission: notificationPermission,
    requestDate: 0
  },
  tooltips: true,
  tours: true
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.notification = {
        permission: 'Notification' in window ? Notification.permission : 'denied',
        requestDate: newState.notification?.requestDate || 0
      }
      // handle case where saved state doesn't have these settings (i.e. undefined)
      state.tooltips = newState.tooltips === false ? false : true
      state.tours = newState.tours === false ? false : true
    },

    /* specific mutations */

    setOnlineStatus(state, online) {
      state.online = online
    },

    setNotices(state, notices) {
      state.notices = notices
    },

    setNotificationPermission(state, { permission, requestDate }) {
      state.notification.permission = permission
      state.notification.requestDate = requestDate
    },

    setTooltips(state, isActive) {
      state.tooltips = isActive
    },

    setTours(state, isActive) {
      state.tours = isActive
    },

    setVersion(state, version) {
      state.version = version
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    activateTooltips({ commit }) {
      commit('setTooltips', true)
    },

    deactivateTooltips({ commit }) {
      commit('setTooltips', false)
    },

    activateTours({ commit }) {
      commit('setTours', true)
    },

    deactivateTours({ commit }) {
      commit('setTours', false)
    },

    fetchNotices({ commit }) {
      return client.fetchNotices().then((notices) => {
        commit('setNotices', notices)
        return notices
      })
    },

    reportState({ state }) {
      console.info('[appStore]: Version=', state.version)
      console.debug('[appStore]: Initially ' + (state.online ? 'on' : 'off') + 'line.')
    },

    updateNotices({ commit }, { notices }) {
      commit('setNotices', notices)
    },

    updateNotificationPermission({ commit }, permission) {
      const notification = {
        permission: permission,
        requestDate: Date.now()
      }
      commit('setNotificationPermission', notification)
    },

    updateOnlineStatus({ commit }, onlineStatus) {
      commit('setOnlineStatus', onlineStatus)
    },

    updateVersion({ commit }, version) {
      commit('setVersion', version)
    }
  }
}
