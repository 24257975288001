var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "view" },
    [
      _vm.isCarousel
        ? _c("CardCarousel", {
            attrs: {
              items: _vm.items,
              "card-width": _vm.minCardWidth,
              loading: _vm.loading,
              width: _vm.viewWidth,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [_vm._t("card", null, { item: props.item })]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm.isGrid
        ? _c("CardGrid", {
            attrs: {
              infinite: true,
              items: _vm.items,
              loading: _vm.loading,
              width: _vm.viewWidth,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [
                      _c(
                        "DraggableNode",
                        {
                          attrs: {
                            active: _vm.draggable,
                            "drag-type": _vm.dragType,
                            identifier: props.item.id,
                          },
                          on: {
                            drop: function ($event) {
                              return _vm.$emit("move", $event)
                            },
                          },
                        },
                        [_vm._t("card", null, { item: props.item })],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm.isVirtualGrid
        ? _c("CardVirtualGrid", {
            attrs: {
              items: _vm.items,
              loading: _vm.loading,
              "min-card-height": _vm.minCardHeight,
              width: _vm.viewWidth,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [_vm._t("card", null, { item: props.item })]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm.isTree
        ? _c("CardTree", {
            staticClass: "mb-12",
            attrs: {
              attribute: _vm.treeAttribute,
              expand: _vm.treeExpand,
              items: _vm.items,
              loading: _vm.loading,
              width: _vm.viewWidth,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [_vm._t("card", null, { item: props.item })]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }