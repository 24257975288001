<template>
  <v-btn
    class="c-close"
    :style="{position: absolute ? 'absolute' : 'relative', top: top, right: right}"
    color="rgba(0, 0, 0, 0.25)"
    x-small
    fab
    @click="$emit('close')"
  >
    <v-icon large> mdi-close </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CloseButton',

  components: {},

  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: true
    },

    top: {
      type: String,
      required: false,
      default: '4px'
    },

    right: {
      type: String,
      required: false,
      default: '24px' // actually 8px since scrollbar is 12px wide
    },

    size: {
      type: Number,
      required: false,
      default: 12
    }
  },

  data: function () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.c-close {
  &:hover {
    transform: scale(1.1);
    color: var(--v-primary-base);
  }
}
</style>
