var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "leaderboard", staticClass: "c-leaderboard-ads" },
    [
      _c("AdSpot", {
        staticClass: "c-carousel",
        attrs: { carousel: "", max: 3, optimize: "", zone: "leaderboard" },
        on: {
          "update:show": function ($event) {
            return _vm.$emit("close")
          },
          count: function ($event) {
            return _vm.onCount($event)
          },
        },
        model: {
          value: _vm.showAds,
          callback: function ($$v) {
            _vm.showAds = $$v
          },
          expression: "showAds",
        },
      }),
      _vm.showHero
        ? _c("AdSpot", {
            staticClass: "c-hero",
            attrs: { max: 1, rollup: "", zone: "hero" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }