<template>
  <div class="c-login-page">
    <CircularProgress :message="$t('message.loggingIn')"/>
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import exitMixin from '@/mixins/exitMixin.js'

const convertObjectToQuery = (obj) => {
  return Object.keys(obj) // {a: 1, b: 2}
    .map((key) => `${key}=${obj[key]}`) // ['a=1', 'b=2']
    .join('&') // a=1&b=2
}

const convertQueryToObject = (query) => {
  return query
    ? query // a=1&b=2
        .split('&') // ['a=1', 'b=2']
        .map((param) => param.split('=')) // [[a,1], [b,2]]
        .map((arr) => ({ [arr[0]]: arr[1] })) // [{ a:1 }, { b:2 }]
        .reduce((acc, nvp) => ({ ...acc, ...nvp }), {}) // { a:1, b:2 }
    : {}
}

export default {
  name: 'LoginPage',

  components: {
    CircularProgress
  },

  mixins: [exitMixin],

  props: {
    force: {
      type: Boolean,
      required: false,
      default: false
    },

    redirect: {
      type: String,
      required: false,
      default: '/'
    },

    options: {
      type: String,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      redirectURI: this.redirect
    }
  },

  computed: {
    isPopupLogin() {
      return this.$auth.isPopupLogin()
    },

    isSSO() {
      return this.$auth.isPopupLogin()
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    tenant() {
      return this.$store.state.tenantStore.tenant
    },

    userEmail() {
      return this.$store.userStore.user.userId
    }
  },

  created: async function () {
    // handle redirect login case during create
    if (this.$auth.isRedirectLogin()) {
      // const state = await this.$auth.handleRedirect()
      // this.redirectURI = state?.redirectURI || this.redirectURI
      await this.processAuth()
    }
  },

  mounted: async function () {
    // handle popup login case after mounting
    if (this.$auth.isPopupLogin() || this.$auth.isSSOLogin()) {
      await this.processAuth()
    }
  },

  methods: {
    parseRedirectURI(uri) {
      if (!uri) return {}

      // split the uri into its core elements
      const uriDecoded = decodeURIComponent(uri)
      const uriParts = uriDecoded.split('?')
      const uriPath = uriParts[0]
      const uriQuery = uriParts[1]
      const uriQueryObject = uriQuery ? convertQueryToObject(uriQuery) : {}

      // extract login specific options from the uri
      if ('options' in uriQueryObject) {
        // convert from "relaxed" JSON to strict JSON
        const optionsJSON = uriQueryObject['options']
          .replace(/[ ]?:[ ]?/g, ':')
          .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2":')
          .replace(/:(['"])?([a-z0-9A-Z_@.]+)(['"])?/g, ':"$2"')
        const loginOptions = JSON.parse(optionsJSON)

        // remove login specific options from the redirect route
        const routeQueryObject = { ...uriQueryObject }
        delete routeQueryObject['options']
        const routeQuery = convertObjectToQuery(routeQueryObject)
        const redirectURI = routeQuery ? `${uriPath}?${routeQuery}` : uriPath

        return {
          redirectURI: redirectURI,
          loginOptions: {
            locale: this.locale,
            ...loginOptions
          }
        }
      } else {
        return {
          redirectURI: uriDecoded,
          loginOptions: {
            locale: this.locale
          }
        }
      }
    },

    async processAuth() {
      const { loginOptions, redirectURI } = this.parseRedirectURI(this.redirectURI)
      try {
        // authenticate
        console.debug('[Login]: Authenticating...')
        await this.$auth.authenticate({
          redirectURI,
          loginOptions
        })

        // redirect to the original route that required authentication / authorization
        // (see authGuard in @/router/guards.js redirect login & authorization)
        redirectURI ? this.goto(redirectURI) : this.goto('/')
      } catch (error) {
        console.error('[Login]: Error during authentication processing.', error)
        await this.reportError(error)
      }
    },

    goto(target) {
      console.debug('[Login]: Replacing /login with', target)
      this.$router.replace(target || '/').catch((_error) => {})
      // error is guaranteed whenever router guard redirected to this route/page
      // .catch((error) => console.debug('[Login]: Push error during redirect.', error))
    },

    async exitOrLogout(error) {
      const result = await this.$alert({
        icon: 'warning',
        title: this.$t(`error.${error.name}.title`),
        text: this.$t(`error.${error.name}.message`),
        footer: error,
        confirmButtonText: this.$t(`ui.exit`),
        showDenyButton: true,
        denyButtonText: this.$t('ui.logout')
      })
      if (result.isConfirmed) {
        const exited = this.exitMixin_exit()
        if (!exited) {
          console.debug('[Login]: Show browser close message...')
          this.$router.push({ path: '/splash?notice=exit' })
        }
      } else if (result.isDenied) {
        console.debug('[Login]: Signing out...')
        await this.$auth.signOut()
        const exited = this.exitMixin_exit()
        if (!exited) {
          console.debug('[Login]: Show browser close message...')
          this.$router.push({ path: '/splash?notice=exit' })
        }
      }
    },

    async reportError(error) {
      switch (error.name) {
        case 'AuthenticationError':
          // FIXME: reroute to /login instead?
          await this.$error(error)
          break
        case 'AuthorizationError':
          await this.exitOrLogout(error)
          break
        case 'AuthorizationRequiredError':
          // FIXME: reroute to /join instead?
          await this.exitOrLogout(error)
          break
        default:
          await this.$error(error)
      }
    },

    showErrorPage(error) {
      this.$router
        .push({
          name: 'error',
          query: {
            code: error.name,
            message: error.message
          }
        })
        .catch((error) => {
          console.debug('[Login]: Push error routing to error page.', error)
        })
    },

    showJoinPage() {
      this.$router
        .push({
          name: 'join'
        })
        .catch((error) => {
          console.debug('[Login]: Push error routing to join page.', error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.c-login-page {
  background-image: url('~@/assets/images/backgrounds/valley-lake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
