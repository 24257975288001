<template>
  <div class="c-notifications">
    <v-btn
      v-tooltip="$t('tooltip.appBar.notifications')"
      fab
      small
      @click="showNotices"
    >
      <div v-if="badgeCount > 0">
        <v-badge
          class="c-badge"
          color="red"
          :content="badgeCount"
          overlap
          top
        >
          <v-icon color="accent"> mdi-bell </v-icon>
        </v-badge>
      </div>
      <div v-else>
        <v-icon color="accent"> mdi-bell </v-icon>
      </div>
    </v-btn>

    <!-- eslint-disable vue/no-v-html -->
    <notifications class="mt-16" group="topbar">
      <template #body="notice">
        <v-alert :type="notice.item.type" dismissible @click.native="removeNotice(notice)">
          <div
            v-if="notice.item.title"
            class="text-xs-caption text-sm-subtitle-1 text-md-subtitle-1 text-lg-h6 text-xl-h6"
          >
            {{ notice.item.title }}
          </div>
          <div class="text-xs-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1">
            {{ notice.item.text }}
          </div>
        </v-alert>
      </template>
    </notifications>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import pushMixin from '@/mixins/pushMixin.js'

export default {
  name: 'AppNotices',

  components: {},

  mixins: [pushMixin],

  data: function () {
    return {
      notices: [],
      badgeCount: 0,
      isShowing: false
    }
  },

  computed: {
    randomNotification() {
      const randomItem = Math.floor(Math.random() * this.notices.length)
      return {
        title: this.notices[randomItem].title,
        body: this.notices[randomItem].text,
        type: this.notices[randomItem].type,
        tag: 'random'
      }
    }
  },

  watch: {
    pushMixin_hasPermission: {
      immediate: true,
      handler: function (newPermission, _oldPermission) {
        if (newPermission && this.$feature('pwaRandomNotifications')) {
          setInterval(() => this.pushMixin_pushNotification(this.randomNotification), 5 * 60 * 1000)
        }
      }
    }
  },

  created: async function () {
    // FIXME: both these values should be in a store
    this.notices = await this.fetchNotices()
    this.badgeCount = this.notices.length
  },

  methods: {
    ...mapActions('appStore', ['fetchNotices']),

    showNotices() {
      if (this.isShowing) {
        if (this.badgeCount > 0) {
          this.$notify({
            group: 'topbar',
            clean: true
          })
        }
        this.badgeCount = 0
        this.isShowing = false
      } else {
        for (const notice of this.notices) {
          this.$notify({
            type: notice.type,
            group: 'topbar',
            title: notice.title,
            text: notice.text,
            closeOnClick: true,
            duration: -1,
            width: 300
          })
        }
        this.badgeCount = this.notices.length
        this.isShowing = true
      }
    },

    removeNotice(notice) {
      this.badgeCount = this.badgeCount - 1
      notice.close()
    },

    clearNotices(group) {
      this.$notify({ group, clean: true })
    }
  }
}
</script>

<style scoped lang="scss">
$badge-font-size: 18px;

@import '@/styles/variables.scss';

.c-notice {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  margin: 0 5px 5px;
  color: white;
  background: var(--v-info-base);
  border-left: 5px solid #187fe7;
}

.c-badge :deep(.v-badge__badge) {
  border: 0.5px solid white !important;
}

.c-notice.warn {
  background: var(--v-warning-base);
  border-left-color: #f48a06;
}

.c-notice.error {
  background: var(--v-error-base);
  border-left-color: #b82e24;
}

.c-notice.success {
  background: var(--v-success-base);
  border-left-color: #42a85f;
}
</style>
