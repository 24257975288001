<template>
  <div class="c-screen-layout">
    <transition name="fade">
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ScreenLayout',

  components: {},

  data: function () {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="css" scoped>
.c-screen-layout {
  background-color: var(--v-sheet-base);
  height: 100vh;
  height: 100dvh;
  overflow-y: hidden;
}
</style>
