<template>
  <SectionPanels
    ref="homeView"
    v-resize="onResize"
    class="c-home-view"
    :depth="depth"
    :initial="[0, 1, 2]"
  >
    <!-- Featured/Recent Content -->
    <ContentPanel
      v-show="showFeaturedContent"
      class="c-panel"
      :dense="isViewportMedium"
      :items="showcaseItems"
      draggable
      :layouts="['grid', 'carousel']"
      :loading="isContentLoading"
      :title="showcaseTitle"
      :width="viewWidth"
      @share="shareItem($event)"
      @view:item="viewItem($event)"
      @view:parent="viewParent($event)"
    />

    <!-- Recent Posts -->
    <PostPanel
      v-show="showRecentPosts"
      class="c-panel"
      :dense="isViewportMedium"
      :posts="recentPosts"
      draggable
      :layouts="['grid', 'carousel']"
      :loading="arePostsLoading"
      :title="$t('home.posts.recent')"
      :width="viewWidth"
      @share="sharePost($event)"
      @view:post="viewPost($event)"
    />

    <!-- Featured/Recent Channels -->
    <ChannelPanel
      v-show="showChannels"
      class="c-panel"
      :dense="isViewportMedium"
      :channels="showcaseChannels"
      draggable
      :layouts="['grid', 'carousel']"
      :loading="areChannelsLoading"
      :title="$t('home.channels.featured')"
      :width="viewWidth"
      @share="shareChannel($event)"
      @view:channel="viewChannel($event)"
    />

    <!-- Calendar -->
    <CalendarPanel
      v-if="showCalendar"
      class="c-panel"
      :entries="calendarEntries"
      :loading="areEntriesLoading"
      :title="$t('home.today')"
    />

    <!-- Modals -->
    <ContentModal v-if="showContentModal" v-model="showContentModal" :item="item" />
    <PostModal v-if="showPostModal" v-model="showPostModal" :post="post" />
    <ShareModal v-if="showSharingModal" v-model="showSharingModal" :entity="sharedEntity" />
  </SectionPanels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// content
import ContentModal from '@/components/content/ContentModal'
import ContentPanel from '@/components/content/ContentPanel'
// posts
import PostPanel from '@/components/post/PostPanel'
import PostModal from '@/components/post/PostModal'
// channels
import ChannelPanel from '@/components/channel/ChannelPanel'
// calendar
import CalendarPanel from '@/components/calendar/CalendarPanel'
import { calendarService } from '@/services/calendarService'
// base
import SectionPanels from '@/components/base/SectionPanels'
import ShareModal from '@/components/base/ShareModal'
import mobileMixin from '@/mixins/mobileMixin.js'
import shareMixin from '@/mixins/shareMixin.js'
import sortMixin from '@/mixins/sortMixin.js'
import viewMixin from '@/mixins/viewMixin.js'

export default {
  name: 'HomeView',

  components: {
    // content components
    ContentModal,
    ContentPanel,
    // post components
    PostPanel,
    PostModal,
    // channel components
    ChannelPanel,
    // calendar components
    CalendarPanel,
    // base components
    SectionPanels,
    ShareModal
  },

  mixins: [mobileMixin, shareMixin, sortMixin, viewMixin],

  data: function () {
    return {
      // page
      depth: 0,
      viewWidth: 0,
      // content
      activeItem: null,
      showContentModal: false,
      // posts
      activePost: null,
      showPostModal: false,
      // channels
      activeChannel: null,
      // events
      calendarEntries: [],
      areEntriesLoading: true,
      // sharing
      sharedEntity: {},
      showSharingModal: false
    }
  },

  computed: {
    ...mapGetters('contentStore', ['libraryItems', 'isLibraryPrimed']),
    ...mapGetters('channelStore', ['areChannelsPrimed']),
    ...mapGetters('postStore', ['arePostsPrimed']),

    isViewportMedium() {
      return this.mobileMixin_isViewportMedium
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    menuRoutes() {
      return this.$store.state.menuStore.menuRoutes
    },

    /* panel controls */

    showFavourites() {
      return (
        this.menuRoutes.includes('content') ||
        this.menuRoutes.includes('collection') ||
        this.menuRoutes.includes('channels')
      )
    },

    showFeaturedContent() {
      return this.showFavourites
    },

    showPosts() {
      return this.menuRoutes.includes('blog') || this.menuRoutes.includes('channels')
    },

    showRecentPosts() {
      return this.showPosts
    },

    showChannels() {
      return this.menuRoutes.includes('channels')
    },

    showCalendar() {
      return this.menuRoutes.includes('schedule')
    },

    /* items */

    isContentLoading() {
      return !this.isLibraryPrimed
    },

    userFavouriteItemIds() {
      return this.$store.state.userStore.favourites
    },

    userFavouriteItems() {
      return this.libraryItems
        .filter((item) => this.userFavouriteItemIds.includes(item.id))
        .sort(
          (a, b) =>
            this.userFavouriteItemIds.indexOf(a.id) - this.userFavouriteItemIds.indexOf(b.id)
        )
    },

    hasFeaturedItems() {
      return this.featuredItems.length > 0
    },

    featuredItems() {
      return this.libraryItems
        .filter((item) => item.isFeatured && item.locale === this.locale)
        .slice(0, 5)
    },

    recentItems() {
      const sortName = 'sortByPublishDateDesc'
      return this.libraryItems
        .filter((item) => item.locale === this.locale)
        .slice()
        .sort(this.sortMixin_getSortFunction(sortName))
        .slice(0, 5)
    },

    showcaseItems() {
      return this.hasFeaturedItems ? this.featuredItems : this.recentItems
    },

    showcaseTitle() {
      return this.hasFeaturedItems
        ? this.$t('home.content.featured')
        : this.$t('home.content.recent')
    },

    /* posts */

    arePostsLoading() {
      return !this.arePostsPrimed
    },

    recentPosts() {
      const sortName = 'sortByPublishDateDesc'
      return this.$store.state.postStore.posts
        .slice()
        .sort(this.sortMixin_getSortFunction(sortName))
        .slice(0, 5)
    },

    /* channels */

    areChannelsLoading() {
      return !this.areChannelsPrimed
    },

    featuredChannels() {
      return this.$store.state.channelStore.channels
        .filter((channel) => channel.isFeatured)
        .slice(0, 5)
    },

    hasFeaturedChannels() {
      return this.featuredChannels.length > 0
    },

    recentChannels() {
      const sortName = 'sortByPublishDateDesc'
      return this.$store.state.channelStore.channels
        .slice()
        .sort(this.sortMixin_getSortFunction(sortName))
        .slice(0, 3)
    },

    showcaseChannels() {
      return this.hasFeaturedChannels ? this.featuredChannels : this.recentChannels
    },

    today() {
      const d = new Date()
      return d.toJSON().slice(0, 10)
    }
  },

  watch: {
    showContentModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    },

    showPostModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    }
  },

  created: async function () {
    /* retrieve all home page data in "parallel" */

    // ensure content store is primed
    if (this.showFavourites) {
      this.fetchLibrary()
    }

    // ensure post store is primed
    if (this.showPosts) {
      this.fetchPosts()
    }

    // ensure channel store is primed
    if (this.showChannels) {
      this.fetchChannels()
    }

    if (this.showCalendar) {
      this.fetchCalendarToday()
    }
  },

  mounted: function () {
    const panels = this.$refs.homeView.$el.querySelectorAll('.c-panel') || []
    this.depth = panels.length

    // $nextTick handles sidebar padding in v-main
    this.$nextTick(() => this.onResize())
  },

  methods: {
    // TODO: eventually, all data should come from a "store"
    ...mapActions('contentStore', ['fetchLibrary']),
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('channelStore', ['fetchChannels']),

    onResize() {
      this.viewWidth = this.$refs.homeView.$el.clientWidth
    },

    /* calendar */

    fetchCalendarToday() {
      this.areEntriesLoading = true
      calendarService.fetchCalendarEntries(this.today, this.today).then((entries) => {
        this.calendarEntries = entries
        this.areEntriesLoading = false
      })
    },

    /* sharing */

    async shareChannel(channel) {
      this.activeChannel = channel
      this.sharedEntity = await this.shareMixin_shareChannel(channel)
      this.showSharingModal = !!this.sharedEntity
    },

    async shareItem(item) {
      this.activeItem = item
      this.sharedEntity = await this.shareMixin_shareItem(item)
      this.showSharingModal = !!this.sharedEntity
    },

    async sharePost(post) {
      this.activePost = post
      this.sharedEntity = await this.shareMixin_sharePost(post)
      this.showSharingModal = !!this.sharedEntity
    },

    /* viewing */

    viewChannel(channel) {
      // this.activeChannel = channel
      this.viewMixin_viewChannel(channel)
    },

    viewItem(item) {
      // this.activeItem = item
      // this.showContentModal = true
      this.viewMixin_viewItem(item)
    },

    viewParent(parent) {
      this.viewMixin_viewReference(parent)
    },

    viewPost(post) {
      // this.activePost = post
      // this.showPostModal = true
      this.viewMixin_viewPost(post)
    },

    /* scrolling */

    suspendScrolling(suspend) {
      // prevent scrolling of underlying content grid/tree
      // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
      if (suspend) {
        document.body.style.top = `-${window.scrollY}px` // capture before setting position to fixed
        document.body.style.position = 'fixed'
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
      // document.documentElement.addEventListener('touchmove', (e) => e.preventDefault())
    }
  }
}
</script>

<style lang="css" scoped>
.c-home-view {
  background-color: var(--v-sheet-base);
}
</style>
