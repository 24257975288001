var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        "v-system-bar",
        {
          attrs: {
            app: "",
            color: _vm.message.type,
            dark: "",
            height: "50",
            width: "100%",
            window: "",
          },
        },
        [
          _c(
            "v-alert",
            {
              staticClass: "c-sysalert",
              attrs: {
                close: "mdi-close-circle",
                dismissible: "",
                elevation: "0",
                icon: _vm.messageIcon,
                tile: "",
                type: _vm.messageType,
              },
              model: {
                value: _vm.enabled,
                callback: function ($$v) {
                  _vm.enabled = $$v
                },
                expression: "enabled",
              },
            },
            [_vm._v(" " + _vm._s(_vm.messageText) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }