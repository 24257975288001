<template>
  <v-toolbar
    class="c-toolbar"
    color="primary"
    dark
    dense
    flat
    fullscreen
    hide-overlay
    tile
    width="100%"
  >
    <v-spacer />

    <v-toolbar-title
      v-tooltip="titleTooltip"
      class="c-title text-left text-h4"
    >
      {{ title }}
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>

    <v-btn
      fab
      icon
      @click="$emit('toggle:favourite')"
    >
      <v-icon class="xred--text" large>
        {{ favourite ? 'mdi-heart' : 'mdi-heart-outline' }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="$actions.share"
      class="ml-2"
      fab
      icon
      @click="$emit('share')"
    >
      <v-icon large> mdi-share-variant </v-icon>
    </v-btn>
    <v-btn
      class="ml-2"
      fab
      icon
      @click.stop="$emit('close')"
    >
      <v-icon large> mdi-close </v-icon>
    </v-btn>
  </v-toolbar-items>
  </v-toolbar>
</template>

<script>
const DENSE_BAR_HEIGHT = 48

export default {
  name: 'PostBar',

  components: {},

  props: {
    title: {
      type: String,
      required: true
    },

    favourite: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    titleTooltip() {
      return {
        content: this.title,
        theme: 'overflow'
      }
    }
  },

  mounted: function () {
    this.$emit('height', DENSE_BAR_HEIGHT)
  }
}
</script>

<style lang="css" scoped>
.c-toolbar {
  position: fixed;
  top: 0;
  z-index: 1;
}
.c-toolbar :deep(.v-toolbar__content) {
  justify-content: space-between;
}
.c-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.c-actions {
  position: absolute;
  right: 0;
}
</style>
