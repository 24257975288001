import colors from 'vuetify/lib/util/colors'
import pkg from '@@/package.json'

// eslint-disable-next-line no-unused-vars
const parse = (value, fallback) => {
  if (typeof value === 'undefined') {
    return fallback
  }

  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

// context
export const APP_NAME = 'Lumenii'
export const APP_VERSION = `V${pkg.version} (2025-01-14)`
export const APP_ENV = process.env.NODE_ENV || 'development'
export const APP_ROOT = process.env.BASE_URL || '/'

// external services
export const AVATAR_BASE_URL = 'https://seccdn.libravatar.org/avatar'

// internal constants
export const LIBRARY_COLLECTION_KEY = 'library'

// analytics
export const GTM_ID = process.env.VUE_APP_GTM_ID
export const POSTHOG_KEY = process.env.VUE_APP_POSTHOG_KEY
export const POSTHOG_BASE_URL = 'https://us.posthog.com/embedded'
export const POSTHOG_DASHBOARD_ID = process.env.VUE_APP_POSTHOG_DASHBOARD_ID
export const POSTHOG_API_URL = 'https://us.i.posthog.com'
export const TRACKING_URL = 'https://www.healthplexus.net/ad/track/index.php'

// settings
export const DEFAULT_CALENDAR_OVERLAP_MODE = 'column'
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_MODE = 'dark'
export const DEFAULT_THEME = {
  themes: {
    light: {
      // branding colours
      primary: colors.blueGrey.lighten2,
      secondary: colors.grey.lighten1,
      tertiary: colors.lightBlue.lighten2,
      accent: colors.cyan.accent3,
      // digital colours
      anchor: colors.blue.darken3,
      background: colors.grey.lighten4,
      fab: colors.grey.darken2,
      icon: colors.grey.darken3,
      outline: colors.grey.lighten2,
      sheet: colors.grey.lighten2,
      text: colors.shades.black,
      // notification colours
      info: colors.blue.darken4,
      success: colors.green.darken4,
      warning: colors.amber.darken4,
      error: colors.red.darken4
    },
    dark: {
      // branding colours
      primary: colors.blueGrey.darken1,
      secondary: colors.grey.darken1,
      tertiary: colors.lightBlue.darken3,
      accent: colors.cyan.accent3,
      // digital colours
      anchor: colors.blue.lighten3,
      background: colors.blueGrey.darken4,
      fab: colors.grey.lighten2,
      icon: colors.shades.white,
      outline: colors.grey.darken2,
      sheet: colors.blueGrey.darken3,
      text: colors.shades.white,
      // notification colours
      info: colors.blue.darken4,
      success: colors.green.darken4,
      warning: colors.amber.darken4,
      error: colors.red.darken4
    }
  }
}

export const appConfig = {
  // branding
  appLogo: 'lumenii-logo.png',
  appName: APP_NAME,

  // configuration
  appVersion: APP_VERSION,
  appRoot: '/',
  appEnv: APP_ENV,

  // global state (mutable)
  historyStart: 0,

  // third party services
  avatarBaseURL: AVATAR_BASE_URL,
  posthogBaseURL: POSTHOG_BASE_URL,
  posthogAPIURL: POSTHOG_API_URL,

  // i18n
  defaultLocale: DEFAULT_LOCALE,

  // dark mode
  defaultMode: DEFAULT_MODE,

  // default theme
  defaultTheme: DEFAULT_THEME,

  // content
  libraryCollectionKey: LIBRARY_COLLECTION_KEY,

  // calendar
  defaultCalendarOverlapMode: DEFAULT_CALENDAR_OVERLAP_MODE,

  // tracking
  trackingId: GTM_ID,

  // end of object
  endOfConfig: ''
}
