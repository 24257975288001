// https://github.com/GerardRodes/why-did-you-render/blob/master/WhyDidYouRender.js

function createWatchers() {
  for (const prop in this) {
    this.$watch(prop, (val, old) => {
      console.error('[WhyDidYouRender:watcher]', this.$options.name, prop, { val, old })

      if (window.WDYU_DEBUG) {
        debugger
      }
    })
  }

  this.$parent && createWatchers.call(this.$parent)
}

export default {
  created: function () {
    if (!this.$options.debug) return
    createWatchers.call(this)
  },

  updated: function () {
    if (!this.$options.debug) return
    console.error('[WhyDidYouRender:UPDATED]', this.$options.name)
  }
}
