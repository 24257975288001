var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-group",
    {
      staticClass: "c-list-group",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", { attrs: { color: "accent", "x-large": "" } }, [
                    _vm._v(" mdi-translate "),
                  ]),
                ],
                1
              ),
              _c("v-list-item-title", { staticClass: "text-h6 text-left" }, [
                _vm._v(" " + _vm._s(_vm.currentLocaleName) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("LocaleSelector", { staticClass: "ml-8" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }