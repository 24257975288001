<template>
  <RichTextEditor
      v-if="rich"
      readonly
      :value="body"
    />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-else class="c-body" v-html="body" />
</template>

<script>
import RichTextEditor from '@/components/base/RichTextEditor'

export default {
  name: 'CommentBody',

  components: {
    RichTextEditor
  },

  props: {
    body: {
      type: String,
      required: true
    },

    rich: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isString() {
      return typeof this.body === 'string' || this.body instanceof String
    }
  }
}
</script>

<style lang="scss" scoped>
.c-body {
  :deep(img) {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  :deep(video) {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
}
</style>
