<template>
  <div class="c-app-update">
    <!-- "update available" snack -->
    <v-snackbar
      class="c-snack"
      content-class="c-snack__content"
      app
      bottom
      right
      :dark="isDark"
      :light="!isDark"
      :timeout="-1"
      :value="activationPrompt"
    >
      {{ $t('app.notice.update') }}
      <template #action="{ attrs }">
        <v-btn
          fab
          small
          v-bind="attrs"
          @click="activateUpdate"
        >
          <v-icon class="c-icon" color="accent"> mdi-cellphone-arrow-down </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- "reload required" snack -->
    <v-snackbar
      v-if="false"
      class="c-snack"
      content-class="c-snack__content"
      app
      bottom
      right
      :dark="isDark"
      :light="!isDark"
      :timeout="-1"
      :value="reloadPrompt"
    >
      {{ $t('app.notice.reload') }}
      <template #action="{ attrs }">
        <v-btn
          fab
          small
          v-bind="attrs"
          @click="reloadApp"
        >
          <v-icon class="c-icon" color="accent"> mdi-reload-alert </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- "reload required" modal -->
    <ModalDialog
      v-model="reloadPrompt"
      confirm
      :title="$t('app.activate.title')"
      :submit-text="$t('app.activate.label')"
      @submit="reloadApp"
  >
    {{ $t('app.notice.reload') }}
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { APP_VERSION } from '@/config/appConfig.js'
import ModalDialog from '@/components/base/ModalDialog'

export default {
  name: 'AppUpdate',

  components: {
    ModalDialog
  },

  data() {
    return {
      isActivating: false,
      isReloading: false,
      serviceWorker: null,
      activationPrompt: false,
      reloadPrompt: false
    }
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    }
  },

  mounted: function () {
    // from service worker
    const once = true
    document.addEventListener('CustomUpdateAvailable', this.onUpdateAvailable, { once })
    document.addEventListener('CustomUpdated', this.onUpdateAvailable, { once })

    // from serice worker container
    document.addEventListener('CustomControllerChange', this.onControllerChange)
  },

  beforeDestroy: function () {
    const once = true
    document.removeEventListener('CustomUpdateAvailable', this.onUpdateAvailable, { once })
    document.addEventListener('CustomUpdated', this.onUpdateAvailable, { once })
    document.removeEventListener('CustomControllerChange', this.onControllerChange)
  },

  methods: {
    ...mapActions('appStore', ['updateVersion']),

    // updates detected (installed, but not activated)
    onUpdateAvailable(e) {
      // update status sent from the service worker (see pwaRegister logic)
      console.debug(
        `[AppUpdate]: ${
          e.type === 'CustomUpdateAvailable'
            ? 'Service worker update available!'
            : 'Service worker updated!'
        }`
      )
      this.serviceWorker = e.detail
      this.activationPrompt = e.type === 'CustomUpdateAvailable'
    },

    // ...activate the installed updates
    activateUpdate(e) {
      // app instructs the pwa
      // (because the serviceWorker asked and the user agreed)
      this.activationPrompt = false

      // tell service worker to activate the update
      if (this.serviceWorker) {
        console.debug('[AppUpdate]: Tell service worker to activate.', e.detail)
        this.serviceWorker.postMessage({ type: 'SKIP_WAITING' })
        this.isActivating = true
      } else {
        console.error('[AppUpdate]: Unable to tell service worker to activate.', e.detail)
      }
    },

    // an updated service worker is available
    onControllerChange(e) {
      // called regardless of whether the app was informed of the update
      console.debug('[AppUpdate]: onControllerChange=', e)
      this.updateVersion(APP_VERSION)
      // prompt the user
      this.reloadPrompt = true
    },

    // ...refresh the page to use it
    reloadApp() {
      // each client instance reloads itself
      if (!this.isReloading) {
        console.debug('[AppUpdate]: Reloading app after user agreed.')
        this.reloadPrompt = false
        window.location.reload()
        this.isReloading = true
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-snack .c-icon {
  color: var(--v-accent-base) !important;
  caret-color: var(--v-accent-base) !important;
}
.c-snack :deep(.v-snack__wrapper) {
  background-color: var(--v-primary-base);
  padding-right: 8px;
}
.c-snack :deep(.c-snack__content) {
  font-size: 1rem;
  font-weight: bold;
}
.c-snack.theme--dark :deep(.v-btn) {
  background-color: black;
}
.c-snack.theme--light :deep(.v-btn) {
  background-color: white;
}
</style>
