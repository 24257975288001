export default {
  activePortal(state, getters, rootState) {
    return rootState.portalStore.activePortal
  },

  arePostsPrimed(state) {
    return state.posts.length > 0 || state.fetched
  },

  arePostsLoading(state) {
    return state.loading == undefined ? false : state.loading
  },

  getPostsByAuthor(state) {
    return ({ id, lastName }) =>
      id
        ? state.posts.find((post) => post.author?.id === id)
        : state.posts.find((post) => post.author?.lastName === lastName)
  },

  getPost(state) {
    return ({ id, slug }) =>
      id
        ? state.posts.find((post) => post.id === id)
        : state.posts.find((post) => post.slug === slug)
  },

  getLikes(state, getters) {
    return (postId) => getters.getPost({ id: postId })?.likes || 0
  }
}
