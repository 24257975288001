/*
 * Notes:
 *   1)Workbox offers helper functions for service workers.
 *   2)Service Worker collaborates with AppInstall component.
 *   3)Reference Material
 *     https://web.dev/articles/service-worker-lifecycle
 *     https://gist.github.com/leommoore/6415005
 */

import { Workbox } from 'workbox-window'
import splashScreens from '@/config/splashScreens.js'
import { parseURL } from '@/utilities/urlUtils.js'
import { pushService } from '@/services/pushService.js'
import { getFeature } from '@/config/featureConfig.js'
import { APP_VERSION } from './config/appConfig'

// flags
const isDev = process.env.NODE_ENV === 'development'
const skipWaiting = getFeature('pwaSkipWaiting')
export const isPWA = () => {
  const pwa =
    ('standalone' in window.navigator && window.navigator.standalone) ||
    // everywhere else
    window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: minimal-ui)').matches

  return pwa
}

// utilities
const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
const displayQuota = () => {
  if (isDev) {
    navigator.storage.estimate().then((estimate) => {
      const quotaMB = (estimate.quota / 1024 / 1024).toFixed(2)
      const quotaPercent = ((estimate.usage / estimate.quota) * 100).toFixed(2)
      console.info('[pwa]: Quota available (Mb) =', quotaMB)
      console.info('[pwa]: Quota used (%) =', quotaPercent)
    })
  }
}

// define method for handling service worker replacements
let isUpdated = false
const onUpdated = ({ newSW, oldSW }) => {
  displayQuota()

  const notifyMethod = skipWaiting ? 'SKIP' : 'APP'
  console.debug('[pwa]: Update notification method=', notifyMethod)

  switch (notifyMethod) {
    // notify app that a new worker has been installed
    case 'APP':
      // inform the app
      setTimeout(() => {
        const event = new CustomEvent('CustomUpdateAvailable', { detail: newSW })
        document.dispatchEvent(event)
      }, 5000)
      break

    // notify user that a new worker has been installed
    case 'USER':
      if (window.confirm('Update available! Install now?')) {
        // tell the waiting worker to proceed
        if (oldSW) oldSW.postMessage({ type: 'ABORT' })
        newSW.postMessage({ type: 'SKIP_WAITING' })
        // inform the app
        document.dispatchEvent(new CustomEvent('CustomUpdated', { detail: newSW }))
      }
      break

    // silently update
    case 'SKIP':
      console.debug('[pwa]: Skip waiting and activate immediately!')
      if (oldSW) oldSW.postMessage({ type: 'ABORT' })
      newSW.postMessage({ type: 'SKIP_WAITING' })
      // inform the app
      document.dispatchEvent(new CustomEvent('CustomUpdated', { detail: newSW }))
      break

    default:
      console.debug('[pwa]: Invalid notification method!', notifyMethod)
  }
}

// define method for handling app refreshes
let isRefreshing = false
const onControllerChange = (event) => {
  console.debug(`[pwa]: A new service worker ${APP_VERSION} has taken control!`)
  if (isPWA()) {
    // notify app to prompt user to reload app
    setTimeout(() => {
      const appEvent = new CustomEvent('CustomControllerChange', { detail: event.detail })
      document.dispatchEvent(appEvent)
    }, 0)
  } else {
    // reload window now to ensure latest app is running
    if (!isRefreshing) {
      console.debug('[AppUpdate]: Automatically reloading client window.')
      window.location.reload()
      isRefreshing = true
    }
  }
}

// define method for sending a message to the service worker
export const sendMessage = async (message) => {
  const registration = await navigator.serviceWorker.ready
  const response = await registration.active.postMessage(message)
  return response
}

// define method for receiving service worker messages
const onMessage = (event) => {
  const messageType = event.data && event.data.type

  // if using workbox-broadcast-update
  if (messageType === 'CACHE_UPDATED') {
    const { cacheName, updatedURL } = event.data.payload

    console.debug(`[pwa]: A newer version of ${updatedURL} in cache ${cacheName} is available!`)
  }

  // old service worker version
  if (messageType === 'SW_VERSION') {
    console.debug('[pwa]: Pre-existing service worker version=', event.data.version)
  }
}

// register the service worker (based on lifecycle monitoring)
export const register = async () => {
  console.debug('[pwa]: Registering new service worker!')

  // check if service workers are supported
  if (!('serviceWorker' in navigator)) {
    console.error('[pwa]: Service workers are not supported!')
    return
  }

  // detect whether an existing service worker is active
  const oldSW = navigator.serviceWorker.controller
  console.debug('[pwa]: Pre-existing service worker detected=', !!oldSW)
  if (oldSW) {
    sendMessage({ type: 'GET_VERSION' })
  }

  // add listeners (for events from the service worker container)
  navigator.serviceWorker.addEventListener('controllerchange', onControllerChange)
  navigator.serviceWorker.addEventListener('message', onMessage)

  // manage service worker lifecycle (register -> install -> activate -> ready)
  // https://whatwebcando.today/articles/handling-service-worker-updates/
  try {
    // register service worker
    const swURL = '/sw.js'
    const options = { scope: '/' }
    const registration = await navigator.serviceWorker.register(swURL, options)

    // registration.installing - the installing service worker (or undefined)
    // registration.waiting - the waiting service worker (or undefined)
    // registration.active - the active service worker (or undefined)

    // handle the case where the updatefound event was missed
    /* If there's a waiting service worker with a matching URL before the
     * `updatefound` event fires, it likely means that this site is open
     * in another tab (and in that tab, the service worker was installed)
     * or the user refreshed the page (but the previous page wasn't
     * fully unloaded before this page started loading).
     * https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#waiting
     */
    if (registration.waiting) {
      console.debug('[pwa]: Service worker registration was immediate!')
      onUpdated({ newSW: registration.waiting, oldSW: oldSW })
      return
    }

    // otherwise, define an event handler for registration updates
    registration.addEventListener('updatefound', () => {
      console.debug('[pwa]: Updated service worker detected!')

      const newSW = registration.installing

      // listen for further state changes for the new service worker
      newSW.addEventListener('statechange', (e) => {
        const state = e.target.state // same as newSW.state
        // "installing" - the install event has fired, but not yet complete
        // "installed"  - install complete
        // "activating" - the activate event has fired, but not yet complete
        // "activated"  - fully active
        // "redundant"  - discarded (failed to install or replaced by a newer version)

        // if (registration.waiting) {
        if (state === 'installed') {
          // check if there's a pre-existing "activated" service worker
          // if (navigator.serviceWorker.controller) {
          if (oldSW) {
            console.debug('[pwa]: Updated service worker installed!')
            isUpdated = true
            onUpdated({ newSW, oldSW })
          } else {
            // first time install (so there's nothing to do)
            console.debug('[pwa]: Service worker installed (for the first time)!')
          }
        } else if (state === 'activated') {
          // else if (registration.active) {
          isUpdated
            ? console.debug('[pwa]: Updated service worker activated!')
            : console.debug('[pwa]: Service worker activated (for the first time)!')
        } else if (state === 'redundant') {
          console.error('[pwa]: Service worker installation abandoned.')
        } else {
          console.debug(`[pwa]: Updated service worker is ${state}!`)
        }
      })
    })

    // ask the service worker for its version
    // const swVersion = await sendMessage({ type: 'GET_VERSION' })
    // console.log('[pwa]: Service Worker version:', swVersion)
  } catch (error) {
    console.error('[pwa]: Service worker registration failed!', error)
  }
}

// register the service worker (based on workbox-window)
export const registerV2 = async () => {
  // check if service workers are supported
  if (!('serviceWorker' in navigator)) {
    console.error('[pwa]: Service workers are not supported!')
    return
  }

  // detect whether an existing service worker is active
  const oldSW = navigator.serviceWorker.controller
  console.debug('[pwa]: Pre-existing service worker detected=', !!oldSW)

  const wb = new Workbox('/sw.js')

  // new service worker cannot install due to a previous service worker
  wb.addEventListener('waiting', (event) => {
    console.debug(
      `[pwa]: A new service worker has been installed, but it can't activate` +
        `until all tabs running the current version have fully unloaded.`
    )

    wb.addEventListener('controlling', onControllerChange)

    onUpdated({ newSW: event.sw, oldSW: oldSW })
  })

  // service worker was installed
  wb.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      console.debug('[pwa]: Updated service worker installed!')
    } else {
      console.debug('[pwa]: Service worker installed (for the first time)!')
    }
  })

  // service worker was activated
  wb.addEventListener('activated', (event) => {
    // unnecessary because everything is precached
    /*
    // Get the current page URL + all resources the page loaded.
    const urlsToCache = [
      location.href,
      ...performance.getEntriesByType('resource').map((r) => r.name)
    ]

    // Send that list of URLs to your router in the service worker.

    wb.messageSW({
      type: 'CACHE_URLS',
      payload: { urlsToCache }
    })
    */

    if (event.isUpdate) {
      console.debug('[pwa]: Updated service worker activated!')
    } else {
      console.debug('[pwa]: Service worker activated (for the first time)!')
    }
  })

  // listen for messages from the service worker
  wb.addEventListener('message', onMessage)

  // register the service worker
  await wb.register()

  // ask the service worker for its version
  const { version } = await wb.messageSW({ type: 'GET_VERSION' })
  console.log('[pwa]: Service Worker version:', version)
}

// deregister the service worker
export const deregister = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready
      registration.unregister()
    } catch (error) {
      console.error('[pwa]: Deregistering service worker failed!', error)
    }
  }
}

// deregister *all* service workers
export const deregisterAll = async () => {
  if (window.navigator && navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for (let registration of registrations) {
      registration.unregister()
    }
  }
}

// add screen
export const addSplashScreens = () => {
  const { tenantKey } = parseURL()
  for (const ss of splashScreens) {
    const link = document.createElement('link')
    link.rel = 'apple-touch-startup-image'
    link.media = `screen and (device-width: ${ss.width}px) and (device-height: ${ss.height}px) and (-webkit-device-pixel-ratio: ${ss.pixelRatio}) and (orientation: ${ss.orientation})`
    link.href = `/${tenantKey}/splash_screens/${ss.devices}`
    document.head.appendChild(link)
  }
}

// events raised by the browser client
export const addInstallListeners = () => {
  // Chrome/Edge only
  window.addEventListener('beforeinstallprompt', (e) => {
    console.debug('[pwa]: beforeinstallprompt received')
    e.preventDefault()
    const installEvent = e // save the event
    setTimeout(() => {
      const event = new CustomEvent('CustomBeforeInstallPrompt', { detail: installEvent })
      document.dispatchEvent(event)
    }, 5000)
  })

  // Chrome/Edge only
  window.addEventListener('appinstalled', (e) => {
    setTimeout(() => {
      const event = new CustomEvent('CustomAppInstalled', { detail: e })
      document.dispatchEvent(event)
    }, 500)
  })
}

// subscribe to push notifications
export const subscribe = async () => {
  try {
    const registration = await navigator.serviceWorker.ready

    if (!registration.pushManager) {
      return
    }

    // check for an existing subscription
    const currentSubscription = await registration.pushManager.getSubscription()
    if (currentSubscription) {
      console.debug('[pwa]: Current push subscription=', currentSubscription)
      return currentSubscription
    }

    // get VAPID public key
    const { vapidPublicKey } = await pushService.fetchKey()

    // create new subscription
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(vapidPublicKey)
    })

    // save subscription in backend
    await pushService.saveSubscription(subscription.toJSON())
  } catch (error) {
    console.error('[pwa]: Unable to subscribe to push notifications.', error)
  }
}

export const unsubscribe = async () => {
  try {
    const registration = await navigator.serviceWorker.ready

    if (!registration.pushManager) {
      return
    }

    const subscription = await registration.pushManager.getSubscription()
    const result = await registration.pushManager.unsubscribe(subscription)
    if (result) {
      // delete subscription from backend
      const subscriptionId = subscription.endpoint.split('/').pop()
      await pushService.deleteSubscription(subscriptionId)
    }
  } catch (error) {
    console.error('[pwa]: Unable to unsubscribe from push notifications.', error)
  }
}
