import { PluginKey } from 'prosemirror-state'
import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'

import CommentMentions from '@/components/comments/CommentMentions'

const render = (prefix) => {
  let component
  let popup

  return {
    onStart: (props) => {
      component = new VueRenderer(CommentMentions, {
        editor: props.editor,
        // using vue 2:
        parent: this,
        propsData: { ...props, prefix }
        // using vue 3
        // props
      })

      if (!props.clientRect) {
        return
      }

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start'
      })
    },

    onUpdate(props) {
      component.updateProps(props)

      if (!props.clientRect) {
        return
      }

      popup[0].setProps({
        getReferenceClientRect: props.clientRect
      })
    },

    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        popup[0].hide()

        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onExit() {
      popup[0].destroy()
      component.destroy()
    }
  }
}

const filterTopics = ({ query, topics }) => {
  return query.length > 1
    ? topics.filter((topic) => topic.name.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
    : []
}

const filterUsers = ({ query, users }) => {
  return query.length > 1
    ? users.filter((user) => user.name.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
    : []
}

export const hashtagPlugin = (topics) => ({
  char: '#',
  allowSpaces: false,
  decorationClass: 'suggestion--topic',
  items: ({ query }) => filterTopics({ query, topics }),
  pluginKey: new PluginKey('hashtagPluginKey'),
  render: () => render('#')
})

export const mentionPlugin = (users) => ({
  char: '@',
  allowSpaces: false,
  decorationClass: 'suggestion--user',
  items: ({ query }) => filterUsers({ query, users }),
  pluginKey: new PluginKey('mentionPluginKey'),
  render: () => render('@')
})
