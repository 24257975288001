<template>
  <div v-if="editor" ref="editor" class="c-editor">
    <RichTextToolbar
      v-if="editable"
      class="mb-2"
      :editor="editor"
      @expand="onResizeEditor($event)"
    />
    <editor-content
      class="c-editor__content"
      :editor="editor"
    />
    <div
      v-if="maxLength && editable"
      class="c-count mt-2 mr-2"
      :class="{ 'c-max': editor.storage.characterCount.characters() === maxLength }"
    >
      {{ editor.storage.characterCount.characters() }} / {{ maxLength }}
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
// https://tiptap.dev/docs/editor/extensions/functionality/starterkit
import StarterKit from '@tiptap/starter-kit'
// marks
// import Bold from '@tiptap/extension-bold'
// import Code from '@tiptap/extension-code'
// import Italic from '@tiptap/extension-italic'
// import Strike from '@tiptap/extension-strike'
// blocks (aka "nodes")
// import Blockquote from '@tiptap/extension-blockquote'
// import BulletList from '@tiptap/extension-bullet-list'
// import CodeBlock from '@tiptap/extension-code-block'
// import Document from '@tiptap/extension-document'
// import HardBreak from '@tiptap/extension-hard-break'
// import Heading from '@tiptap/extension-heading'
// import HorizontalRule from '@tiptap/extension-horizontal-rule'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
// functionalities
// import Dropcursor from '@tiptap/extension-dropcursor'
// import Gapcursor from '@tiptap/extension-gapcursor'
// import History from '@tiptap/extension-history'

// extensions
import BubbleMenu from '@tiptap/extension-bubble-menu'
import CharacterCount from '@tiptap/extension-character-count'
// import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
// import Suggestion from "@tiptap/suggestion"
import TextAlign from '@tiptap/extension-text-align'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'

import RichTextToolbar from '@/components/base/RichTextToolbar'
import { EmoticonReplacer } from '@/utilities/editor/emoticonReplacer.js'
// import { Hashtag } from '@/utilities/editor/hashtags.js'
import { hashtagPlugin, mentionPlugin } from '@/utilities/editor/suggestionPlugins.js'

// TODO: fetch #topics / @users for real
import topics from '@/services/mock/data/topics.json'
import users from '@/services/mock/data/users.json'

export default {
  name: 'RichTextEditor',

  components: {
    EditorContent,
    RichTextToolbar
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },

    maxLength: {
      type: Number,
      required: false,
      default: 400
    },

    value: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editor: null,
      expanded: false
    }
  },

  computed: {
    editable() {
      return !this.readonly
    }
  },

  watch: {
    value: {
      immediate: false,
      handler: function (value, _oldValue) {
        const isSame = this.editor.getHTML() === value
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

        if (isSame) {
          return
        }

        this.editor.commands.setContent(value, false)
      }
    }
  },

  created: function () {
    this.createEditor()
  },

  beforeDestroy: function () {
    this.editor?.destroy()
  },

  methods: {
    createEditor() {
      this.editor = new Editor({
        content: this.value,
        // props
        editorProps: {
          attributes: {
            spellcheck: this.editable
          }
        },
        // extensions
        extensions: [
          StarterKit,
          BubbleMenu,
          CharacterCount.configure(
            this.maxLength && {
              limit: this.maxLength
            }
          ),
          /*
          FileHandler.configure({
            allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
            onDrop: this.onDrop,
            onPaste: this.onPaste
          }),
          */
          // Hashtag(),
          Image.configure({
            allowBase64: true
          }),
          Link.configure({
            defaultProtocol: 'https',
            validate: (href) => /^https?:\/\//.test(href)
          }),
          Mention.extend({
            name: 'mention'
          }).configure({
            HTMLAttributes: {
              class: 'mention'
            },
            suggestion: mentionPlugin(users)
          }),
          Mention.extend({
            name: 'hashtag'
          }).configure({
            HTMLAttributes: {
              class: 'hashtag'
            },
            suggestion: hashtagPlugin(topics)
          }),
          Placeholder.configure({
            placeholder: this.$t('comment.placeholder')
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph']
          }),
          Typography,
          Underline,
          Youtube.configure({
            allowFullscreen: true,
            controls: true,
            modestBranding: true,
            nocookie: false
          }),
          // custom extensions
          EmoticonReplacer
        ],
        // settings
        autofocus: this.editable,
        editable: this.editable,
        injectCSS: true,
        // events
        onUpdate: () => {
          this.$emit('input', this.editor.getHTML())
          // this.$emit('input', this.editor.getJSON())
        }
      })
    },

    onDrop(currentEditor, files, pos) {
      files.forEach((file) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          currentEditor
            .chain()
            .insertContentAt(pos, {
              type: 'image',
              attrs: {
                src: fileReader.result
              }
            })
            .focus()
            .run()
        }
      })
    },

    onPaste(currentEditor, files) {
      files.forEach((file) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          currentEditor
            .chain()
            .insertContentAt(currentEditor.state.selection.anchor, {
              type: 'image',
              attrs: {
                src: fileReader.result
              }
            })
            .focus()
            .run()
        }
      })
    },

    onResizeEditor(expand) {
      this.expanded = expand
      this.editor.setOptions({
        editorProps: {
          attributes: {
            class: expand ? 'c-expanded' : 'c-collapsed'
          }
        }
      })
      this.$emit('expand', expand)
    }
  }
}
</script>

<style lang="css" scoped>
.c-editor {
  width: 100%;
}

.c-count {
  text-align: right;
  color: grey;
  font-size: 0.75rem;
  gap: 0.5rem;
}

.c-count.c-max {
  color: var(--v-warning-base);
}
</style>

<style lang="scss">
.tiptap {
  padding: 8px;
  width: 100%;
}

.tiptap[contenteditable='true'] {
  border: 1px solid grey;
  border-radius: 16px;

  &.c-expanded {
    height: 24rem;
    max-height: 80%;
  }

  &:focus-visible {
    outline-color: var(--v-primary-base);
  }
}

/* editor theming */

.theme--dark {
  .tiptap {
    color: rgb(255, 255, 255, 0.7);

    p.is-editor-empty:first-child::before {
      color: rgba(180, 180, 180, 0.5);
    }
  }
  .tiptap[contenteditable='true'] {
    background: rgba(255, 255, 255, 0.08);

    &:hover:not(:focus-visible) {
      background: rgba(255, 255, 255, 0.16);
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
}

.theme--light {
  .tiptap {
    color: rgb(0, 0, 0, 0.7);

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .tiptap[contenteditable='true'] {
    background: rgba(0, 0, 0, 0.06);

    &:hover:not(:focus-visible) {
      background: rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.87);
    }
  }
}

/* editor contents */

.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* placeholder */
  p.is-editor-empty:first-child {
    overflow: hidden;
  }
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    height: 0;
    float: left;
    pointer-events: none;
  }

  /* paragraphs */
  p {
    margin-bottom: 0;
  }

  /* lists */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* images */
  img {
    display: block;
    height: auto;
    margin: 8px 0;
    width: 100%;

    &.ProseMirror-selectednode {
      outline: 0px solid var(--v-primary-base);
    }
  }

  /* code and preformatted text styles */
  code {
    background-color: var(--v-background-base);
    border-radius: 0.4rem;
    color: var(--v-text-base);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: black;
    border-radius: 0.5rem;
    color: rgba(240, 240, 240, 0.8);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--v-fab-base);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--v-fab-base);
    margin: 2rem 0;
  }

  /* link styles */
  a {
    color: blue;
    cursor: pointer;

    &:hover {
      color: mediumBlue;
    }
  }

  /* emoji extension */
  [data-type='emoji'] {
    img {
      height: 1rem;
      width: 1rem;
    }
  }

  /* mentions */
  .mention,
  .hashtag,
  .suggestion--user,
  .suggestion--topic {
    background-color: var(--c-accent-light);
    color: var(--v-accent-base);
    border-radius: 0.4rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
  }

  /* youtube embed */
  div[data-youtube-video] {
    cursor: move;
    position: relative;
    max-width: 100%;
    min-height: 180px;
    background-size: contain;
    aspect-ratio: 16 / 9;
    max-height: 100%;
    margin: auto;

    &::after {
      content: '';
      display: block;
    }

    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 0;
    }

    &.ProseMirror-selectednode iframe {
      outline: 0px solid var(--v-primary-base);
      transition: outline 0.15s;
    }
  }
}
</style>
