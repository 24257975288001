import companies from '@/services/mock/data/companies.json'
import contacts from '@/services/mock/data/contacts.json'
import events from '@/services/mock/data/calendar.js'
import notices from '@/services/mock/data/notices.json'
import systemMessage from '@/services/mock/data/systemMessage.json'
import { fakeFetch } from './fakeFetch'
import { fetchOrg, fetchOrgs } from './org'
import { fetchTenant, fetchTenants } from './tenant'
import { fetchContent, getContentHeaders, fetchContentItem } from './content'

export const fetchCalendarEntries = async (start, end) => {
  const eventData = events(start, end)
  return fakeFetch(eventData, 500) // wait 500ms before returning posts
}

export const fetchCompanies = async () => {
  return fakeFetch(companies, 500) // wait 500ms before returning posts
}

export const fetchContacts = async () => {
  return fakeFetch(contacts, 500) // wait 500ms before returning posts
}

export const fetchNotices = async () => {
  return fakeFetch(notices, 500) // wait 500ms before returning posts
}

export const fetchSystemMessage = async () => {
  return fakeFetch(systemMessage, 500) // wait 500ms before returning posts
}

export default {
  fetchCalendarEntries,
  fetchCompanies,
  fetchContacts,
  fetchContent,
  fetchContentItem,
  fetchOrg,
  fetchOrgs,
  fetchTenant,
  fetchTenants,
  getContentHeaders,
  fetchNotices,
  fetchSystemMessage
}
