<template>
  <v-list
    v-if="hasItems"
    :dark="isDark"
    dense
    nav
    rounded
  >
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      active-class="c-active"
      :input-value="index === selectedIndex"
      @click="selectItem(index)"
    >
      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-title>{{ prefix + item.name }}</v-list-item-title>
    </v-list-item>
  </v-list>
  <!-- needs an empty div to ensure *something* gets rendered -->
  <div v-else></div>
</template>

<script>
// Note: This component is rendered outside of the <app/> node

import store from '@/store'

export default {
  name: 'CommentMentions',

  components: {},

  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    },

    prefix: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      selectedIndex: 0
    }
  },

  computed: {
    hasItems() {
      return this.items.length > 0
    },

    isDark() {
      return store.state.themeStore.isDark
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    }
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.name })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.v-list-item {
  min-height: 32px;
}
.c-active {
  background-color: var(--c-accent-light);
}
.c-active::before {
  opacity: 0.24 !important;
}
</style>
