import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

export class TooltipPlugin {
  static install(Vue, _options) {
    Vue.use(FloatingVue, {
      themes: {
        overflow: {
          $extend: 'tooltip'
          // Other options (see the 'Global options' section)
        }
      }
    })
    FloatingVue.options.themes.tooltip.disabled = false
    FloatingVue.options.themes.overflow.disabled = false
  }
}
