<template>
  <CardPanel
    :items="loading ? skeletonItems : items"
    :dense="dense"
    :draggable="draggable"
    drag-type="content"
    :loading="loading"
    :filters="contentFilters"
    :layouts="layouts"
    :preference="preference"
    :sorts="contentSortOptions"
    :title="title"
    :tree-attribute="treeAttribute"
    :width="width"
  >
    <template #card="props">
      <ContentCardSkeleton v-if="loading"/>
      <ContentCard
        v-else
        :item="props.item"
        @view:item="$emit('view:item', props.item)"
        @view:parent="$emit('view:parent', props.item.parent)"
        @share="$emit('share', props.item)"
      />
    </template>
  </CardPanel>
</template>

<script>
import { dummyItems } from '@/components/content/dummyItems.js'

// content components
import ContentCard from '@/components/content/ContentCard'
import ContentCardSkeleton from '@/components/content/ContentCardSkeleton'
import CardPanel from '@/components/base/CardPanel'
import contentMixin from '@/components/content/contentMixin.js'

export default {
  name: 'ContentPanel',

  components: {
    CardPanel,
    ContentCard,
    ContentCardSkeleton
  },

  mixins: [contentMixin],

  props: {
    items: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    draggable: {
      type: Boolean,
      required: false,
      default: false
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'carousel', 'tree']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    preference: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    treeAttribute: {
      type: String,
      required: false,
      default: 'topic'
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      skeletonItems: dummyItems(6)
    }
  },

  computed: {
    contentFilters() {
      return this.contentMixin_filters
    },

    contentSortOptions() {
      return this.draggable
        ? [
            ...this.contentMixin_sorts,
            {
              text: this.$t('ui.sortOptions.sortByAsIs'),
              value: 'sortByAsIs'
            }
          ]
        : this.contentMixin_sorts
    }
  },

  created: function () {},

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
