<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container class="c-form-fields py-0" fluid>
      <v-row>
        <!-- name -->
        <v-col v-if="isAnonymous" cols="12" md="6">
          <v-text-field
            ref="name"
            v-model="commentName"
            autocomplete="name"
            clearable
            :disabled="alert"
            filled
            prepend-icon="mdi-account"
            :rules="nameRules"
            :counter="25"
            :label="$t('comment.details.name')"
            required
          />
        </v-col>
        <!-- email -->
        <v-col v-if="isAnonymous" cols="12" md="6">
          <v-text-field
            ref="email"
            v-model="commentEmail"
            clearable
            :disabled="alert"
            filled
            prepend-icon="mdi-at"
            :rules="emailRules"
            :label="$t('comment.details.email')"
            required
          />
        </v-col>
        <!-- body -->
        <v-col class="d-flex flex-wrap px-0" cols="12">
          <RichTextEditor
            v-if="rich"
            v-model="commentBody"
            :counter="maxLength"
          />
          <v-textarea
            v-else
            v-model="commentBody"
            class="c-plain-editor"
            autocomplete
            autofocus
            auto-grow
            clearable
            :disabled="alert"
            filled
            :placeholder="$t('comment.placeholder')"
            :rules="activeRules"
            :label="$t('comment.details.body')"
            :counter="maxLength"
            required
            rows="3"
            outlined
            @click:clear="resetForm"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      v-model="alert"
      class="mt-4"
      dense
      dismissible
      type="error"
    >
      {{ error }}
    </v-alert>
    <div class="c-form-actions d-flex mt-2">
      <v-spacer></v-spacer>
      <v-slide-x-reverse-transition>
        <v-btn
          v-show="!valid"
          class="my-0"
          icon
          @click="resetForm"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
      <v-btn
        class="ml-2"
        rounded
        small
        text
        @click="onCancel()"
      >
        {{ $t('ui.cancel') }}
      </v-btn>
      <v-btn
        class="white--text ml-2"
        color="green lighten-1"
        :disabled="!valid || !isDirty"
        rounded
        small
        type="submit"
        @click="onSubmit($event)"
      >
        {{ updateMode ? $t('comment.action.update') : $t('comment.action.submit') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
// https://rmirabelle.medium.com/fixing-vuetifys-form-validation-3a5781ea43fe
import RichTextEditor from '@/components/base/RichTextEditor'

export default {
  name: 'CommentForm',

  components: {
    RichTextEditor
  },

  props: {
    body: {
      type: String,
      required: false,
      default: ''
    },

    maxLength: {
      type: Number,
      required: false,
      default: 400
    },

    outlined: {
      type: Boolean,
      required: false,
      default: true
    },

    rich: {
      type: Boolean,
      required: false,
      default: true
    },

    updateMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      alert: false,
      valid: true,
      error: '',
      activeRules: [],
      commentName: '',
      commentEmail: '',
      commentBody: this.body || '',
      commentRules: [
        (v) => !!v.trim() || 'Comment is required',
        (v) =>
          (v || '').length <= this.maxLength ||
          `Comment must be less than ${this.maxLength} characters.`
      ],
      nameRules: [
        (v) => !!v.trim() || 'Name is required',
        (v) => (v || '').length <= 25 || 'Name must be less than 25 characters.'
      ],
      emailRules: [
        (v) => !!v.trim() || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail syntax is not valid.'
      ]
    }
  },

  computed: {
    avatarURL() {
      return this.$auth.getUserAvatar()
    },

    isAnonymous() {
      return !this.isAuthenticated
    },

    isAuthenticated() {
      return this.$auth.isAuthenticated()
    },

    isDirty() {
      return this.commentBody?.length > 0
    }
  },

  mounted: function () {
    this.commentBody = this.body || ''
  },

  methods: {
    async onSubmit() {
      this.activeRules = this.commentRules
      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.$emit('submit', this.commentBody.trim())
          this.resetForm()
        }
      })
    },

    onCancel() {
      this.resetForm()
      this.$emit('cancel')
    },

    resetForm() {
      this.$refs.form.reset()
      this.alert = false
      this.error = ''
      this.valid = true
      this.commentBody = ''
      this.activeRules = []
    }
  }
}
</script>

<style lang="css" scoped>
.c-plain-editor :deep(.v-text-field__details) {
  padding: 0 0;
}
</style>
