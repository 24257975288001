export default {
  /* common mutations */

  syncState(state, newState) {
    state.channels = newState.channels || []
  },

  /* specific mutations */

  setActiveChannel: (state, channel) => {
    state.activeChannel = channel
  },

  setChannels(state, channels) {
    state.channels = [...channels]
  },

  setChannel(state, channel) {
    // check if the channel is already in the state
    const channelId = channel.id
    const index = state.channels.findIndex((c) => c.id === channelId)

    index < 0
      ? state.channels.push(channel) // append
      : state.channels.splice(index, 1, channel) // replace
  },

  setFetched(state, isFetched) {
    state.fetched = isFetched
  },

  setLoading(state, { loading, promise = null }) {
    state.loading = loading
    state.promise = promise
  }
}
