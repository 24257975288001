var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RecycleScroller",
    _vm._b(
      {
        ref: "scroller",
        attrs: {
          items: _vm.itemsWithSize,
          "min-item-size": _vm.minItemSize,
          direction: _vm.direction,
          "key-field": "id",
          "list-tag": _vm.listTag,
          "item-tag": _vm.itemTag,
        },
        on: { resize: _vm.onScrollerResize, visible: _vm.onScrollerVisible },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ item: itemWithSize, index, active }) {
                return [
                  _vm._t("default", null, null, {
                    item: itemWithSize.item,
                    index,
                    active,
                    itemWithSize,
                  }),
                ]
              },
            },
            _vm.$slots.before
              ? {
                  key: "before",
                  fn: function () {
                    return [_vm._t("before")]
                  },
                  proxy: true,
                }
              : null,
            _vm.$slots.after
              ? {
                  key: "after",
                  fn: function () {
                    return [_vm._t("after")]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "empty",
              fn: function () {
                return [_vm._t("empty")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "RecycleScroller",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }