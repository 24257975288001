/*
 * Relies on consuming component to define:
 *   1. sortOption - key used to select the specific sort function
 *   2. resultSet - array of items to be sorted
 */

export default {
  data: function () {
    return {}
  },

  methods: {
    /* general sort utilities */

    sortMixin_sortItems(sortName, items) {
      const sortFunction = this.sortMixin_getSortFunction(sortName)
      return sortName && sortName !== 'sortByAsIs' ? items.sort(sortFunction) : items
    },

    sortMixin_getSortFunction(sortName) {
      const sortFunctions = {
        sortByAsIs: this.sortByAsIs,
        sortByLastActivityAsc: this.sortByLastActivityAsc, // old to new
        sortByLastActivityDesc: this.sortByLastActivityDesc, // new to old
        sortByNameAsc: this.sortByNameAsc,
        sortByNameDesc: this.sortByNameDesc,
        sortByPosterAsc: this.sortByPosterAsc,
        sortByPosterDesc: this.sortByPosterDesc,
        sortByPrecedenceAsc: this.sortByPrecedenceAsc,
        sortByPrecedenceDesc: this.sortByPrecedenceDesc,
        sortByPublishDateAsc: this.sortByPublishDateAsc, // old to new
        sortByPublishDateDesc: this.sortByPublishDateDesc, // new to old
        sortByRankAsc: this.sortByRankAsc,
        sortByRankDesc: this.sortByRankDesc,
        sortByTitleAsc: this.sortByTitleAsc,
        sortByTitleDesc: this.sortByTitleDesc
      }
      return sortFunctions[sortName] || this.sortByAsIs
    },

    sortByAsIs(_stringA, _stringB) {
      return 0
    },

    sortByDateAsc(dateA, dateB) {
      // convert dates to milliseconds since epoch
      const msDateA = new Date(dateA || 0)
      const msDateB = new Date(dateB || 0)
      // oldest first (old -> new)
      return msDateA - msDateB
    },

    sortByDateDesc(dateA, dateB) {
      // newest first (new -> old)
      return this.sortByDateAsc(dateB, dateA)
    },

    sortByStringAsc(stringA, stringB) {
      if (stringA < stringB) {
        return -1
      } else if (stringA > stringB) {
        return 1
      } else {
        return 0
      }
    },

    sortByStringDesc(stringA, stringB) {
      return this.sortByStringAsc(stringB, stringA)
    },

    /* field specific sorts */

    sortByLastActivityDateAsc(a, b) {
      return this.sortByDateAsc(a.lastActivity, b.lastActivity)
    },

    sortByLastActivityDateDesc(a, b) {
      return this.sortByLastActivityDateAsc(b, a)
    },

    sortByNameAsc(a, b) {
      const entityA = a.name ? a.name.toUpperCase : (a.lastName + a.firstName)?.toUpperCase() || ''
      const entityB = b.name ? b.name.toUpperCase : (b.lastName + b.firstName)?.toUpperCase() || ''
      return this.sortByStringAsc(entityA, entityB)
    },

    sortByNameDesc(a, b) {
      return this.sortByNameAsc(b, a)
    },

    sortByPosterAsc(a, b) {
      const posterA = a.postedBy?.toUpperCase() || ''
      const posterB = b.postedBy?.toUpperCase() || ''
      return this.sortByStringAsc(posterA, posterB)
    },

    sortByPosterDesc(a, b) {
      return this.sortByPosterAsc(b, a)
    },

    sortByPrecedenceAsc(a, b) {
      const precedenceA = parseInt(a.precedence || '')
      const precedenceB = parseInt(b.precedence || '')
      return precedenceA - precedenceB
    },

    sortByPrecedenceDesc(a, b) {
      return this.sortByPrecedenceAsc(b, a)
    },

    sortByPublishDateAsc(a, b) {
      return this.sortByDateAsc(a.publishedAt, b.publishedAt)
    },

    sortByPublishDateDesc(a, b) {
      return this.sortByPublishDateAsc(b, a)
    },

    sortByRankAsc(a, b) {
      const rankA = a.orderRank || ''
      const rankB = b.orderRank || ''
      return this.sortByStringAsc(rankA, rankB)
    },

    sortByRankDesc(a, b) {
      return this.sortByRankAsc(b, a)
    },

    sortByTitleAsc(a, b) {
      const titleA = a.title?.toUpperCase() || ''
      const titleB = b.title?.toUpperCase() || ''
      return this.sortByStringAsc(titleA, titleB)
    },

    sortByTitleDesc(a, b) {
      return this.sortByTitleAsc(b, a)
    }
  }
}
