<template>
  <v-card class="c-card py-0 rounded-xl" outlined>
    <v-list-item class="px-2">
      <v-list-item-avatar class="my-0">
        <v-img v-if="hasAvatar" :src="authorAvatar" />
        <v-icon v-else color="primary" large> mdi-account-circle-outline </v-icon>
      </v-list-item-avatar>

      <v-list-item-content class="py-0">
        <v-list-item-title>
          {{ authorName }}
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ ago }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text class="text-body-1 pa-2">
      <CommentForm
        v-if="editMode"
        :body="commentBody"
        :outlined="false"
        :rich="rich"
        :update-mode="true"
        @cancel="onCancel()"
        @submit="onUpdate($event)"
      />
      <CommentBody
        v-else
        :body="commentBody"
        :rich="rich" />
    </v-card-text>

    <v-card-actions class="pt-0">
      <v-btn :disabled="commentReplyCount === 0" fab icon small @click="onReplies">
        <v-icon class="primary--text"> mdi-chat-outline </v-icon>
        <span>{{ commentReplyCount }}</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn v-if="editable" fab icon small @click="onEdit">
        <v-icon class="orange--text"> mdi-pencil </v-icon>
      </v-btn>

      <v-btn v-if="editable" fab icon small @click="onDelete">
        <v-icon class="red--text"> mdi-delete </v-icon>
      </v-btn>

      <v-btn fab icon small @click="onReply">
        <v-icon class="blue--text"> mdi-reply </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CommentBody from '@/components/comments/CommentBody'
import CommentForm from '@/components/comments/CommentForm'

export default {
  name: 'CommentCard',

  components: {
    CommentBody,
    CommentForm
  },

  model: {
    prop: 'body',
    event: 'update:body'
  },

  props: {
    body: {
      type: [String, Object],
      required: false,
      default: () => null
    },

    comment: {
      type: Object,
      required: true
    },

    rich: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {
      commentBody: null,
      editMode: false,
      collapsed: true
    }
  },

  computed: {
    authorName() {
      return this.comment.author.name || this.$t('comment.anonymous')
    },

    authorAvatar() {
      return this.comment.author.avatar
    },

    hasAvatar() {
      return !!this.comment.author.avatar
    },

    commentReplyCount() {
      return this.comment.replies?.length || 0
    },

    editable() {
      return this.$auth.getUserId() === this.comment.author.email
    },

    removable() {
      return this.editable && this.comment.replies?.length === 0
    },

    ago() {
      return this.timeAgo(this.comment.createdAt)
    }
  },

  watch: {
    comment: {
      immediate: true,
      handler: function (newComment, _oldComment) {
        this.commentBody = newComment.body
      },
      deep: true
    }
  },

  methods: {
    onCancel() {
      this.editMode = false
      this.$emit('cancel')
    },

    onEdit() {
      this.editMode = true
    },

    onDelete() {
      this.$emit('delete')
    },

    onReply() {
      this.$emit('reply')
    },

    onReplies() {
      this.$emit('replies')
    },

    onUpdate(updatedBody) {
      this.editMode = false
      this.commentBody = updatedBody
      this.$emit('update:body', updatedBody)
      this.$emit('update', updatedBody)
    },

    timeAgo(time) {
      const date = time instanceof Date ? time : new Date(time)
      const rtf = new Intl.RelativeTimeFormat('en')
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
        now: 0
      }
      const delta = (date.getTime() - Date.now()) / 1000
      const secondsElapsed = Math.abs(delta)

      // special cases
      if (secondsElapsed < 30) {
        return this.$t('comment.justNow')
      }

      // determine unit of time and delta for that unit
      for (let key in ranges) {
        if (ranges[key] < secondsElapsed) {
          const delta = secondsElapsed / ranges[key]
          // convert unit and delta to appropriate catch phrase
          return rtf.format(Math.round(-1 * delta), key)
        }
      }
    }
  }
}
</script>

<style lang="css" scoped></style>
