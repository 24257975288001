import { useStore } from '@/store'

// import { tenantService } from '@/services/tenantService'

import { userService } from '@/services/userService'
import { AuthorizationError, AuthorizationRequiredError } from '@/config/authConfig.js'
import { i18n } from '@/plugins/localePlugin.js'

class AuthzService {
  constructor() {
    // initialize services
    this.store = useStore()
    this.userService = userService
    this.$t = (msg, ...args) => i18n.t(msg, ...args)

    // initialize context
    this.authorized = false
    this.tenant = null
  }

  /*
   * Service Management Methods
   */

  async initialize(tenant) {
    console.debug('[authzService]: Initialization begins...')
    this.tenant = tenant
  }

  /* manage authorization */

  async authorize(userId) {
    this.authorized = await this.checkAndGrantAuthorization(userId)

    // throw a special exception when user requires authorization
    if (!this.authorized) {
      const errMsg = `User ${userId} is not authorized for tenant ${this.tenant.key}`
      throw new AuthorizationRequiredError(errMsg)
    }
  }

  async checkAndGrantAuthorization(userId) {
    console.debug(`[authService]: Authorizing ${userId}...`)
    this.authorized = false
    try {
      // find user in repository
      // FIXME: check for existence instead
      const user = await this.fetchUser(userId)

      if (user) {
        // found: user is already authorized
        console.debug(`[authService]: UserId ${userId} is already authorized.`)
        this.store.dispatch('reflectUser', { user })
        this.authorized = true
        return true
      } else {
        // not found: user requires authorization
        console.debug(`[authService]: UserId ${userId} requires authorization.`)
        if (this.isPreApproved(userId)) {
          // user is authorized (pre-approved)
          await this.addUser(userId)
          this.store.dispatch('userStore/updateUser', { user: { userId } })
          this.authorized = true
          console.debug(`[authService]: UserId ${userId} has been authorized.`)
          return true
        } else {
          // user is not authorized
          this.authorized = false
          return false
        }
      }
    } catch (error) {
      console.error(`[authService]: Unable to authorize ${userId}. ${error}`)
      this.authorized = false
      throw new AuthorizationError(error)
    }
  }

  clearAuthorization() {
    this.authorized = false
  }

  /* manage user */

  async fetchUser(userId) {
    try {
      const user = await this.userService.fetchUser(userId)
      return user
    } catch (error) {
      console.error('[authService]: Unable to fetch user record.', error)
      throw error
    }
  }

  async verifyUser(userId) {
    try {
      const exists = await this.userService.verifyUser(userId)
      return exists
    } catch (error) {
      console.error('[authService]: Unable to verify user.', error)
      throw error
    }
  }

  async addUser(userId) {
    try {
      const newUser = {
        userId: userId
      }
      const response = await this.userService.addUser(newUser)

      if (response.added) {
        return newUser
      } else {
        throw response.message
      }
    } catch (error) {
      console.error('[authService]: Unable to add user.', error)
      throw error
    }
  }

  getUserDomain(userid) {
    const address = userid.split('@').pop() || ''
    // const domain = parser(address).domain // import tld-extract
    return address
  }

  /* authorization status */

  isDomainAuthorized(domain) {
    const authorizedDomains = this.tenant
      ? [this.tenant.domain, ...this.tenant.authorizedDomains]
      : []

    console.debug('[authService]: user domain=', domain)
    console.debug('[authService]: authorized domains=', authorizedDomains)

    return authorizedDomains.includes(domain) || authorizedDomains.includes('*')
  }

  isPreApproved(userId) {
    // does user have a valid invitation to join?
    // TODO: add invitation checking logic
    const hasInvitation = false

    // does user domain match an authorized domain for this tenant?
    const userDomain = this.getUserDomain(userId)
    const isUserDomainAuthorized = this.isDomainAuthorized(userDomain)

    if (isUserDomainAuthorized)
      console.info(`[authService]: User ${userId} is a member of an authorizedDomain.`)

    return hasInvitation || isUserDomainAuthorized
  }

  isUserAuthorized(_userId) {
    return this.authorized
  }
}

export default AuthzService
