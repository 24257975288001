<template>
  <div
    ref="toolbar"
    class="c-toolbar d-flex flex-wrap"
  >
    <RichTextMenuButton
      v-for="(button, index) in enabledMenuButtons"
      :key="button.name === 'divider' ? index : button.name"
      :icon="button.icon"
      :name="button.name"
      :active="button.isActive()"
      :disabled="button.isDisabled()"
      @click="button.action()"
    />

    <v-spacer v-if="!showExtendedMenu" />
    <v-divider v-else class="mx-2" vertical />

    <RichTextMenuButton
      :key="resizeButton.name"
      :icon="resizeButton.icon"
      :name="resizeButton.name"
      :active="resizeButton.isActive()"
      :disabled="resizeButton.isDisabled()"
      @click="resizeButton.action()"
    />

  </div>
</template>

<script>
// import { EditorMenuBar } from '@tiptap/vue-2'
import RichTextMenuButton from '@/components/base/RichTextMenuButton'

export default {
  name: 'RichTextToolbar',

  components: {
    RichTextMenuButton
  },

  props: {
    editor: {
      type: Object,
      required: true
    },

    config: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      observer: null,
      extensionWidth: 400,
      showExtendedMenu: false,
      expanded: false,

      /* buttons */

      dividerButton: {
        name: 'divider',
        icon: '',
        isActive: () => false,
        isDisabled: () => false,
        isEnabled: () => this.showExtendedMenu,
        action: () => 'divider'
      },

      formatButtons: [
        {
          name: 'bold',
          icon: 'mdi-format-bold',
          isActive: () => this.editor.isActive('bold'),
          isDisabled: () => !this.editor.can().chain().focus().toggleBold().run(),
          isEnabled: () => true,
          action: () => this.editor.chain().focus().toggleBold().run()
        },
        {
          name: 'italic',
          icon: 'mdi-format-italic',
          isActive: () => this.editor.isActive('italic'),
          isDisabled: () => !this.editor.can().chain().focus().toggleItalic().run(),
          isEnabled: () => true,
          action: () => this.editor.chain().focus().toggleItalic().run()
        },
        {
          name: 'code',
          icon: 'mdi-code-tags',
          isActive: () => this.editor.isActive('code'),
          isDisabled: () => !this.editor.can().chain().focus().toggleCode().run(),
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleCode().run()
        },
        {
          name: 'strike',
          icon: 'mdi-format-strikethrough-variant',
          isActive: () => this.editor.isActive('strike'),
          isDisabled: () => !this.editor.can().chain().focus().toggleStrike().run(),
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleStrike().run()
        },
        {
          name: 'underline',
          icon: 'mdi-format-underline',
          isActive: () => this.editor.isActive('underline'),
          isDisabled: () => !this.editor.can().chain().focus().toggleUnderline().run(),
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleUnderline().run()
        },
        {
          name: 'unsetSelected',
          icon: 'mdi-format-clear',
          isActive: () => false,
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().unsetAllMarks().run()
        }
      ],

      alignButtons: [
        {
          name: 'left',
          icon: 'mdi-format-align-left',
          isActive: () => this.editor.isActive({ textAlign: 'left' }),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().setTextAlign('left').run()
        },
        {
          name: 'centre',
          icon: 'mdi-format-align-center',
          isActive: () => this.editor.isActive({ textAlign: 'center' }),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().setTextAlign('center').run()
        },
        {
          name: 'right',
          icon: 'mdi-format-align-right',
          isActive: () => this.editor.isActive({ textAlign: 'right' }),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().setTextAlign('right').run()
        },
        {
          name: 'justify',
          icon: 'mdi-format-align-justify',
          isActive: () => this.editor.isActive({ textAlign: 'justify' }),
          isDisabled: () => false,
          isEnabled: () => false,
          action: () => this.editor.chain().focus().setTextAlign('justify').run()
        }
      ],

      listButtons: [
        {
          name: 'ordered',
          icon: 'mdi-format-list-numbered',
          isActive: () => this.editor.isActive('orderedList'),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleOrderedList().run()
        },
        {
          name: 'bullet',
          icon: 'mdi-format-list-bulleted',
          isActive: () => this.editor.isActive('bulletList'),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleBulletList().run()
        }
      ],

      doButtons: [
        {
          name: 'undo',
          icon: 'mdi-undo',
          isActive: () => false,
          isDisabled: () => !this.editor.can().chain().focus().undo().run(),
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().undo().run()
        },
        {
          name: 'redo',
          icon: 'mdi-redo',
          isActive: () => false,
          isDisabled: () => !this.editor.can().chain().focus().redo().run(),
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().redo().run()
        }
      ],

      headingButtons: [
        {
          name: 'h1',
          icon: 'mdi-format-header-1',
          isActive: () => this.editor.isActive('heading', { level: 1 }),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run()
        },
        {
          name: 'h2',
          icon: 'mdi-format-header-2',
          isActive: () => this.editor.isActive('heading', { level: 2 }),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run()
        }
      ],

      blockButtons: [
        {
          name: 'paragraph',
          icon: 'mdi-format-paragraph',
          isActive: () => this.editor.isActive('paragraph'),
          isDisabled: () => false,
          isEnabled: () => false,
          action: () => this.editor.chain().focus().setParagraph().run()
        },
        {
          name: 'blockcode',
          icon: 'mdi-code-braces',
          isActive: () => this.editor.isActive('codeBlock'),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleCodeBlock().run()
        },
        {
          name: 'blockquote',
          icon: 'mdi-format-quote-close',
          isActive: () => this.editor.isActive('blockquote'),
          isDisabled: () => false,
          isEnabled: () => this.showExtendedMenu,
          action: () => this.editor.chain().focus().toggleBlockquote().run()
        }
      ],

      mediaButtons: [
        {
          name: 'image',
          icon: 'mdi-image',
          isActive: () => false,
          isDisabled: () => false,
          isEnabled: () => true,
          action: () => this.addImage()
        },
        {
          name: 'video',
          icon: 'mdi-video-vintage',
          isActive: () => false,
          isDisabled: () => false,
          isEnabled: () => true,
          action: () => this.addVideo()
        }
      ]
    }
  },

  computed: {
    menuButtons() {
      return [
        ...this.doButtons,
        this.dividerButton,
        ...this.formatButtons,
        this.dividerButton,
        ...this.alignButtons,
        this.dividerButton,
        ...this.listButtons,
        this.dividerButton,
        ...this.headingButtons,
        this.dividerButton,
        ...this.blockButtons,
        this.dividerButton,
        ...this.mediaButtons
      ]
    },

    enabledMenuButtons() {
      return this.menuButtons.filter((button) => button.isEnabled())
    },

    resizeButton() {
      return {
        name: 'resize',
        icon: this.expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand',
        isActive: () => false,
        isDisabled: () => false,
        isEnabled: () => true,
        action: () => this.onResizeEditor()
      }
    }
  },

  created: function () {
    this.observer = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect
      this.showExtendedMenu = rect.width > this.extensionWidth
    })
  },

  mounted: function () {
    this.observer.observe(this.$refs.toolbar)
  },

  beforeDestroy: function () {
    if (this.observer) this.observer.disconnect()
  },

  methods: {
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor
          .chain()
          .focus()
          .setImage({
            src: url,
            alt: ''
          })
          .run()
      }
    },

    addVideo() {
      const url = prompt(this.$t('editor.addVideo'))

      if (url) {
        this.editor.commands.setYoutubeVideo({
          src: url,
          width: '100%',
          height: '100%'
          // width: Math.max(320, parseInt(this.width, 10)) || 640,
          // height: Math.max(180, parseInt(this.height, 10)) || 480
        })
      }
    },

    addTopic() {
      const topic = window.prompt('Topic')

      this.editor
        .chain()
        .focus()
        .insertContent({
          type: 'mentionTopic',
          attrs: {
            id: topic
          }
        })
        .insertContent(' ')
        .run()
    },

    onResizeEditor() {
      this.expanded = !this.expanded
      this.$emit('expand', this.expanded)
    },

    cancel() {
      this.editor.chain().focus().clearContent().run()
    }
  }
}
</script>

<style lang="css" scoped>
.c-toolbar {
  row-gap: 4px;
}
</style>
