import { APP_NAME } from '@/config/appConfig'

const META_DATASET_PREFIX = 'lumenii-meta'

export default {
  computed: {
    metaMixin_loaded() {
      return this.metaTags.loaded
    }
  },

  watch: {
    metaMixin_loaded: {
      handler: function (loaded, _oldValue) {
        if (loaded) {
          document.title = this.metaTags.docTitle
          this.metaMixin_createTags(this.metaTags.meta).forEach((tag) =>
            document.head.appendChild(tag)
          )
        }
      }
    }
  },

  beforeDestroy: function () {
    document.title = APP_NAME
    this.metaMixin_removeTags()
  },

  methods: {
    metaMixin_createTags(meta) {
      return meta.map((tagDef) => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key])
        })

        tag.setAttribute(`data-${META_DATASET_PREFIX}`, '')

        return tag
      })
    },

    metaMixin_removeTags() {
      Array.from(document.querySelectorAll(`[data-${META_DATASET_PREFIX}]`)).map((el) =>
        el.parentNode.removeChild(el)
      )
    }
  }
}
