<template>
  <SectionPanel
    :colour="colour"
    :count="entriesCount"
    :loading="loading"
    :title="title"
  >
    <CalendarViewer
      :events="entries"
      period="day"
      @update:period="$emit('update:peried', $event)"
    />
  </SectionPanel>
</template>

<script>
import CalendarViewer from '@/components/calendar/CalendarViewer'
import SectionPanel from '@/components/base/SectionPanel'

export default {
  name: 'CalendarPanel',

  components: {
    CalendarViewer,
    SectionPanel
  },

  props: {
    entries: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    entriesCount() {
      return this.entries.length
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
