var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "c-card d-flex flex-column",
      class: { "c-selected": _vm.active },
      attrs: { text: "" },
    },
    [
      _c(
        "div",
        { staticClass: "c-flex-img" },
        [
          _c(
            "v-img",
            {
              staticClass: "white--text c-clickable c-card-image",
              attrs: {
                "aspect-ratio": 16 / 9,
                gradient: _vm.cardGradient,
                src: _vm.thumbnail,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("view:channel")
                },
              },
            },
            [
              _c("v-card-title", { staticClass: "c-card-title px-4 py-2" }, [
                _vm._v(" " + _vm._s(_vm.channel.title) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.channel.hideOverview
        ? _c("v-card-title", { staticClass: "py-3 grey--text" }, [
            _vm._v(
              " " +
                _vm._s(_vm.displayName ? _vm.$t("post.by") : "") +
                " " +
                _vm._s(_vm.displayName) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pb-2 text-left" },
        [_c("ClampedText", [_vm._v(" " + _vm._s(_vm.channel.abstract) + " ")])],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-card-actions",
        { staticClass: "px-4" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("view:channel")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("channel.explore")) + " ")]
          ),
          _c("v-spacer"),
          _vm.$actions.share
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("tooltip.cardAction.share"),
                      expression: "$t('tooltip.cardAction.share')",
                    },
                  ],
                  attrs: { color: "accent", icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("share")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(" mdi-share-variant "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.cardAction.info"),
                  expression: "$t('tooltip.cardAction.info')",
                },
              ],
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.revealBack = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-information "),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.cardAction.favourite"),
                  expression: "$t('tooltip.cardAction.favourite')",
                },
              ],
              attrs: { color: "red", icon: "", large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleFavourite()
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isFavourite ? "mdi-heart" : "mdi-heart-outline"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-scroll-x-reverse-transition",
        [
          _c("ChannelCardBack", {
            attrs: { author: _vm.channel.author, sponsor: _vm.channel.sponsor },
            model: {
              value: _vm.revealBack,
              callback: function ($$v) {
                _vm.revealBack = $$v
              },
              expression: "revealBack",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }