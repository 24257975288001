var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rich
    ? _c("RichTextEditor", { attrs: { readonly: "", value: _vm.body } })
    : _c("div", {
        staticClass: "c-body",
        domProps: { innerHTML: _vm._s(_vm.body) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }