<template>
  <v-btn
    v-if="overlapMode === 'stack'"
    v-tooltip="$t('tooltip.calendar.calendarMode')"
    color="icon"
    icon
    @click="toggleMode"
  >
    <v-icon x-large> mdi-view-column </v-icon>
  </v-btn>
  <v-btn
    v-else
    v-tooltip="$t('tooltip.calendar.calendarMode')"
    color="icon"
    icon
    @click="toggleMode"
  >
    <v-icon x-large> mdi-checkbox-multiple-blank </v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CalendarModeSwitcher',

  components: {},

  data: function () {
    return {}
  },

  computed: {
    overlapMode: {
      get() {
        return this.$store.state.calendarStore.overlapMode
      },
      set(overlapMode) {
        this.updateOverlapMode({ overlapMode })
      }
    }
  },

  methods: {
    ...mapActions('calendarStore', ['updateOverlapMode']),

    toggleMode() {
      if (this.overlapMode === 'stack') {
        this.overlapMode = 'column' // reactive!
        this.$emit('update:overlap', false)
      } else {
        this.overlapMode = 'stack'
        this.$emit('update:overlap', true)
      }
    }
  }
}
</script>
