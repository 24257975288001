<template>
  <!-- note: v-toolbar applies -12px margin to first/last child (buttons only) -->
  <v-toolbar
    ref="toolbar"
    class="c-layout-toolbar mb-0"
    :class="{ 'c-mobile': isReallyMobile, 'c-wide': wide }"
    color="secondary"
    dense
    flat
    tile
  >
    <!-- search -->
    <v-text-field
      v-model="searchInput"
      v-tooltip="$t('tooltip.layoutBar.search')"
      class="c-search"
      clearable
      dense
      full-width
      hide-details
      outlined
      :placeholder="$t('ui.search')"
      prepend-inner-icon="mdi-magnify"
      single-line
      @paste="onPaste"
      @input="debounceSearch"
      @click.stop=""
    />

    <!-- title -->
    <v-toolbar-title v-if="title" class="c-title">
      {{ title }}
    </v-toolbar-title>

    <!-- control buttons -->
    <div class="c-controls d-flex align-center justify-end">
      <!-- expand/collapse for tree view sections -->
      <v-btn
        v-show="isTree && !hideControls"
        v-tooltip="$t('tooltip.layoutBar.tree')"
        class="c-btn"
        icon
        @click.stop="toggleTree"
      >
        <v-icon large>
          {{ isTreeExpanded ? 'mdi-arrow-collapse-vertical' : 'mdi-arrow-expand-vertical' }}
        </v-icon>
      </v-btn>

      <!-- sort -->
      <v-menu
        v-if="!hideControls && sorts.length > 0"
        bottom
        offset-y
        :open-on-click="true"
        :close-on-click="true"
        :close-on-content-click="true"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-tooltip="$t('tooltip.layoutBar.sort')"
            class="c-btn"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large> mdi-sort </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
            v-model="currentSortOptionIndex"
            color="accent"
            mandatory
          >
            <v-list-item
              v-for="s in sorts"
              :key="s.value"
              @click="onSort(s.value)"
            >
              <v-list-item-title>{{ s.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- filters -->
      <v-menu
        v-if="hasFilters && !hideControls"
        v-model="showFilters"
        content-class="c-filter-menu"
        bottom
        offset-y
        :open-on-click="true"
        :close-on-click="true"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-tooltip="$t('tooltip.layoutBar.filter')"
            class="c-btn"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :class="{ 'accent--text': anyFiltersActive }" large> mdi-filter </v-icon>
          </v-btn>
        </template>
        <v-list class="c-filter-list">
          <v-list-item v-for="filter in filters" :key="filter.field">
            <v-select
              v-model="selectedFilters[filter.field]"
              class="c-selector mx-2 my-2"
              chips
              clearable
              color="accent"
              deletable-chips
              :dense="isMobile"
              hide-details
              item-color="accent"
              :items="extractFilterValues(items, filter)"
              :label="filter.label"
              :menu-props="{
                contentClass: 'c-selector-menu',
                offsetY: true,
                closeOnClick: true,
                closeOnContentClick: false
              }"
              multiple
              outlined
              @change="onFilter"
            />
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- layouts -->
      <div v-if="hasLayouts && dial" class="c-layouts">
        <LayoutSelector
          v-model="displayLayout"
          :layouts="layouts"
          @status="hideControls = $event"
          @update:layout="$emit('update:layout', $event)"
        />
      </div>
      <div v-if="hasLayouts && !dial" class="c-layouts">
        <v-btn
          v-tooltip="$t('tooltip.layoutBar.layouts')"
          class="c-btn"
          icon
          @click.stop="changeLayout(otherLayout)"
        >
          <v-icon large>
            {{ layoutIcon[otherLayout] }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'
import sortMixin from '@/mixins/sortMixin.js'
import LayoutSelector from '@/components/base/LayoutSelector'

export default {
  name: 'LayoutToolbar',

  components: {
    LayoutSelector
  },

  mixins: [mobileMixin, sortMixin],

  model: {
    prop: 'layout',
    event: 'update:layout'
  },

  props: {
    expand: {
      type: Boolean,
      required: false,
      default: true
    },

    filters: {
      type: Array,
      required: false,
      default: () => []
    },

    items: {
      type: Array,
      required: true
    },

    layout: {
      type: String,
      required: false,
      default: 'grid'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid']
    },

    reset: {
      type: Number,
      required: false,
      default: 0
    },

    scrollTarget: {
      type: String,
      required: false,
      default: ''
    },

    sort: {
      type: String,
      required: false,
      default: 'sortByPublishDateDesc'
    },

    sorts: {
      type: Array,
      required: false,
      default: () => []
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    wide: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      activated: false,
      // layout
      displayLayout: this.layout,
      hideControls: false,
      isTreeExpanded: this.expand,
      //scrolling
      prevScrollPos: 0,
      // search
      debounceTimer: null,
      searchInput: '',
      searchTerms: '',
      savedSearch: '',
      // filters
      showFilters: false,
      selectedFilters: {},
      savedFilters: {},
      // sorting
      currentSortOptionIndex: this.sorts.findIndex((option) => option.value === this.sort),
      currentSortOptionName: this.sort
    }
  },

  computed: {
    /* state variables */

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* layout */

    hasLayouts() {
      return this.isReallyMobile ? false : this.layouts?.length > 1
    },

    dial() {
      return this.hasLayouts && this.layouts?.length > 2
    },

    isCarousel() {
      return this.displayLayout === 'carousel'
    },

    isGrid() {
      return this.displayLayout === 'grid'
    },

    isTable() {
      return this.displayLayout === 'table'
    },

    isTree() {
      return this.displayLayout === 'tree'
    },

    layoutIcon() {
      return {
        carousel: 'mdi-view-column',
        grid: 'mdi-apps',
        table: 'mdi-table',
        tree: 'mdi-file-tree'
      }
    },

    otherLayout() {
      // used to toggle layouts between two values vs using a speeddial
      return this.layouts.find((layout) => layout !== this.displayLayout)
    },

    /* filtering */

    anyFiltersActive() {
      let active = false
      for (const key in this.selectedFilters) {
        // filters are arrays of strings or single strings
        if (this.selectedFilters[key].length > 0) {
          active = true
        }
      }
      return active
    },

    hasFilters() {
      return this.filters.length > 0
    },

    /* search */

    searchTermFilters() {
      return this.searchTerms.length > 0 ? this.searchTerms.toLowerCase().split(' ') : []
    },

    /* generate result set */

    resultSet() {
      // depends on:
      // - this.items
      // - this.selectedFilters
      // - this.searchTerms
      // - this.currentSortOptionName

      if (!this.items || this.items.length === 0) return []

      let items = [...this.items]

      // apply all active filters
      if (this.anyFiltersActive) {
        for (const filter of this.filters) {
          const field = filter.field
          const filterValues = this.selectedFilters[filter.field] || []

          if (filterValues.length > 0) {
            const isMultiValued = filter.isMultiValued
            const requiresTranslation = filter.requiresTranslation
            items = items.filter((item) => {
              if (isMultiValued) {
                // check if filter values intersect with item values
                return item[field]?.length > 0
                  ? this.intersects(
                      item[field].map((value) => {
                        // field value (within the array of values) could be null
                        return value && requiresTranslation
                          ? value[this.locale] || value[this.defaultLocale]
                          : value || ''
                      }),
                      filterValues
                    )
                  : true // treat empty fields as a match
              } else {
                // check if field matches any any of the filter values
                return item[field] && filterValues.length > 0
                  ? filterValues.includes(
                      requiresTranslation
                        ? item[field][this.locale] || item[field][this.defaultLocale]
                        : item[field]
                    )
                  : true
              }
            })
          }
        }
      }

      // find items matching search terms
      if (this.searchTerms.length > 0) {
        items = items.filter((item) =>
          this.searchTermFilters.every(
            (searchTerm) =>
              item.title.toLowerCase().includes(searchTerm) ||
              item.subtitle?.toLowerCase().includes(searchTerm) ||
              item.tags?.join('-').includes(searchTerm)
          )
        )
      }

      // sort the list
      items = items.sort(this.sortMixin_getSortFunction(this.currentSortOptionName))

      return items
    }
  },

  watch: {
    resultSet: {
      immediate: true, // apply sort immediately
      handler: function (newItems, _oldItems) {
        if (newItems) {
          // could be undefined due to prop.items!
          this.$emit('results', newItems)
        }
      }
    },

    reset: {
      immediate: false, // initial values captured via data()
      handler: function (_newVal, _oldVal) {
        // reset search
        this.searchInput = ''
        this.searchTerm = ''
        // reset filters
        this.initializeFilters()
      }
    }
  },

  created: function () {
    this.initializeFilters()
  },

  mounted: function () {
    this.$nextTick(() => {
      if (this.scrollTarget) {
        this.scrollTargetElement =
          this.scrollTarget === 'window' ? window : document.querySelector(this.scrollTarget)
        if (this.scrollTargetElement) {
          this.scrollTargetElement.addEventListener('scroll', this.onScroll)
        }
      }
    })
  },

  activated: function () {
    this.activated = true
    // this.searchInput = this.savedSearch
    // this.selectedFilters = { ...this.savedFilters }

    this.prevScrollPos = window.scrollY
  },

  deactivated: function () {
    this.activated = false
    // this.savedSearch = this.searchInput
    // this.savedFilters = { ...this.selectedFilters }
  },

  beforeDestroy: function () {
    this.scrollTargetElement?.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    /* generate resultSet */

    intersects(a, b) {
      const baseSet = new Set(b)
      const intersection = a.filter((el) => baseSet.has(el))
      return intersection.length > 0
    },

    /* manage scrolling */

    onScroll() {
      if (this.activated) {
        const currentScrollPos = window.pageYOffset
        this.$refs.toolbar.$el.style.top =
          this.prevScrollPos >= currentScrollPos || currentScrollPos === 0 ? '48px' : '-48px'

        this.prevScrollPos = currentScrollPos
      }
    },

    /* manage sort */

    onSort(sortOptionName) {
      this.currentSortOptionName = sortOptionName
      this.$emit('update:sort', sortOptionName)
    },

    /* manage search */

    clearSearch() {
      this.searchInput = '' // triggers @input hence debounceSearch()
    },

    onPaste(e) {
      this.searchTerms = e.clipboardData.getData('text') || ''
    },

    debounceSearch(searchInput) {
      // called whenever input changes, including @click:clear
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.searchTerms = searchInput || ''
      }, 300)
    },

    /* manage filters */

    onFilter() {},

    initializeFilters() {
      const selectedFilters = {}
      this.filters.forEach((filter) => (selectedFilters[filter.field] = []))
      this.selectedFilters = { ...selectedFilters } // add keys reactively
    },

    clearFilters() {
      this.filters.forEach((filter) => (this.selectedFilters[filter.field] = []))
    },

    extractFilterValues(items, filter) {
      // 'filter' is an object describing the filter

      // create an array of all the values of a given filter field
      const values = items.map((item) => item[filter.field])

      // flatten multi-valued values
      const valueArray = filter.isMultiValued ? values.flat() : values

      // convert array of objects to a list of string values
      const valueList = valueArray
        .map((value) =>
          value
            ? filter.requiresTranslation // handle i18n values
              ? value[this.locale] || value[this.defaultLocale]
              : value
            : ''
        )
        .filter(Boolean) // remove empty string values
        .sort()

      // remove duplicates
      const valueSet = new Set(valueList)

      // console.log('list=', [...valueSet])

      // create an object array as required by v-select
      const valueObjectArray = [...valueSet].map((value) => {
        return {
          value: value,
          text: filter.labelRequiresTranslation
            ? this.$t(`${filter.labelPrefix}.${value}`) || value
            : value
        }
      })

      return valueObjectArray
    },

    /* manage layout */

    changeLayout(layout) {
      this.displayLayout = layout
      this.$emit('update:layout', layout)
    },

    /* manage tree */

    toggleTree() {
      if (this.isTreeExpanded) {
        this.isTreeExpanded = false
        this.$emit('collapse')
      } else {
        this.isTreeExpanded = true
        this.$emit('expand')
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-title {
  flex: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

.c-search,
.c-controls {
  flex: 1 1 0;
}
.c-controls {
  margin-right: -8px; /* buttons have 12px of margin */
  max-width: calc(300px - 24px); /* leave space for caret */
}

.c-search,
.c-sort,
.c-filter {
  max-width: 300px;
}

.c-overscroll {
  overscroll-behavior: contain; /* all this seems to do is add a "pause" before the scroll chaining occurs */
  -ms-scroll-chaining: contain;
}

.c-layout-toolbar :deep(.v-toolbar__content) {
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}
.c-layout-toolbar.c-wide :deep(.v-toolbar__content) {
  padding-left: 0px;
  padding-right: 0px;
}

/* global styles because v-select menu-prop content-class not working */
.c-filter-menu,
.c-selector-menu {
  min-width: 240px !important;
  max-width: max(25%, 300px);
  /* left: unset !important; */
  right: 16px !important;
}
.c-selector-menu :deep(.v-select-list) {
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;
}
.c-selector-menu :deep(.v-list-item__action),
.c-selector-menu :deep(.v-list-item__action:first-child) {
  margin-right: 0 !important;
}
.c-selector-menu :deep(.v-input--selection-controls__input i) {
  font-size: 1rem;
}
.c-selector-menu :deep(.v-list-item__title) {
  font-size: 1rem;
}
</style>
