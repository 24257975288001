<template>
  <CardPanel
    :items="loading ? skeletonChannels : channels"
    :dense="dense"
    :draggable="draggable"
    drag-type="channel"
    :loading="loading"
    :filters="channelFilters"
    :layouts="layouts"
    :preference="preference"
    :sorts="channelSortOptions"
    :title="title"
    :tree-attribute="treeAttribute"
    :width="width"
  >
    <template #card="props">
      <ChannelCardSkeleton v-if="loading"/>
      <ChannelCard
        v-else
        :channel="props.item"
        @view:channel="$emit('view:channel', props.item)"
        @share="$emit('share', props.item)"
      />
    </template>
  </CardPanel>
</template>

<script>
import { dummyChannels } from '@/components/channel/dummyChannels.js'

// content components
import ChannelCard from '@/components/channel/ChannelCard'
import ChannelCardSkeleton from '@/components/channel/ChannelCardSkeleton'
import CardPanel from '@/components/base/CardPanel'
import channelMixin from '@/components/channel/channelMixin.js'

export default {
  name: 'ChannelPanel',

  components: {
    CardPanel,
    ChannelCard,
    ChannelCardSkeleton
  },

  mixins: [channelMixin],

  props: {
    channels: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    draggable: {
      type: Boolean,
      required: false,
      default: false
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'carousel', 'tree']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    preference: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    treeAttribute: {
      type: String,
      required: false,
      default: 'topic'
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      skeletonChannels: dummyChannels(6)
    }
  },

  computed: {
    channelFilters() {
      return this.channelMixin_filters
    },

    channelSortOptions() {
      return this.draggable
        ? [
            ...this.channelMixin_sorts,
            {
              text: this.$t('ui.sortOptions.sortByAsIs'),
              value: 'sortByAsIs'
            }
          ]
        : this.channelMixin_sorts
    }
  },

  created: function () {},

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
