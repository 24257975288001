<template>
  <v-list>
    <v-list-item-group
      v-model="activeLocale"
      active-class="c-list-item--active"
      mandatory
    >
      <v-list-item
        v-for="(localeObj, key, i) in locales"
        :key="localeObj.code"
        :value="localeObj.code"
        @click.stop="switchLocale(localeObj.code)"
      >
        <v-list-item-action size="28">
          <v-avatar v-if="true" class="ml-0 mr-4 c-lang-avatar" :color="getColour(i)">
            <span class="white--text text-h5">
              {{ localeObj.avatar }}
            </span>
          </v-avatar>
          <img v-else height="24" :src="require('@/assets/images/flags/' + localeObj.icon)">
        </v-list-item-action>
        <v-list-item-title class="text-h6">
          {{ localeObj.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import locales from '@/locales'
import { mapActions } from 'vuex'

export default {
  name: 'LocaleSelector',

  components: {},

  data: function () {
    return {
      activeLocale: null,
      locales: locales,
      colours: ['secondary', 'secondary']
    }
  },

  computed: {
    currentLocale() {
      return this.$store.state.i18nStore.locale
    },

    currentLocaleName() {
      return this.locales[this.currentLocale].name
    },

    currentLocaleIcon() {
      return this.locales[this.currentLocale].icon
    }
  },

  created: function () {
    this.activeLocale = this.currentLocale
  },

  methods: {
    ...mapActions('i18nStore', ['updateLocale']),

    getColour(i) {
      return this.colours[i % this.colours.length]
    },

    switchLocale(locale) {
      this.activeLocale = locale
      this.updateLocale({ locale, vm: this })
      this.$emit('update:locale', locale)
    }
  }
}
</script>

<style lang="css" scoped>
.c-list-item--active {
  background-color: var(--v-primary-base);
}
.c-lang-avatar {
  height: 100%;
}
</style>
