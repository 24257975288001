<template>
  <SectionPanels
    class="g-hide-scrollbar c-section-panel mb-0"
    :depth="tree.length"
    :expand="expand"
  >
    <SectionPanel
      v-for="(node, i) in tree"
      :key="i"
      :title="node.branchName"
      :count="node.leafNodes.length"
      :loading="loading"
    >
      <CardCarousel
        :title="node.branchName"
        :items="node.leafNodes"
        :width="width"
      >
        <template #card="props">
          <slot name="card" :item="props.item" />
        </template>
      </CardCarousel>
    </SectionPanel>
  </SectionPanels>
</template>

<script>
import CardCarousel from '@/components/base/CardCarousel'
import SectionPanel from '@/components/base/SectionPanel'
import SectionPanels from '@/components/base/SectionPanels'

export default {
  name: 'CardTree',

  components: {
    CardCarousel,
    SectionPanel,
    SectionPanels
  },

  props: {
    items: {
      type: Array,
      required: true
    },

    attribute: {
      type: String,
      required: true
    },

    expand: {
      type: Boolean,
      required: false,
      default: false
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      tree: [],
      constructing: true
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    branches() {
      const attributeGroup = this.items
        .map((item) => item[this.attribute]) // potentially an array of arrays
        .flat() // flatten the array of arrays
        .filter(Boolean) // remove falsey values
        .map((branch) => branch?.[this.locale] || branch?.[this.defaultLocale] || branch || '') // retrieve the (localized?) value
        .sort()

      const attributeSet = [...new Set(attributeGroup)]

      return attributeSet
    }
  },

  watch: {
    items: {
      immediate: true, // build tree with pre-load mock data
      handler: function (_newValue, _oldValue) {
        this.constructTree()
      }
    }
  },

  methods: {
    constructTree() {
      this.constructing = true
      this.tree = []
      for (const branch of this.branches) {
        this.tree.push({
          branchName: branch,
          leafNodes: this.getLeafNodesPerBranch(branch)
        })
      }
      this.constructing = false
    },

    getLeafNodesPerBranch(branch) {
      return this.items.filter((item) => {
        const value = item[this.attribute]
        return Array.isArray(value)
          ? value.map((v) => v?.[this.locale] || v?.[this.defaultLocale] || v).includes(branch)
          : (value?.[this.locale] || value?.[this.defaultLocale] || value) === branch
      })
    }
  }
}
</script>

<style lang="css" scoped></style>
