<template>
  <v-menu :close-on-click="!touring" :close-on-content-click="false" offset-y>
    <template #activator="{ on, attrs, value }">
      <v-btn
        v-tooltip="$t('tooltip.appBar.account')"
        class="c-adjustment"
        :class="activatorClass"
        :data-title="$t('tour.appBar.accountMenu.title')"
        :data-intro="$t('tour.appBar.accountMenu.intro')"
        :data-toggle="value ? 'nop' : 'open'"
        fab
        small
        v-bind="attrs"
        v-on="on"
        @click.stop
      >
        <v-avatar class=c-avatar size="40">
          <v-img v-if="isAuthenticated" :src="avatarURL" />
          <v-icon v-else color="accent" large> mdi-account </v-icon>
        </v-avatar>

      </v-btn>
    </template>

    <!-- menu items -->
    <v-list rounded>
      <UserMenuItem />

      <v-divider />

      <LocaleMenuItem />
      <ModeMenuItem />
      <SettingsMenuItem />

      <!-- sign out -->
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="accent" x-large> mdi-location-exit </v-icon>
        </v-list-item-action>
        <v-list-item-title class="text-h6">
          {{ $t('account.signout') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import UserMenuItem from '@/components/account/UserMenuItem'
import LocaleMenuItem from '@/components/account/LocaleMenuItem'
import ModeMenuItem from '@/components/account/ModeMenuItem'
import SettingsMenuItem from '@/components/account/SettingsMenuItem'

import { tourService } from '@/services/tourService.js'

export default {
  name: 'AccountMenu',

  components: {
    UserMenuItem,
    LocaleMenuItem,
    ModeMenuItem,
    SettingsMenuItem
  },

  props: {
    activatorClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      touring: false
    }
  },

  computed: {
    avatarURL() {
      return this.$auth.getUserAvatar()
    },

    isAuthenticated() {
      return this.$auth.isAuthenticated()
    }
  },

  mounted: function () {
    this.touring = tourService.isTouringActive()
  },

  activated: function () {
    this.touring = tourService.isTouringActive()
  },

  methods: {
    signOut() {
      this.showDialog = false
      this.$auth.signOut({})
    }
  }
}
</script>

<style lang="css" scoped>
.c-adjustment {
  margin-bottom: 1px;
}

.c-avatar {
  outline: var(--v-text-base) 1px solid;
}

.v-list-item__action {
  min-width: 32px !important;
}
.c-list-group .v-list-item--active.theme--dark {
  color: white !important;
}
.c-list-group .v-list-item--active.theme--light {
  color: black !important;
}
</style>
