import { postService } from '@/services/postService'

export default {
  /* common actions */

  restoreState({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */

  async fetchPosts({ state, commit, getters }) {
    // set headers
    if (state.headers.length < 1) {
      const headers = postService.getPostHeaders()
      if (headers.length > 0) commit('setHeaders', headers)
    }

    // fetch posts
    if (state.fetched) {
      // already fetched - use cached values
      const count = state.posts.length
      console.debug(`[postStore]: Fetching posts from cache (${count}).`)
      return state.posts
    } else {
      // fetch required
      if (state.loading) {
        // previous fetch in progress
        console.debug('[postStore]: Fetching posts via pre-existing promise.')
        // skip waiting because there's cached content (where previous fetch proceeds)
        if (state.posts.length > 0) {
          return state.posts
        }
        // wait because there's no cached content
        try {
          const posts = await state.promise
          return posts
        } catch (error) {
          console.warn('[postStore]: ...Pre-existing fetch failed.', error)
          return state.posts || []
        }
      } else {
        // needs a refresh from the CMS
        try {
          console.info(`[postStore]: Fetching posts from CMS.`)
          const filter = getters.activePortal?.postFilter
          const promise = postService.fetchPosts({ filter })
          commit('setLoading', { loading: true, promise })
          // use cached posts (but update state)
          if (state.posts.length > 0) {
            promise.then((posts) => {
              commit('setPosts', posts)
              commit('setFetched', true)
              commit('setLoading', { loading: false })
            })
            // return the current cache value
            return state.posts
          } else {
            const posts = await promise
            commit('setPosts', posts)
            commit('setFetched', true)
            commit('setLoading', { loading: false })
            // return the fetched value
            return posts
          }
        } catch (error) {
          console.warn(`[postStore]: ...Fetch failed. Using cache.`, error)
          return state.posts || []
        }
      }
    }
  },

  async fetchPost({ getters, commit }, { id, slug }) {
    const post = getters.getPost({ id, slug })

    if (post) {
      return post
    } else {
      try {
        const fetchedPost = await postService.fetchPost({ id, slug })
        commit('setPost', fetchedPost)
        return fetchedPost
      } catch (error) {
        console.error('[postStore]:', error)
        throw error
      }
    }
  },

  async incrementLikes({ commit }, { postId, deltaLikes }) {
    try {
      const { id, likes } = await postService.incrementLikes({ postId, deltaLikes })
      commit('setPost', { id, likes })
      return { id, likes }
    } catch (error) {
      console.error('[postStore]:', error)
    }
  }
}
