// import client from '@client'
import client from '@/services/mock'

class CalendarService {
  constructor() {
    this.type = ''
  }

  getCalendarEvents(_start, _end) {
    return []
  }

  async fetchCalendarEntries(startDate, endDate) {
    try {
      const events = await client.fetchCalendarEntries(startDate, endDate)
      return events
    } catch (error) {
      console.error('[calendarService]: Unable to retrieve calendar events.', error)
      return []
    }
  }
}

export default CalendarService
export const calendarService = new CalendarService()
