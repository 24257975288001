<template>
  <v-menu
    v-model="isOpen"
    close-on-click
    close-on-content-click
    max-height="80%"
    offset-y
    top
    transition="slide-y-reverse-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-tooltip="$t('tooltip.modelBar.layers')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        small
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ isOpen ? 'mdi-close' : 'mdi-layers-triple' }}
        </v-icon>
      </v-btn>
    </template>
    <LayerList v-model="selectedLayerIds" :layers="layers" @select="$emit('select', $event)" />
  </v-menu>
</template>

<script>
import LayerList from './LayerList'

export default {
  name: 'LayerSelector',

  components: {
    LayerList
  },

  props: {
    layers: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      transition: 'slide-y-transition',
      selectedLayerIds: []
    }
  },

  created: function () {
    this.selectedLayerIds = this.layers.map((layer) => layer.id)
  },

  methods: {}
}
</script>

<style lang="css" scoped></style>
