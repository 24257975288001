<template>
  <CardPanel
    :items="loading ? skeletonPosts : posts"
    :dense="dense"
    :draggable="draggable"
    drag-type="post"
    :loading="loading"
    :filters="postFilters"
    :layouts="layouts"
    :preference="preference"
    :sorts="postSortOptions"
    :title="title"
    :tree-attribute="treeAttribute"
    :width="width"
  >
    <template #card="props">
      <PostCardSkeleton v-if="loading"/>
      <PostCard
        v-else
        :post="props.item"
        @view:post="$emit('view:post', props.item)"
        @share="$emit('share', props.item)"
      />
    </template>
  </CardPanel>
</template>

<script>
import { dummyPosts } from '@/components/post/dummyPosts.js'

// content components
import PostCard from '@/components/post/PostCard'
import PostCardSkeleton from '@/components/post/PostCardSkeleton'
import CardPanel from '@/components/base/CardPanel'
import postMixin from '@/components/post/postMixin.js'

export default {
  name: 'PostPanel',

  components: {
    CardPanel,
    PostCard,
    PostCardSkeleton
  },

  mixins: [postMixin],

  props: {
    posts: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    draggable: {
      type: Boolean,
      required: false,
      default: false
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'carousel', 'tree']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    preference: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    treeAttribute: {
      type: String,
      required: false,
      default: 'topic'
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      skeletonPosts: dummyPosts(6)
    }
  },

  computed: {
    postFilters() {
      return this.postMixin_filters
    },

    postSortOptions() {
      return this.draggable
        ? [
            ...this.postMixin_sorts,
            {
              text: this.$t('ui.sortOptions.sortByAsIs'),
              value: 'sortByAsIs'
            }
          ]
        : this.postMixin_sorts
    }
  },

  created: function () {},

  methods: {}
}
</script>

<style lang="css" scoped>
</style>
