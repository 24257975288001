<template>
  <v-navigation-drawer
    class="c-menu"
    app
    :clipped="clipped"
    :expand-on-hover="expandOnHover"
    :mini-variant="isMini"
    :mini-variant-width="miniWidth"
    :mobile-breakpoint="0"
    :value="show"
    :width="fullWidth"
  >
    <!-- menu background -->
    <template #img>
      <v-img :src="backgroundImage" :gradient="backgroundGradient" height="100%" />
    </template>

    <!-- app and logo -->
    <template #prepend>
      <v-list nav subheader class="pb-0">
        <v-list-item class="c-list-item c-list-item--prepend py-1">
          <v-list-item-icon class="c-logo c-list-item-icon py-0 my-0">
            <v-img :src="orgLogo" contain max-height="40" max-width="40" />
          </v-list-item-icon>

          <v-list-item-content class="c-list-item-content">
            <v-list-item-title class="c-name">
              {{ appName }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="ml-3 my-0">
            <v-btn
              v-tooltip="$t('tooltip.menuBar.menuStyleButton')"
              :data-title="$t('tour.appBar.menuStyleButton.title')"
              :data-intro="$t('tour.appBar.menuStyleButton.intro')"
              :data-toggle="isMini ? 'open' : 'nop'"
              fab
              small
              input-value="true"
              @click="toggleMiniVariant"
            >
              <v-icon large>
                {{ isMini ? 'mdi-format-list-bulleted-square' : 'mdi-dots-vertical' }}
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider class="mb-1" />
    </template>

    <!-- menu items -->
    <v-list nav rounded>
      <v-list-item
        v-for="menuItem in menuItems"
        :key="menuItem.key"
        :data-title="menuTitles[menuItem.key]"
        :data-intro="menuIntros[menuItem.key]"
        data-click
        active-class="c-list-item--active"
        class="c-list-item"
        exact
        fixed
        :to="to(menuItem)"
        router
      >
        <v-list-item-icon class="c-list-item-icon">
          <v-icon large>
            {{ menuItem.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="c-list-item-content">
          <v-list-item-title class="text-h6">
            {{ menuTitles[menuItem.key] }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <!-- control auto-hover setting (for mini menu) -->
    <template #append>
      <v-list v-show="isMini">
        <v-list-item class="c-list-item">
          <v-list-item-icon>
            <v-btn
              v-tooltip="$t('tooltip.menuBar.menuLockButton')"
              :data-title="$t('tour.appBar.menuLockButton.title')"
              :data-intro="$t('tour.appBar.menuLockButton.intro')"
              :data-toggle="!isMini ? 'close' : 'nop'"
              icon
              @click="toggleHover"
            >
              <v-icon x-large>
                {{ expandOnHover ? 'mdi-chevron-triple-left' : 'mdi-chevron-triple-right' }}
              </v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content />
        </v-list-item>
        <v-list />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppSideBar',

  components: {},

  mixins: [mobileMixin],

  props: {
    show: {
      type: Boolean,
      required: true
    },

    backgroundImage: {
      type: String,
      required: false,
      default: require('@/assets/images/sidebars/valley-lake.jpg')
    }
  },

  data: function () {
    return {
      // initially, the sidebar is a mini variant that overlays on hover
      clipped: false, // the side drawer pushes over the top app-bar
      expandOnHover: true, // hover effect for mini variant is enabled
      isMini: true, // use a mini variant (until hover)
      miniWidth: 72, // to accommodate menu item icons = 2rem
      fullWidth: 240 // to accomodate logo plus app name as well as menu buttons (icons and labels)
    }
  },

  computed: {
    ...mapGetters('menuStore', ['getMenuTitles', 'getMenuIntros']),
    ...mapGetters('userStore', ['getSettings']),

    /* menu layout */

    actualWidth() {
      // prettier-ignore
      return this.show
        ? this.isMobile
          ? 0
          : this.isMini
            ? this.miniWidth
            : this.fullWidth
        : 0
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isMobile() {
      return this.mobileMixin_isMobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* menu content */

    appName() {
      return (
        this.$store.state.portalStore.activePortal?.title?.[this.locale] || this.$appConfig.appName
      )
    },

    backgroundGradient() {
      return this.isDark
        ? 'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)'
        : 'rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)'
    },

    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    menuTitles() {
      return this.getMenuTitles(this.locale)
    },

    menuIntros() {
      return this.getMenuIntros(this.locale)
    },

    orgLogo() {
      return this.$store.state.orgStore.orgLogo
    }
  },

  watch: {
    show: {
      immediate: false, // initial response handled via created hook
      handler: function (_newValue, _oldValue) {
        this.$emit('width', this.actualWidth)
      }
    }
  },

  created: function () {
    const { sidebarMini, sidebarHover } = this.getSettings()
    this.isMini = sidebarMini
    this.expandOnHover = sidebarHover

    this.$emit('width', this.actualWidth)
  },

  methods: {
    ...mapActions('userStore', ['updateSettings']),

    toggleMiniVariant() {
      this.isMini = !this.isMini
      this.expandOnHover = this.isMini
      this.updateSettings({
        sidebarMini: this.isMini,
        sidebarHover: this.expandOnHover
      })

      // recalculate width based on settings changes
      this.$emit('width', this.actualWidth)
    },

    toggleHover() {
      if (this.isMini) {
        this.expandOnHover = !this.expandOnHover
        this.updateSettings({
          sidebarHover: this.expandOnHover
        })
      }
    },

    to(item) {
      return item.to ? item.to : { name: item.route }
    }
  }
}
</script>

<style lang="css" scoped>
/* menu item styling */
.c-list-item {
  justify-content: flex-start !important; /* prevents the "jitter" bug */
  padding: 8px; /* creates a more compact menu item */
  min-height: 40px; /* overrides 48px default */
}
.c-list-item--active {
  background-color: var(--v-primary-base);
}
.c-list-item-icon {
  margin: 0px; /* creates a more compact menu item */
  margin-right: 0px !important; /* eliminates gap between icon and label */
  margin-left: 2px !important; /* adds to icon to center with logo: logo=40px - icon=36px / 2 = 2px */
  padding: 0px; /* c-list-item ensures button padding */
  align-self: center; /* centers icon relative to name */
}
.c-list-item-icon.c-logo {
  margin-left: 0px !important; /* counters icon shift above */
}
.c-list-item-content {
  padding: 0px; /* creates a more compact menu item */
  margin-left: 12px; /* controls gap between icon and label */
}

.c-name {
  font-size: 1.1rem;
  font-weight: bold;
}

.c-menu :deep(::-webkit-scrollbar) {
  width: 0;
  background: transparent;
}
.c-menu :deep(.v-navigation-drawer__content) {
  scrollbar-width: none;
}
</style>
