<template>
  <v-card ref="card" class="c-card d-flex flex-column">
    <!-- header -->
    <v-list-item>
      <!-- media icon -->
      <v-list-item-avatar>
        <v-icon color="accent" x-large>
          {{ getMediaIcon(item.mediaType) }}
        </v-icon>
      </v-list-item-avatar>
      <!-- title -->
      <v-list-item-content>
        <v-card-title class="c-title pa-0 text-left">
          <ClampedText :max-lines="3">
            {{ item.title }}
          </ClampedText>
        </v-card-title>
      </v-list-item-content>
    </v-list-item>

    <!-- thumbnail image -->
    <div class="c-thumbnail-border">
      <v-img
        :key="thumbnail"
        class="c-clickable ma-4"
        :aspect-ratio="thumbnailAspectRatio"
        contain
        :src="thumbnail"
        @click="$emit('view:item')"
      />
    </div>

    <!-- topic -->
    <v-card-text class="py-0 text-subtitle-1 text-left">
      <span>{{ topic }}&nbsp;</span>
    </v-card-text>

    <!-- size and duration -->
    <v-card-text v-if="$actions.size" class="d-flex py-0 text-subtitle-1">
      <div>
        {{ sizeInMB ? `${sizeInMB}MB` : '&nbsp;' }}
      </div>
      <v-spacer />
      <div>
        {{ durationInMinutes ? `${durationInMinutes} Min` : '&nbsp;' }}
      </div>
    </v-card-text>

    <!-- action menu -->
    <ContentCardActions
      :item="item"
      @view:item="$emit('view:item')"
      @view:info="revealBack = true"
      @remove:favourite="$emit('remove:favourite')"
      @share="$emit('share')"
    />

    <!-- flip card -->
    <v-scroll-x-reverse-transition>
      <ContentCardBack v-model="revealBack" :item="item" @view:parent="$emit('view:parent')" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import ClampedText from '@/components/base/ClampedText'
import ContentCardActions from '@/components/content/ContentCardActions'
import ContentCardBack from '@/components/content/ContentCardBack'
import Players from '@/players/playerRegistry.json'
import { getImage } from '@/config/sanityConfig'

export default {
  name: 'ContentCard',

  components: {
    ClampedText,
    ContentCardActions,
    ContentCardBack
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailAspectRatio: 4 / 3,
      maxLines: 3,
      isFavourite: false,
      revealBack: false,
      showViewer: false,
      cardX: 0,
      cardY: 0
    }
  },

  computed: {
    durationInMinutes() {
      return this.item.durationInMinutes || ''
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    favourite() {
      return this.$store.state.userStore.favourites.indexOf(this.item.id) > -1
    },

    sizeInMB() {
      return this.item.sizeInMB || ''
    },

    topic() {
      return this.item.topic?.[this.locale] || this.item.topic?.[this.defaultLocale] || ''
    },

    thumbnail() {
      // const url = this.item.cardImage?.url
      const image = this.item.cardImage?.obj
      const crop = image?.crop
      // const hotspot = image.hotspot

      return image
        ? crop
          ? getImage(image).width(400).height(300).url()
          : getImage(image).url()
        : ''
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  mounted: function () {
    const cardRect = this.$refs.card.$el.getBoundingClientRect()
    this.cardX = Math.ceil(cardRect.left)
    this.cardY = Math.ceil(cardRect.top)
  },

  activated: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    getCardHeight(colWidth) {
      // cardWidth
      const colGap = 12
      const rowGap = 12
      const cardWidth = Math.min(Math.max(colWidth - 2 * colGap, 275), 400)

      // title height
      const titlePadding = 2 * 12
      const titleHeight = 120 + titlePadding

      // thumbnail height
      const imageMarginWidth = 2 * 16 + 2 * 16
      const thumbnailHeight = (cardWidth - imageMarginWidth) * (3 / 4) + 2 * 16

      // fixed  blocks
      const topicHeight = 28
      const actionHeight = 60

      // totals
      const height = 2 * rowGap + titleHeight + thumbnailHeight + topicHeight + actionHeight
      return height
    },

    getMediaIcon(mediaType) {
      return Players.find((player) => player.mediaType === mediaType)?.icon || 'mdi-file'
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  min-width: 275px;
  max-width: min(400px, 100%);
  border-radius: 16px;
}

.c-title {
  min-height: 120px;
}

.c-thumbnail-border {
  background-color: black;
  margin: 0px 16px;
}

.c-clickable {
  cursor: pointer;
}
</style>
