const initialState = {
  activeChannel: null,
  channels: [],
  // transient state
  fetched: false,
  loading: undefined,
  promise: undefined
}

export default () => initialState
