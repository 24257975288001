var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasAuthor
      ? _c(
          "div",
          [
            _vm.hasGuests
              ? _c("div", { staticClass: "mt-2 text-h5" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isBlog ? _vm.$t("post.author") : _vm.$t("post.host")
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c("PersonBanner", { attrs: { person: _vm.post.author } }),
          ],
          1
        )
      : _vm._e(),
    _vm.hasGuests
      ? _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isBlog
                      ? _vm.$t("post.coAuthors")
                      : _vm.$t("post.guests")
                  ) +
                  " "
              ),
            ]),
            _vm._l(_vm.post.guests, function (guest) {
              return _c("PersonBanner", {
                key: guest.lastName,
                attrs: { person: guest },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }