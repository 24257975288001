var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      ref: "bar",
      attrs: {
        app: "",
        "clipped-left": _vm.clipped,
        "clipped-right": _vm.clipped,
        color: "primary",
        dense: "",
        elevation: "0",
        "hide-on-scroll": false,
        "inverted-scroll": false,
        "scroll-target": "#main",
      },
    },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.appBar.menu"),
              expression: "$t('tooltip.appBar.menu')",
            },
          ],
          attrs: {
            "data-title": _vm.$t("tour.appBar.menuDisplayButton.title"),
            "data-intro": _vm.$t("tour.appBar.menuDisplayButton.intro"),
            icon: "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("toggle")
            },
          },
        },
        [
          _c("v-icon", { attrs: { color: "icon", "x-large": "" } }, [
            _vm._v(" " + _vm._s(_vm.menu ? "mdi-menu-open" : "mdi-menu") + " "),
          ]),
        ],
        1
      ),
      _c("v-toolbar-title", { staticClass: "c-title" }, [
        _vm._v(
          " " + _vm._s(_vm.pageTitle) + " " + _vm._s(_vm.pageSubtitle) + " "
        ),
      ]),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "c-controls d-flex justify-space-between align-center" },
        [
          _vm.showInstallButton ? _c("AppInstall") : _vm._e(),
          _vm.showOnlineStatus
            ? _c("AppOnlineStatus", {
                staticClass: "ml-2",
                attrs: { "offline-only": true },
              })
            : _vm._e(),
          _vm.hasNotices ? _c("AppNotices", { staticClass: "ml-2" }) : _vm._e(),
          _c("AccountMenu", { attrs: { "activator-class": "ml-2" } }),
        ],
        1
      ),
      _vm.hasNotifications ? _c("AppNotificationPermission") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }