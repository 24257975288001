var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": !_vm.touring,
        "close-on-content-click": false,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs, value }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("tooltip.appBar.account"),
                          expression: "$t('tooltip.appBar.account')",
                        },
                      ],
                      staticClass: "c-adjustment",
                      class: _vm.activatorClass,
                      attrs: {
                        "data-title": _vm.$t("tour.appBar.accountMenu.title"),
                        "data-intro": _vm.$t("tour.appBar.accountMenu.intro"),
                        "data-toggle": value ? "nop" : "open",
                        fab: "",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-avatar",
                    { staticClass: "c-avatar", attrs: { size: "40" } },
                    [
                      _vm.isAuthenticated
                        ? _c("v-img", { attrs: { src: _vm.avatarURL } })
                        : _c(
                            "v-icon",
                            { attrs: { color: "accent", large: "" } },
                            [_vm._v(" mdi-account ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { rounded: "" } },
        [
          _c("UserMenuItem"),
          _c("v-divider"),
          _c("LocaleMenuItem"),
          _c("ModeMenuItem"),
          _c("SettingsMenuItem"),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.signOut()
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", { attrs: { color: "accent", "x-large": "" } }, [
                    _vm._v(" mdi-location-exit "),
                  ]),
                ],
                1
              ),
              _c("v-list-item-title", { staticClass: "text-h6" }, [
                _vm._v(" " + _vm._s(_vm.$t("account.signout")) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }