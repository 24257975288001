<template>
  <SectionPanel
    ref="panel"
    :colour="colour"
    :count="itemCount"
    :loading="loading"
    :title="title"
  >
    <template #[toolbarSlotName]>
      <LayoutToolbar
        v-model="layout"
        :items="items"
        :filters="filters"
        :layouts="layouts"
        :sort="initialSortOptionName"
        :sorts="sorts"
        :title="dense ? title : ''"
        :wide="dense"
        @collapse="expand = false"
        @expand="expand = true"
        @results="resultSet = $event"
        @update:layout="updateLayoutPreference($event)"
        @update:sort="updateSortPreference($event)"
      />
    </template>

    <template #default="{ open }">
      <CardView
        v-show="open"
        :items="resultSet"
        :loading="loading"
        :layout="layout"
        :draggable="isDraggable"
        :drag-type="dragType"
        :tree-attribute="treeAttribute"
        :tree-expand="expand"
        :width="width"
      >
        <template #card="props">
          <slot name="card" :item="props.item" />
        </template>
      </CardView>
    </template>
  </SectionPanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import CardView from '@/components/base/CardView'
import LayoutToolbar from '@/components/base/LayoutToolbar'
import SectionPanel from '@/components/base/SectionPanel'

import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'CardPanel',

  components: {
    CardView,
    LayoutToolbar,
    SectionPanel
  },

  mixins: [mobileMixin],

  props: {
    /* card entities */

    items: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    /* panel / toolbar */

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    /* toolbar */

    filters: {
      type: Array,
      required: false,
      default: () => []
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'carousel', 'tree']
    },

    sorts: {
      type: Array,
      required: false,
      default: () => []
    },

    preference: {
      type: String,
      required: false,
      default: ''
    },

    /* card view */

    width: {
      type: Number,
      required: false,
      default: 0
    },

    /* card grid */

    draggable: {
      type: Boolean,
      required: false,
      default: false
    },

    dragType: {
      type: String,
      required: false,
      default: 'generic'
    },

    /* card tree */

    treeAttribute: {
      type: String,
      required: false,
      default: 'topic'
    }
  },

  data: function () {
    return {
      resultSet: [],
      layout: '',
      expand: true,

      // keep-alive status
      isActivated: false
    }
  },

  computed: {
    ...mapGetters('userStore', ['getPreference']),

    isDraggable() {
      // only allow dragging if all cards are visible (i.e. not excluded by search or filters)
      // and the sort order is "as is"
      return (
        this.draggable &&
        this.resultSet.length === this.items.length &&
        this.initialSortOptionName === 'sortByAsIs'
      )
    },

    itemCount() {
      return this.resultSet.length
    },

    initialSortOptionName() {
      return this.preference
        ? this.getPreference(this.preference + 'Sort') || this.sorts[0].value
        : this.sorts[0].value
    },

    toolbarSlotName() {
      return this.dense ? 'header' : 'toolbar'
    }
  },

  watch: {
    loading: {
      immediate: true,
      handler: function (newLoading, _oldLoading) {
        if (!newLoading) {
          this.resultSet = this.items
        }
      }
    }
  },

  created: function () {
    this.layout = this.mobileMixin_isReallyMobile
      ? 'carousel'
      : this.preference
      ? this.getPreference(this.preference + 'Layout') || this.layouts[0]
      : this.layouts[0]
  },

  mounted: function () {},

  activated: function () {
    this.isActivated = true
  },

  deactivated: function () {
    this.isActivated = false
  },

  methods: {
    ...mapActions('userStore', ['updatePreferences']),

    updateLayoutPreference(layoutPreference) {
      if (this.preference) {
        const layoutPreferenceName = this.preference + 'Layout'
        this.updatePreferences({ [layoutPreferenceName]: layoutPreference })
      }
    },

    updateSortPreference(sortPreference) {
      if (this.preference) {
        const sortPreferenceName = this.preference + 'Sort'
        this.updatePreferences({ [sortPreferenceName]: sortPreference })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
