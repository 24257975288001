var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-main", { staticClass: "c-main", attrs: { id: "main" } }, [
    _c(
      "div",
      {
        staticClass: "c-main__view g-skinny-scrollbars",
        class: { "c-mobile": _vm.isReallyMobile },
      },
      [
        _c(
          "transition",
          {
            attrs: { name: "fade", duration: 10, mode: "out-in" },
            on: { "after-leave": _vm.afterLeave },
          },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.keepAliveRouteComponentNames } },
              [
                _c("router-view", {
                  key: _vm.$route.path,
                  staticClass: "c-view",
                  class: { "c-mobile": _vm.isReallyMobile },
                  on: {
                    count: function ($event) {
                      return _vm.$emit("count", $event)
                    },
                    title: function ($event) {
                      return _vm.$emit("title", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }