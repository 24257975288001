var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-notifications" },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.appBar.notifications"),
              expression: "$t('tooltip.appBar.notifications')",
            },
          ],
          attrs: { fab: "", small: "" },
          on: { click: _vm.showNotices },
        },
        [
          _vm.badgeCount > 0
            ? _c(
                "div",
                [
                  _c(
                    "v-badge",
                    {
                      staticClass: "c-badge",
                      attrs: {
                        color: "red",
                        content: _vm.badgeCount,
                        overlap: "",
                        top: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v(" mdi-bell "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("v-icon", { attrs: { color: "accent" } }, [
                    _vm._v(" mdi-bell "),
                  ]),
                ],
                1
              ),
        ]
      ),
      _c("notifications", {
        staticClass: "mt-16",
        attrs: { group: "topbar" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function (notice) {
              return [
                _c(
                  "v-alert",
                  {
                    attrs: { type: notice.item.type, dismissible: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.removeNotice(notice)
                      },
                    },
                  },
                  [
                    notice.item.title
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-xs-caption text-sm-subtitle-1 text-md-subtitle-1 text-lg-h6 text-xl-h6",
                          },
                          [_vm._v(" " + _vm._s(notice.item.title) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-xs-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1",
                      },
                      [_vm._v(" " + _vm._s(notice.item.text) + " ")]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }