var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticClass: "c-toolbar",
      attrs: {
        color: "primary",
        dark: "",
        dense: "",
        flat: "",
        fullscreen: "",
        "hide-overlay": "",
        tile: "",
        width: "100%",
      },
    },
    [
      _c("v-spacer"),
      _c(
        "v-toolbar-title",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.titleTooltip,
              expression: "titleTooltip",
            },
          ],
          staticClass: "c-title text-left text-h4",
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle:favourite")
                },
              },
            },
            [
              _c(
                "v-icon",
                { staticClass: "xred--text", attrs: { large: "" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.favourite ? "mdi-heart" : "mdi-heart-outline"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.$actions.share
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { fab: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("share")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(" mdi-share-variant "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { fab: "", icon: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-close ")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }