var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAuthenticated
    ? _c(
        "v-list-item",
        [
          _c(
            "v-list-item-avatar",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    content: _vm.$t("tooltip.account.avatar"),
                    html: true,
                    delay: { hide: 1500 },
                    popperClass: "c-tooltip-link",
                  },
                  expression:
                    "{ content: $t('tooltip.account.avatar'), html: true, delay: { hide: 1500 }, popperClass: 'c-tooltip-link' }",
                },
              ],
              staticClass: "c-avatar",
              attrs: {
                "data-title": _vm.$t("tour.appBar.accountAvatar.title"),
                "data-intro": _vm.$t("tour.appBar.accountAvatar.intro"),
                size: "40",
              },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$appConfig.avatarBaseURL,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
                [
                  _c("v-avatar", { attrs: { size: "40" } }, [
                    _c("img", { attrs: { src: _vm.avatarURL } }),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.userDisplayName)),
              ]),
              _c("v-list-item-subtitle", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.userEmail)),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }